import API from "./backend";

const backendThunk = (actionTypes, params, successPayload) => dispatch => {
  const getActionType = variant => ({
    type:
      actionTypes[
        Object.keys(actionTypes).find(action => action.endsWith(variant))
      ]
  });

  dispatch(getActionType("REQUEST"));

  return API.backend(params)
    .then(res => {
      dispatch({ ...getActionType("SUCCESS"), ...successPayload(res) });
    })
    .catch(err => {
      dispatch(getActionType("FAILURE"));
    });
};

export default backendThunk;

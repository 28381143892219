import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "./Notifications.module.scss";
import Translation from "../../localization/Translation";
import { notificationTypes } from "./notifications.config";
import WithModalEvents from "../../components/HOC/WithModalEvents";
import Button from "../../components/button/Button";
import OrderDetailsModalContent from "../../features/orders/actions/details/OrderDetailsModalContent";
import { orderDetailsModalConfig } from "../../features/orders/actions/details/order.details.config";

const DATE_FORMAT = "D MMM";

export const Notification = ({
  notification,
  className,
  dateFormat,
  onCloseMenu,
  onOpenModal
}) => {
  const message = getMessage(notification);
  const date = moment(notification.createdAt)
    .utc()
    .format(dateFormat)
    .toUpperCase();

  const notificationClassName = !notification.processed
    ? "notifications--non-processed"
    : "notifications--processed";
  let notificationWrapperClassName = styles["notifications__notification"];

  if (className) {
    notificationWrapperClassName += ` ${className}`;
  }

  const onNotificationClick = event => {
    if (typeof onCloseMenu === "function") {
      onCloseMenu(event);
    }

    onOpenModal({
      modalConfig: orderDetailsModalConfig,
      content: (
        <OrderDetailsModalContent order={{ reference: notification.order }} />
      )
    });
  };

  return (
    <MenuItem className={notificationWrapperClassName}>
      <div
        className={styles["notifications__notification-main"]}
        data-test="notification-component"
      >
        <div className={styles[notificationClassName]}>
          <div className={styles["notifications__notification-header"]}>
            <span className={styles["notifications__notification-title"]}>
              <Translation>{notification.title}</Translation>
            </span>
            <time className={styles["notifications__notification-date"]}>
              {date}
            </time>
          </div>
          {message && (
            <span className={styles["notifications__notification-content"]}>
              {message}
            </span>
          )}
        </div>
        {notification.order && (
          <Button
            data-test="order-details-btn"
            size={"small"}
            variant="primary-outlined"
            onClick={onNotificationClick}
          >
            Details
          </Button>
        )}
      </div>
    </MenuItem>
  );
};

function getMessage(notification) {
  switch (notification.type) {
    case notificationTypes.ORDER_STATUS_CHANGED:
      return (
        <>
          <Translation>Order</Translation>: {notification.order} |{" "}
          <Translation>Status</Translation>:{" "}
          <Translation>{notification.status}</Translation>
        </>
      );

    case notificationTypes.SUPPORT_REQUEST_UPDATED:
      return (
        <>
          <Translation>Order</Translation>: {notification.order}
        </>
      );

    case notificationTypes.VERIFICATION_STATUS_CHANGED:
      return null;

    default:
      throw new Error("Unknow notification type");
  }
}

Notification.defaultProps = {
  dateFormat: DATE_FORMAT
};

Notification.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string,
    order: PropTypes.string
  }),
  className: PropTypes.string,
  onCloseMenu: PropTypes.func,
  onOpenModal: PropTypes.func
};

export default WithModalEvents(Notification);

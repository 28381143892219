import React from "react";
import Transaction from "./Transaction";
import Translation from "./../../../../../localization/Translation";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "./../../../../../components/table.cell/TableCell";
import { headings } from "./transactions.configuration";
import styles from "./Transactions.module.scss";

const TransactionsList = ({ transactions }) => {
  if (transactions.length > 0) {
    return (
      <React.Fragment>
        <div className={styles["transactions-title"]}>
          <Translation>Transactions</Translation>
        </div>
        <Table className={styles["transactions-table"]}>
          <TableHead>
            <TableRow>
              {headings.map(heading => (
                <TableCell
                  key={heading}
                  className={styles["transactions-table__thead"]}
                >
                  <Translation>{heading}</Translation>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((t, index) => (
              <Transaction key={index} transaction={t} />
            ))}
          </TableBody>
        </Table>
      </React.Fragment>
    );
  }
  return null;
};

export default TransactionsList;

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const WithEnabledFeatureHOC = Component => {
  const WithEnabledFeature = ({
    userContextFeatures,
    feature,
    fallback,
    children,
    dispatch, // react warns about this one, needs to be removed from propagated props
    ...rest
  }) => {
    const FallbackComponent = fallback;

    if (
      userContextFeatures &&
      userContextFeatures[feature] &&
      userContextFeatures[feature].active
    ) {
      return <Component {...rest}>{children}</Component>;
    }

    if (fallback) {
      return <FallbackComponent />;
    }

    return null;
  };

  const mapStateToProps = state => ({
    userContextFeatures: state.user.features
  });

  return connect(
    mapStateToProps,
    null
  )(WithEnabledFeature);
};

WithEnabledFeatureHOC.propTypes = {
  feature: PropTypes.string.isRequired
};

export default WithEnabledFeatureHOC;

export default value => {
  const valueAsString = value.toString();
  const positionOfComma = valueAsString.indexOf(",");
  const hasCommaCharacter = valueAsString.indexOf(",", 1) === -1;
  const isStartWithDigits = !/\D/g.test(
    valueAsString.substring(0, positionOfComma)
  );

  if (!hasCommaCharacter && isStartWithDigits) {
    return false;
  }

  return true;
};

import React from "react";
import PropTypes from "prop-types";
import Translation from "../../../../localization/Translation";
import styles from "./OrderDetails.module.scss";

const OrderDetailsItem = ({ label, value, content }) => (
  <div className={styles["order-details__item"]}>
    <span className={styles["order-details__item-label"]}>
      <Translation>{label}</Translation>
    </span>
    <span className={styles["order-details__item-value"]}>
      {content && content()}
      {value}
    </span>
  </div>
);

OrderDetailsItem.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default OrderDetailsItem;

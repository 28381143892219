import API from "../../services/api/backend";
import URLSearchParams from "@ungap/url-search-params";
import isValidBuyAmount from "./../../services/validation/validations/is.valid.buy.amount";
import isValidSellAmount from "./../../services/validation/validations/is.valid.sell.amount";
import newOrderBuyModelFactory from "./buy/new.order.buy.model.factory";
import orderModelFactory from "./order.model.factory";
import OrderCheckModel from "./order.check.model.factory";
import store from "../../store/store";
import { LATEST_ORDERS_ACTION_TYPES } from "./latest/latest.orders.action.types";
import { ORDERS_ACTION_TYPES } from "./orders.action.types";
import { addNewOrder } from "./orders.actions";
import { noOrdersMessage } from "./export/export.orders.config";
import {
  defaults,
  orderTypes,
  defaultDecimalPlaces,
  priorities,
  currencies
} from "./../../constants";
import {
  FETCH_BUNDLES_REQUEST,
  FETCH_BUNDLES_SUCCESS,
  FETCH_BUNDLES_FAILURE
} from "./bundles/bundles.action.types";

export const DEFAULT_ESTIMATE_VALUE = "";

const getOrders = (requestParams, actionTypes, payloadTransformator) => {
  return API.backendThunk(
    actionTypes,
    {
      url: API.endpoints.orders,
      method: API.methods.GET,
      params: requestParams.getParams()
    },
    orders => ({
      payload: payloadTransformator(orders)
    })
  );
};

const getOrder = reference =>
  API.backend({
    url: `${API.endpoints.orders}/${reference}`,
    method: API.methods.GET
  }).then(order => orderModelFactory.create(order));

const fetchLatestOrders = requestParams =>
  getOrders(requestParams, LATEST_ORDERS_ACTION_TYPES, orders => ({
    items: orders.content.map(i => orderModelFactory.create(i))
  }));

const fetchOrders = requestParams =>
  getOrders(requestParams, ORDERS_ACTION_TYPES, ({ content, page }) => ({
    items: content.map(i => orderModelFactory.create(i)),
    totalPages: page.totalPages,
    page: requestParams.page
  }));

const cancelOrder = reference => {
  return API.backend({
    url: `${API.endpoints.orders}/${reference}/cancel`,
    method: API.methods.POST
  });
};

const sendReceipt = reference => {
  return API.backend({
    url: `${API.endpoints.orders}/${reference}/receipt`,
    method: API.methods.POST
  });
};

const sendFeedback = (reference, data) => {
  return API.backend({
    url: `${API.endpoints.orders}/${reference}/feedback`,
    method: API.methods.POST,
    data
  });
};

const getNetworkFee = (to, from = defaults.fiat) =>
  API.backend({
    url: API.endpoints.networkFee,
    method: API.methods.GET,
    params: {
      from,
      to
    }
  });

const buySwishInit = order =>
  API.backend({
    url: `${API.endpoints.swish}/${order.reference}/init`,
    method: API.methods.POST
  }).catch(error => {
    console.logExternally("Error while making swish init request", {
      order,
      error
    });

    return Promise.reject(error);
  });

const placeBuyOrder = data =>
  API.backend({
    url: API.endpoints.buyOrders,
    method: API.methods.POST,
    data: newOrderBuyModelFactory.create(data).toAPI()
  })
    .then(res => orderModelFactory.create(res))
    .then(orderModel => {
      store.dispatch(addNewOrder(orderModel));
      return orderModel;
    })
    .catch(error => {
      console.logExternally("Error while placing Buy order", {
        data,
        error
      });

      return Promise.reject(error);
    });

const getSwishPaymentStatus = reference =>
  API.backend({
    url: `${API.endpoints.swish}/${reference}/check`,
    method: API.methods.POST
  }).then(res => orderModelFactory.create(res));

const getPaymentStatus = reference =>
  API.backend({
    url: `${API.endpoints.stripe}/${reference}/check`,
    method: API.methods.POST
  }).then(res => OrderCheckModel.create(res));

const placeSellOrder = data =>
  API.backend({
    url: API.endpoints.sellOrders,
    method: API.methods.POST,
    data: {
      ...data,
      type: orderTypes.SELL
    }
  })
    .then(res => orderModelFactory.create(res))
    .then(orderModel => {
      store.dispatch(addNewOrder(orderModel));
      return orderModel;
    })
    .catch(error => {
      console.logExternally("Error while placing Sell order", {
        data,
        error
      });

      return Promise.reject(error);
    });

const fetchBundles = () =>
  API.backendThunk(
    { FETCH_BUNDLES_REQUEST, FETCH_BUNDLES_SUCCESS, FETCH_BUNDLES_FAILURE },
    {
      url: API.endpoints.bundles,
      method: API.methods.GET
    },
    res => ({
      payload: res.data
    })
  );

const exportOrders = (requestParams, exportFormat) =>
  API.backend({
    url: API.endpoints.exportOrders,
    method: API.methods.GET,
    params: requestParams.getParams(),
    responseType: "blob"
  })
    .then(data => {
      if (data.size) {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `orders.${exportFormat}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return;
      }

      return Promise.reject(noOrdersMessage);
    })
    .catch(error => {
      if (error !== noOrdersMessage) {
        console.logExternally("Error while exporting orders", {
          requestParams,
          error
        });
      }

      return Promise.reject(error);
    });

const isBundleSelected = (bundleValue, amount) => {
  return bundleValue === parseInt(amount, 10);
};

const getEstimatedSellAmount = (
  cryptoAmount,
  exchange,
  activeFiat,
  activeCoin
) => {
  if (!isValidSellAmount(cryptoAmount)) {
    return DEFAULT_ESTIMATE_VALUE;
  }

  return parseFloat(
    (
      Math.ceil(+cryptoAmount * exchange[activeCoin][activeFiat].rate * 100) /
      100
    ).toFixed(defaultDecimalPlaces.fiat)
  );
};

const isUserPayNetworkFee = (activeCoin, priority) => {
  const { crypto } = currencies;
  const { ETH } = crypto;

  if (
    crypto[activeCoin].hasNetworkFee &&
    (priorities.HIGH === priority || activeCoin === ETH.code)
  ) {
    return true;
  }

  return false;
};

const getEstimatedBuyAmount = ({
  activeCoin,
  activeFiat,
  amount,
  exchange,
  networkFee,
  priority,
  paymentMethodFee = 1
}) => {
  if (!isValidBuyAmount(amount)) {
    return DEFAULT_ESTIMATE_VALUE;
  }

  const currentNetworkFee = isUserPayNetworkFee(activeCoin, priority)
    ? networkFee
    : 0;

  const { conversion_rate: conversionRate } = exchange[activeFiat][activeCoin];

  const newEstimatedAmount =
    (+amount - currentNetworkFee) * conversionRate * paymentMethodFee;

  return newEstimatedAmount > 0
    ? parseFloat(newEstimatedAmount.toFixed(defaultDecimalPlaces.crypto))
    : DEFAULT_ESTIMATE_VALUE;
};

const getFiatValue = (search, fiatParameter) => {
  const searchParams = new URLSearchParams(search);
  let fiatValue = null;

  if (searchParams.has(fiatParameter)) {
    fiatValue = searchParams.get(fiatParameter);
  }

  return fiatValue;
};

const getCoin = (search, coinParameter, currencies) => {
  const searchParams = new URLSearchParams(search);
  let coin = null;

  if (searchParams.has(coinParameter)) {
    const currentCoin = searchParams.get(coinParameter).toUpperCase();

    if (
      Object.values(currencies.crypto)
        .map(c => c.code)
        .includes(currentCoin)
    ) {
      coin = currentCoin;
    }
  }

  return coin;
};

const getCoinName = coinCode => {
  let coinName = "";

  try {
    if (coinCode) {
      coinName = Object.values(
        Object.assign({}, currencies.crypto, currencies.fiat)
      ).find(coin => coin.code === coinCode).name;
    }
  } catch (error) {}

  return coinName;
};

const getFormattedSellAmount = amount => {
  let formattedAmount = amount;
  let isDigitsBeforeComma = false;
  const positionCommaCharacter = formattedAmount.indexOf(",");
  const isCommaCharacterOnValidPosition = positionCommaCharacter > 0;

  if (isCommaCharacterOnValidPosition) {
    const digitsAsStrings = Array.from({ length: 10 }, (x, index) =>
      String(index)
    );

    isDigitsBeforeComma = formattedAmount
      .replace(",", "")
      .split("")
      .slice(0, positionCommaCharacter + 1)
      .every(character => digitsAsStrings.includes(character));
  }

  if (isDigitsBeforeComma) {
    formattedAmount = formattedAmount.replace(",", ".");
  }

  return formattedAmount;
};

export {
  buySwishInit,
  cancelOrder,
  exportOrders,
  fetchBundles,
  fetchLatestOrders,
  fetchOrders,
  getEstimatedBuyAmount,
  getEstimatedSellAmount,
  getNetworkFee,
  getOrder,
  getSwishPaymentStatus,
  getPaymentStatus,
  isBundleSelected,
  placeBuyOrder,
  placeSellOrder,
  sendReceipt,
  getFiatValue,
  getCoin,
  getCoinName,
  getFormattedSellAmount,
  sendFeedback,
  isUserPayNetworkFee
};

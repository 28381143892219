const inputConfig = {
  required: true,
  name: "walletpass",
  label: "Wallet password",
  validations: {
    minLength: 4
  },
  validationErrors: {
    minLength: "Wallet password is invalid",
    isDefaultRequiredValue: "Wallet password is invalid"
  }
};

const walletExportModalConfig = {
  maxWidth: "sm",
  hasCloseIcon: true
};

const walletExportNotifications = {
  error: "Wallet password is invalid"
};

export { inputConfig, walletExportModalConfig, walletExportNotifications };

import { localize } from "../../../../../localization/localization.service";
import { paymentMethods } from "../../../../../constants";

export const fees = {
  networkFee: {
    key: "NetworkFee",
    name: "Network fee"
  },
  paymentMethodFee: {
    key: "PaymentMethodFee",
    name: "Payment method fee"
  }
};
export const headings = [
  "In/Out",
  "Payment method",
  "Message",
  "Date",
  "Amount",
  "Value"
];

export const transactionDirections = {
  OUT: "OUT",
  IN: "IN"
};

export const transactionExplorers = {
  BTC: "https://blockchain.info/tx/",
  ETH: "https://etherscan.io/tx/",
  BCH: "https://bitcoincash.blockexplorer.com/tx/",
  LTC: "https://chain.so/tx/LTC/"
};

export const availableMessages = ["Cancelled card payment"];

export const getTransactionMethod = method => {
  const feeLabels = Object.values(Object.values(fees)).map(f => f.name);
  const paymentMethodLabels = Object.values(Object.values(paymentMethods)).map(
    f => f.code
  );

  if (feeLabels.includes(method)) {
    return localize(method);
  }

  if (paymentMethodLabels.includes(method)) {
    return localize(paymentMethods[method].name);
  }

  return method;
};

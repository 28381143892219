export const ADD_NEW_ORDER = "ADD_NEW_ORDER";
export const LOAD_MODE_ORDERS = "LOAD_MODE_ORDERS";
export const REMOVE_ORDERS_FILTER_BY_PROPERTY =
  "REMOVE_ORDERS_FILTER_BY_PROPERTY";
export const RESET_ORDERS_FILTERS = "RESET_ORDERS_FILTERS";
export const SET_DATE_FILTER = "SET_DATE_FILTER";
export const SET_ORDERS_FILTER_BY_PROPERTY = "SET_ORDERS_FILTER_BY_PROPERTY";
export const SET_ORDER_STATUS = "SET_ORDER_STATUS";
export const ORDERS_ACTION_TYPES = {
  FETCH_ORDERS_REQUEST: "FETCH_ORDERS_REQUEST",
  FETCH_ORDERS_SUCCESS: "FETCH_ORDERS_SUCCESS",
  FETCH_ORDERS_FAILURE: "FETCH_ORDERS_FAILURE"
};

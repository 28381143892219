import React, { memo } from "react";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "./AccountMenu.module.scss";

const AccountMenuItem = ({ handleCloseMenu, children, isActive }) => {
  let menuItemClassName = styles["account-menu__item"];

  if (isActive) {
    menuItemClassName += ` ${styles["account-menu__item--active"]}`;
  }

  return (
    <MenuItem onClick={handleCloseMenu} className={menuItemClassName}>
      {children}
    </MenuItem>
  );
};

AccountMenuItem.propTypes = {
  handleCloseMenu: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  isActive: PropTypes.bool
};

export default memo(AccountMenuItem);

import React from "react";
import PropTypes from "prop-types";
import PaymentImage from "./PaymentImage";
import Translation from "../../../../localization/Translation";
import BuyOrderFinishedInfo from "./BuyOrderFinishedInfo";
import transactionsList from "../../../../../resources/images/icon-transactions-list.svg";
import PaymentInstruction from "./PaymentInstruction";
import FormattedAmount from "../../../../components/formatted.amount/FormattedAmount";
import { defaultDecimalPlaces } from "../../../../constants";
import PaymentInstructionList from "./PaymentInstructionList";

const BuyOrderStep3 = ({
  orderNumber,
  amount,
  activeFiat,
  paymentMethodConfig,
  isSepa,
  isBankgiro
}) => {
  return (
    <BuyOrderFinishedInfo
      step={3}
      title={
        <Translation>{paymentMethodConfig.instructions.step3}</Translation>
      }
    >
      {isBankgiro && (
        <PaymentImage src={transactionsList} alt="order transactions list" />
      )}
      {isSepa && (
        <>
          <PaymentInstructionList
            config={paymentMethodConfig.paymentDetails}
            type={"multiple"}
          />
          <PaymentInstruction
            label={paymentMethodConfig.bic.label}
            toCopy={paymentMethodConfig.bic.value}
            customClass={true}
          >
            {paymentMethodConfig.bic.value}
          </PaymentInstruction>
          <PaymentInstruction
            label={paymentMethodConfig.iban.label}
            toCopy={paymentMethodConfig.iban.value}
          >
            {paymentMethodConfig.iban.value}
          </PaymentInstruction>
          <PaymentInstruction
            label={paymentMethodConfig.amount.label}
            additionalLabel={paymentMethodConfig.amount.additionalLabel}
            toCopy={`${amount.toFixed(
              defaultDecimalPlaces.fiat
            )} ${activeFiat}`}
          >
            <FormattedAmount currency={activeFiat}>{amount}</FormattedAmount>
          </PaymentInstruction>
          <PaymentInstruction
            label={paymentMethodConfig.message.label}
            toCopy={orderNumber}
          >
            {orderNumber}
          </PaymentInstruction>
        </>
      )}
    </BuyOrderFinishedInfo>
  );
};

BuyOrderStep3.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  orderNumber: PropTypes.string.isRequired,
  paymentMethodConfig: PropTypes.object.isRequired,
  activeFiat: PropTypes.string.isRequired
};

export { BuyOrderStep3 };

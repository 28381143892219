import React from "react";
import Icon from "../../components/icon/Icon";
import MuiIconButton from "@material-ui/core/IconButton";

const IconButton = ({ onClick, className, tabIndex, ...iconProps }) => (
  <MuiIconButton
    onClick={onClick}
    className={className}
    tabIndex={tabIndex ? tabIndex : null}
  >
    <Icon {...iconProps} />
  </MuiIconButton>
);

export default IconButton;

import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import IconButton from "../../components/icon.button/IconButton";
import WithModalEvents from "../../components/HOC/WithModalEvents";
import Dialog from "@material-ui/core/Dialog";
import styles from "./Modal.module.scss";

const Modal = ({
  isOpenModal,
  onCloseModal,
  onOpenModal,
  children,
  hasCloseIcon,
  fullScreen,
  ...dialogProps
}) => {
  const onClose = (event, reason) => {
    if (reason !== "backdropClick") {
      onCloseModal(event, reason);
    }
  };

  return (
    <Dialog
      {...dialogProps}
      onClose={onClose}
      open={isOpenModal}
      disableEscapeKeyDown={true}
      fullScreen={fullScreen}
    >
      {hasCloseIcon && (
        <IconButton
          symbol="close"
          onClick={onCloseModal}
          className={styles["modal__close-icon-btn"]}
        />
      )}
      {children !== null && (
        <div className={styles["modal__body"]}>
          {React.cloneElement(children, { onOpenModal, onCloseModal })}
        </div>
      )}
    </Dialog>
  );
};

Modal.defaultProps = {
  maxWidth: "sm",
  hasCloseIcon: true,
  className: styles["modal"],
  children: null
};

Modal.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  children: PropTypes.node,
  maxWidth: PropTypes.string,
  hasCloseIcon: PropTypes.bool
};

const mapStateToProps = state => ({
  isLowRes: state.window.isLowRes
});

const mergeProps = (
  { isLowRes, ...restStateProps },
  dispatchProps,
  ownProps
) => ({
  fullScreen: isLowRes,
  ...restStateProps,
  ...ownProps
});

export default connect(
  mapStateToProps,
  null,
  mergeProps
)(WithModalEvents(Modal));

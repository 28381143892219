export default function isPersonalNumber(value) {
  if (!value) {
    return false;
  }

  const _value = value.replace(/[- ]/g, "");

  if (
    isValidFormat(_value) &&
    isValidDate(_value) &&
    isValidChecksum(_value.slice(-10))
  ) {
    return true;
  }

  return false;
}

function isCompanyChecksum(input) {
  const checksum = input.slice(-4);
  return /^0000/.test(checksum);
}

function isValidFormat(input) {
  return /^(20|19)?\d{10}$/.test(input);
}

function isValidDate(input) {
  try {
    const t = extractDate(input.slice(0, 8));

    if (
      t.y === t.date.getFullYear() &&
      t.m === t.date.getMonth() &&
      t.d === t.date.getDate()
    ) {
      return true;
    }

    return false;
  } catch (error) {
    return false;
  }
}

function isValidChecksum(input) {
  if (isCompanyChecksum(input)) {
    return true;
  }
  let checkSum = 0;
  let n;
  for (let i = 0; i < 10; i++) {
    n = parseInt(input[i], 10);
    if (i % 2 !== 0) {
      checkSum += n;
    } else {
      checkSum += ((n * 2) % 9) + Math.floor(n / 9) * 9;
    }
  }

  if (checkSum % 10 === 0) {
    return true;
  }

  return false;
}

function extractDate(pNum) {
  const y = parseInt(pNum.slice(0, 4), 10);
  const m = parseInt(pNum.slice(4, 6) - 1, 10);
  const d = parseInt(pNum.slice(6, 8), 10);

  return {
    date: new Date(y, m, d),
    d: d,
    m: m,
    y: y
  };
}

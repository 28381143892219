export const defaultLimitations = {
  BUY: {
    SEK: {
      MIN: 10,
      MAX: 1000000
    },
    EUR: {
      MIN: 1,
      MAX: 100000
    }
  },
  SELL: {
    SEK: {
      MIN: 750,
      MAX: 100000
    },
    EUR: {
      MIN: 500,
      MAX: 50000
    }
  },
  SEK: {
    SEK: {
      MIN: 10,
      MAX: 1000000
    },
    EUR: {
      MIN: 1,
      MAX: 100000
    }
  }
};
export const temporaryEmailSufix = "@tmp.tmp";

import { defaultDecimalPlaces } from "./../../../constants";

export default function isValidSellAmount(value) {
  if (
    typeof value !== "string" ||
    Number.isNaN(+value) ||
    +value <= 0 ||
    !value.match(/^[0-9.]+$/) ||
    (value.length > 1 && value.charAt(0) === ".") ||
    (!value.match(/[.]/) && value.charAt(0) === "0") ||
    (value.match(/[.]/) &&
      value.split(".")[1].length > defaultDecimalPlaces.crypto)
  ) {
    return false;
  }

  return true;
}

import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";
import { Redirect as Redirector } from "react-router-dom";
import { paths } from "./../../routing/paths";

const Redirect = ({ to }) => <Redirector to={to} />;

Redirect.propTypes = {
  to: PropTypes.oneOf(Object.values(paths)).isRequired
};

export default withRouter(Redirect);

import BlockexplorerLink from "../../common/blockexplorer.link/BlockexplorerLink";
import CopyToClipboard from "../../../../components/copy.to.clipboard/CopyToClipboard";
import FormattedAmount from "../../../../components/formatted.amount/FormattedAmount";
import Image from "../../../../components/image/Image";
import OrderDetailsFooter from "./order.details.footer/OrderDetailsFooter";
import OrderDetailsHeader from "./order.details.header/OrderDetailsHeader";
import OrderDetailsItem from "./OrderDetailsItem";
import Print from "../../../../components/print/Print";
import React from "react";
import TransactionsList from "./order.details.transactions/TransactionsList";
import Translation from "../../../../localization/Translation";
import WalletAddress from "./../../wallet.address/WalletAddress";
import WithProfile from "./../../../../components/HOC/WithProfile";
import WithLocalization from "../../../../localization/WithLocalization";
import { orderStatuses } from "./../../../../constants";
import styles from "./OrderDetails.module.scss";

const SellOrderDetailsModalContent = ({
  localize,
  profile,
  orderDetailsRef,
  order: {
    from,
    number,
    orderDate,
    priority,
    qrCodeString,
    status,
    to,
    transactions,
    type,
    walletAddress
  }
}) => {
  let fullName = "";
  let bankName = "";
  let clearingNumber = "";
  let accountNumber = "";

  if (profile !== null) {
    fullName = `${profile.firstName} ${profile.lastName}`;

    if (profile.bankAccounts.length) {
      const bankAccount = profile.bankAccounts[0];
      bankName = bankAccount.bankName;
      clearingNumber = bankAccount.clearingNumber;
      accountNumber = bankAccount.accountNumber;
    }
  }

  const showPaymentInstructions = [
    orderStatuses.PAYOUT_PENDING.code,
    orderStatuses.PENDING.code,
    orderStatuses.UNPAID.code
  ].includes(status);

  return (
    <React.Fragment>
      <OrderDetailsHeader
        type={type}
        status={status}
        currency={from.currency}
      />

      {showPaymentInstructions && (
        <div className={styles["order-details__sell-payment"]}>
          <div className={styles["order-details__sel-payment-title"]}>
            <Translation>Payment instructions</Translation>
          </div>
          <div className={styles["order-details__sel-payment-desc"]}>
            <Translation>
              Thank you! Your order has been posted to our database. Please send
              the correct amount BTC to the wallet address below. After we
              receive the funds, we will make a transfer to your bank account.
            </Translation>
          </div>

          <div className={styles["order-details__sel-payment-crypto-label"]}>
            <Translation>Send</Translation>&nbsp;
            <FormattedAmount currency={from.currency}>
              {from.amount}
            </FormattedAmount>
            &nbsp;
            <Translation>to wallet address</Translation>:
          </div>
          <div className={styles["order-details__wallet-address"]}>
            <WalletAddress address={walletAddress} />
          </div>

          <div className={styles["order-details__copy-blockexplorer--center"]}>
            <CopyToClipboard toCopy={walletAddress} />
            <BlockexplorerLink address={walletAddress} type={from.currency} />
          </div>
          <Image
            src={qrCodeString}
            alt={type}
            className={styles["order-details__barcode"]}
          />
        </div>
      )}

      <div className={styles["order-details__main"]}>
        <div className={styles["order-details__title"]}>
          <Translation>Sell order details</Translation>
          <Print reference={orderDetailsRef} />
        </div>
        <OrderDetailsItem label="Order" value={number} />
        {priority && (
          <OrderDetailsItem label="Priority" value={localize(priority)} />
        )}
        <OrderDetailsItem label="Date" value={orderDate} />
        <OrderDetailsItem
          label="Order amount"
          content={() => (
            <FormattedAmount currency={from.currency}>
              {from.amount}
            </FormattedAmount>
          )}
        />
        <OrderDetailsItem
          label={[to.currency, "Estimated amount"]}
          content={() => (
            <FormattedAmount currency={to.currency}>
              {to.amount}
            </FormattedAmount>
          )}
        />
        {fullName && <OrderDetailsItem label="Full name" value={fullName} />}
        {bankName && <OrderDetailsItem label="Bank name" value={bankName} />}
        {accountNumber && (
          <OrderDetailsItem label="Account number" value={accountNumber} />
        )}
        {clearingNumber && (
          <OrderDetailsItem label="Clearing number" value={clearingNumber} />
        )}

        <TransactionsList transactions={transactions} />
      </div>

      <OrderDetailsFooter orderNumber={number} />
    </React.Fragment>
  );
};

export default WithProfile(WithLocalization(SellOrderDetailsModalContent));

import React from "react";
import PropTypes from "prop-types";
import Translation from "../../localization/Translation";
import { Link as ReactLink } from "react-router-dom";
import styles from "./Link.module.scss";

const Link = ({ to, text, children, className }) => (
  <ReactLink to={to} className={className}>
    <Translation>{text}</Translation>
    {children}
  </ReactLink>
);

Link.defaultProps = {
  className: styles["link"]
};

Link.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default Link;

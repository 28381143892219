import { SET_WINDOW_SIZE } from "./window.action.types";
import { isLowRes } from "./window.service";
import { mobileUserAgents } from "./window.service.config";

const initialState = {
  isLowRes: isLowRes(),
  isMobile: mobileUserAgents.some(agent =>
    navigator.userAgent.match(`/${agent}/i`)
  )
};

const windowSizeReducer = (state = initialState, action) => {
  if (action.type === SET_WINDOW_SIZE) {
    return {
      ...state,
      ...action.payload
    };
  }

  return state;
};

export default windowSizeReducer;

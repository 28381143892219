import React from "react";
import { connect } from "react-redux";
import DesktopNavigation from "./../navigation/desktop.navigation/DesktopNavigation";
import MobileNavigation from "./../navigation/mobile.navigation/MobileNavigation";
import styles from "./Header.module.scss";

const Header = ({ isLowRes }) => (
  <header className={styles["header"]}>
    {isLowRes ? <MobileNavigation /> : <DesktopNavigation />}
  </header>
);

const mapStateToProps = state => ({
  isLowRes: state.window.isLowRes
});

export default connect(mapStateToProps)(Header);

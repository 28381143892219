import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Button from "../button/Button";

const RedirectButton = ({ children, ...props }) => (
  <Button {...props} component={Link}>
    {children}
  </Button>
);

RedirectButton.propTypes = {
  children: PropTypes.string,
  to: PropTypes.string.isRequired
};

export default RedirectButton;

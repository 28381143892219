import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { themes, availableThemes } from "../themes/themes.config";
import Icon from "../components/icon/Icon";
import { setTheme } from "./themes.service";
import styles from "./ThemeToggler.module.scss";

const ThemeToggler = ({ theme, setTheme, hasMinimalLayout }) => {
  const getThemeSymbol = () =>
    Object.values(themes).filter(t => t.name === theme)[0].icon;

  const toggleTheme = e => {
    e.stopPropagation();
    const nextTheme = availableThemes.filter(t => t !== theme)[0];
    setTheme(nextTheme);
  };

  const getToggleIndicatorClass = () => {
    let toggleIndicatorClass = "theme-toggler__indicator";
    if (theme === "dark") {
      toggleIndicatorClass += "--" + theme;
    }
    return toggleIndicatorClass;
  };

  let className = styles["theme-toggler"];

  if (hasMinimalLayout) {
    className += ` ${styles["theme-toggler__min-layout"]}`;
  }

  return (
    <div className={className} onClick={toggleTheme}>
      {!hasMinimalLayout && (
        <span className={styles[getToggleIndicatorClass()]} />
      )}
      <Icon symbol={getThemeSymbol()} size={24} />
    </div>
  );
};

ThemeToggler.defaultProps = {
  hasMinimalLayout: false
};

ThemeToggler.propTypes = {
  theme: PropTypes.string.isRequired,
  setTheme: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  isLowRes: state.window.isLowRes,
  theme: state.theme
});

export default connect(mapStateToProps, { setTheme })(ThemeToggler);

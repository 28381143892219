import { RATE_ACTION_TYPES } from "./rates.action.types";

const initialRatesData = {
  BTC: { isLoading: false, isLoaded: false, isError: false, items: [] },
  BCH: { isLoading: false, isLoaded: false, isError: false, items: [] },
  LTC: { isLoading: false, isLoaded: false, isError: false, items: [] },
  ETH: { isLoading: false, isLoaded: false, isError: false, items: [] }
};

const ratesReducer = (state = initialRatesData, action) => {
  switch (action.type) {
    case RATE_ACTION_TYPES.FETCH_RATE_REQUEST:
      return {
        ...state,
        ...{
          [action.crypto]: {
            ...state[action.crypto],
            isLoading: true
          }
        }
      };
    case RATE_ACTION_TYPES.FETCH_RATE_FAILURE:
      return {
        ...state,
        ...{
          [action.crypto]: {
            ...state[action.crypto],
            isError: true,
            isLoading: false
          }
        }
      };
    case RATE_ACTION_TYPES.FETCH_RATE_SUCCESS:
      return {
        ...state,
        ...{
          [action.crypto]: {
            ...state[action.crypto],
            items: action.payload,
            isError: false,
            isLoading: false,
            isLoaded: true
          }
        }
      };
    default:
      return state;
  }
};

export default ratesReducer;

import API from "../api/backend";
import equals from "ramda/src/equals";
import notificationModelFactory from "./../../features/notifications/notification.model.factory";
import store from "../../store/store";
import { defaultLimitations, temporaryEmailSufix } from "./user.config";
import { getLocale } from "./../../localization/localization.service";
import { listOfAvailableMessages } from "../../features/important.messages/important.messages.registry";
import { parseAccessRights } from "./../../access/access.service";
import { removeCsrfCookie } from "../api/backend.service";
import { setLocalization } from "./../../localization/localization.actions";
import { setUser, setGuideDisplayed } from "./user.actions";
import {
  removePersistedItem,
  setSessionItem,
  getSessionItem
} from "../persistance/persistance.service";
import {
  currencies,
  orderTypes,
  paymentMethods,
  verificationStatuses
} from "./../../constants";
import { paymentMethodsConfig } from "../../features/orders/buy/payment.methods/payment.methods.config";
import userBankAccountModelFactory from "./user.bank.model.factory";

const userRefresh = {
  interval: parseInt(process.env.REACT_APP_USER_REFRESH_INTERVAL, 10),
  persistanceKey: "BTCX_LAST_ACCESS"
};
const externalCookie = "BTCX";
const linksConfig = {
  login: {
    en: "login/",
    sv: "logga-in/"
  }
};

export const logoutReason = {
  authorizationError: "?message=expired",
  emailConfirmation: "?message=email",
  networkError: "?message=offline",
  passwordChanged: "?message=changePassword"
};

export function init() {
  return getUser().then(user => {
    if (user && user.details && user.details.language) {
      store.dispatch(setLocalization(user.details.language));
    }

    setSessionItem(userRefresh.persistanceKey, new Date().valueOf());
  });
}

export function tryRefreshUserData() {
  if (Number.isNaN(userRefresh.interval)) {
    return getUser().catch(() => {});
  }

  const currentTimestamp = new Date().valueOf();
  const lastAccessTimestamp = parseInt(
    getSessionItem(userRefresh.persistanceKey),
    10
  );

  if (
    !lastAccessTimestamp ||
    currentTimestamp - lastAccessTimestamp > userRefresh.interval
  ) {
    getUser()
      .then(() => {
        setSessionItem(userRefresh.persistanceKey, new Date().valueOf());
      })
      .catch(() => {});
  }
}

export function getLoginUrl(queryString) {
  const locale = getLocale();
  let loginUrl = `${process.env.REACT_APP_PUBLIC_APP_URL}${locale}/${linksConfig.login[locale]}`;

  if (queryString) {
    return loginUrl.slice(0, -1) + queryString;
  }

  return loginUrl;
}

export function logout(queryString) {
  const user = store.getState().user;
  if (
    user !== null &&
    ![
      logoutReason.networkError,
      logoutReason.passwordChanged,
      logoutReason.emailConfirmation,
      logoutReason.authorizationError
    ].includes(queryString)
  ) {
    API.backendUnhandled({
      url: API.endpoints.logout,
      method: API.methods.POST
    })
      .catch(err => {
        console.logExternally(err);
      })
      .finally(() => {
        window.location.replace(getLoginUrl(queryString));

        removeCsrfCookie();
        removePersistedItem(externalCookie);
      });
  } else {
    window.location.replace(getLoginUrl(queryString));

    removeCsrfCookie();
    removePersistedItem(externalCookie);
  }
}

export function getUser() {
  return API.backend({
    method: API.methods.POST,
    url: API.endpoints.me
  })
    .then(normalizeUser)
    .then(user => {
      store.dispatch(setUser(user));
      return user;
    })
    .catch(err => {
      if (!API.isAuthorizationError(err) && !API.isNetworkError(err)) {
        logout();
      }

      return Promise.reject(err);
    });
}

export function isUserVerified() {
  try {
    const verifications = store.getState().user.verifications;

    return !(
      verifications.email !== verificationStatuses.VERIFIED ||
      verifications.identity !== verificationStatuses.VERIFIED ||
      verifications.phone !== verificationStatuses.VERIFIED ||
      verifications.residency !== verificationStatuses.VERIFIED
    );
  } catch (error) {
    console.logExternally(
      "Error while accessing store in user.service::isUserVerified"
    );

    return false;
  }
}

export function disableGuide() {
  return API.backend({
    method: API.methods.POST,
    url: API.endpoints.guide
  }).then(() => {
    store.dispatch(setGuideDisplayed());
  });
}

function getPaymentMethods(user) {
  if (user && Array.isArray(Object.keys(user.paymentMethodsDetails))) {
    return Object.keys(user.paymentMethodsDetails)
      .filter(method => Object.keys(paymentMethods).includes(method))
      .filter(method => {
        const feature = paymentMethods[method].feature;

        if (user.features[feature] && !user.features[feature].active) {
          return false;
        }

        return true;
      });
  }

  return [];
}

function getPaymentMethodsDetails(paymentMethodsDetails) {
  let formattedDetails = { ...paymentMethodsDetails };

  for (const method in formattedDetails) {
    formattedDetails[method].fiat = paymentMethodsConfig[method]
      ? paymentMethodsConfig[method].fiat.code
      : currencies.fiat.SEK.code;
  }

  return formattedDetails;
}

function getProviders(user) {
  if (user && Array.isArray(Object.keys(user.finsharkPaymentProviders))) {
    return Object.values(user.finsharkPaymentProviders);
  }

  return [];
}

function normalizeUser(rawUser) {
  const user = {};
  const { BUY, SELL } = orderTypes;

  user.details = getDetails(rawUser.details);
  user.documents = getDocumentsProps(rawUser.details);
  user.exchange = rawUser.exchange;
  user.verifications = rawUser.verifications;
  user.questionnaires = rawUser.questionnaires.map(
    ({ limit_reached, ...restProps }) => ({ ...restProps })
  );
  user.features = getFeatures(rawUser.features);
  user.limitations = getLimitations(
    rawUser.properties,
    getActiveCoins(user.features),
    defaultLimitations
  );
  user.notifications = rawUser.notifications.map((n, i) =>
    notificationModelFactory.create(n, i)
  );
  user.access = parseAccessRights(rawUser.roles, user.details);
  user.importantMessages = getImportantMessages(rawUser);
  user.paymentMethods = getPaymentMethods(rawUser);
  user.paymentMethodsDetails = getPaymentMethodsDetails(
    rawUser.paymentMethodsDetails
  );
  user.hasTemporaryEmail = getUserHasTemporaryEmail(
    rawUser.details.email,
    rawUser.verifications.email
  );
  user.enabledCoins = {
    [SELL]: getEnabledCoins(user.features, SELL),
    [BUY]: getEnabledCoins(user.features, BUY)
  };
  user.bankAccounts = getBankAccounts(rawUser.bankAccounts);
  user.finsharkPaymentProviders = getProviders(rawUser);
  user.quickpayFiatCurrency = getQuickpayFiatCurrency(rawUser.properties);
  user.goCardlessMontlyLimitCount = getGoCardlessMontlyLimit(
    rawUser.properties
  );

  if (rawUser.zendeskJwt) {
    user.zendeskJwt = rawUser.zendeskJwt;
  }

  return user;
}

function getGoCardlessMontlyLimit(properties) {
  if (properties.GOCARDLESS_REMAINED_FOR_MONTH) {
    return properties.GOCARDLESS_REMAINED_FOR_MONTH;
  }
}

function getBankAccounts(accounts) {
  const banks = accounts.map(accountData => {
    return userBankAccountModelFactory.create(accountData);
  });

  return {
    list: banks
  };
}

function getQuickpayFiatCurrency(properties) {
  if (properties.QUICKPAY_CURRENCY) {
    return properties.QUICKPAY_CURRENCY;
  }
  return paymentMethodsConfig.QuickPayCardEUR.fiat.code;
}

function getDetails(rawDetails) {
  const details = {};

  details.email = rawDetails.email;
  details.emailConfirmationOngoing = rawDetails.email_confirmation_ongoing;
  details.gaUid = rawDetails.ga_uid;
  details.guideDisplayed = !rawDetails.guide_displayed;
  details.hasWallet = rawDetails.has_wallet;
  details.isBankidUser = getIsBankidUser(rawDetails.bankid_status);
  details.language = rawDetails.language;
  details.phoneNumber = rawDetails.phone_number;
  details.isSwedishResident = rawDetails.swedish;
  details.hasFilledPersonalInfo = rawDetails.has_filled_personal_info;
  details.hasPassword = !rawDetails.needs_password_reset;
  details.enabled2FA = !!rawDetails.two_factor_enabled;
  details.passwordChangeRequired = !!rawDetails.password_change_required;

  return details;
}

function getDocumentsProps(rawDetails) {
  const documents = {};

  if (rawDetails.has_uploaded_id_back !== undefined) {
    documents.hasUploadedIdBack = rawDetails.has_uploaded_id_back;
  }

  if (rawDetails.has_uploaded_id_front !== undefined) {
    documents.hasUploadedIdFront = rawDetails.has_uploaded_id_front;
  }

  if (rawDetails.has_uploaded_residency_file !== undefined) {
    documents.hasUploadedResidency = rawDetails.has_uploaded_residency_file;
  }

  return documents;
}

function getUserHasTemporaryEmail(email, emailVerification) {
  return (
    email.endsWith(temporaryEmailSufix) &&
    emailVerification === verificationStatuses.VERIFIED
  );
}

function getEnabledCoins(features, transactionType) {
  const availableCoins = Object.values(Object.values(currencies.crypto)).map(
    c => c.code
  );
  return Object.keys(features)
    .filter(feature => feature.startsWith(`${transactionType}_`))
    .filter(feature => features[feature].active)
    .map(feature => feature.substr(transactionType.length + 1))
    .filter(feature => availableCoins.includes(feature));
}

function getIsBankidUser(bankidStatus) {
  if (!bankidStatus) {
    return false;
  }

  if (
    bankidStatus === verificationStatuses.VERIFIED ||
    bankidStatus === verificationStatuses.CONFIRMED
  ) {
    return true;
  }

  return false;
}

function getFeatures(rawFeatures) {
  const features = Object.assign({}, rawFeatures);

  features.BUY_SELL = {
    active:
      features.BUY &&
      features.BUY.active &&
      features.SELL &&
      features.SELL.active
  };

  return features;
}

export function getLimitations(properties, activeCoins, defaultLimitations) {
  const AMOUNT = "_AMOUNT_";
  const filteredProperties = {};

  if (properties.SELL_BTC_MAX_AMOUNT) {
    properties.SELL_BTC_MAX_AMOUNT_SEK = properties.SELL_BTC_MAX_AMOUNT;
    delete properties.SELL_BTC_MAX_AMOUNT;
  }

  if (properties.SELL_BTC_MIN_AMOUNT) {
    properties.SELL_BTC_MIN_AMOUNT_SEK = properties.SELL_BTC_MIN_AMOUNT;
    delete properties.SELL_BTC_MIN_AMOUNT;
  }

  for (const property in properties) {
    if (properties.hasOwnProperty(property) && property.includes(AMOUNT)) {
      filteredProperties[property] = properties[property];
    }
  }

  return {
    [orderTypes.BUY]: extractLimitations(
      filteredProperties,
      activeCoins.BUY,
      orderTypes.BUY,
      defaultLimitations
    ),
    [orderTypes.SELL]: extractLimitations(
      filteredProperties,
      activeCoins.SELL,
      orderTypes.SELL,
      defaultLimitations
    )
  };
}

function extractLimitations(
  properties,
  activeCoins,
  orderType,
  defaultLimitations
) {
  const limitations = {};
  const limits = ["MIN", "MAX"];
  const availableFiats = {
    [orderTypes.BUY]: Object.keys(currencies.fiat),
    [orderTypes.SELL]: [currencies.fiat.SEK.code],
    [currencies.fiat.SEK.code]: Object.keys(currencies.fiat)
  };
  activeCoins.push("SEK");

  for (let coin of activeCoins) {
    for (let fiat of availableFiats[orderType]) {
      for (let limit of limits) {
        limitations[coin] = limitations[coin] || {};
        limitations[coin][fiat] = limitations[coin][fiat] || {};
        limitations[coin][fiat][limit] = parseInt(
          properties[`${orderType}_${coin}_${limit}_AMOUNT_${fiat}`] ||
            defaultLimitations[orderType][fiat][limit],
          10
        );
      }
    }
  }

  activeCoins.splice(activeCoins.indexOf("SEK"), 1);

  return limitations;
}

function getActiveCoins(features) {
  const activeCoins = {
    [orderTypes.BUY]: [],
    [orderTypes.SELL]: []
  };

  Object.keys(currencies.crypto).forEach(crypto => {
    if (
      features[`${orderTypes.BUY}_${crypto}`] &&
      features[`${orderTypes.BUY}_${crypto}`].active
    ) {
      activeCoins[orderTypes.BUY].push(crypto);
    }

    if (
      features[`${orderTypes.SELL}_${crypto}`] &&
      features[`${orderTypes.SELL}_${crypto}`].active
    ) {
      activeCoins[orderTypes.SELL].push(crypto);
    }
  });

  return activeCoins;
}

function getImportantMessages(user) {
  let list = [];
  let pageList = [];
  let closedList = [];

  if (user.important && Array.isArray(user.important)) {
    list = user.important.filter(userImportantMessage =>
      listOfAvailableMessages.includes(userImportantMessage)
    );
    pageList = [...list];
  }

  try {
    const prevStateOfImportantMessages = store.getState().user
      .importantMessages;

    closedList = prevStateOfImportantMessages.closedList;

    if (equals(prevStateOfImportantMessages.list, list)) {
      pageList = prevStateOfImportantMessages.pageList;
    }
  } catch (err) {}

  return {
    list,
    pageList,
    closedList
  };
}

export function isFeatureEnabled(featureName) {
  const features = store.getState().user.features;
  const isEnabled = !!(features[featureName] && features[featureName].active);

  return isEnabled;
}

import React from "react";
import PropTypes from "prop-types";
import Input from "../input/Input";
import emailConfig from "./email.config";

const Email = ({ emailAddress, ...restProps }) => {
  const emailProps = Object.assign({}, emailConfig, restProps);

  return (
    <Input
      {...emailProps}
      showErrorOnLoad={Boolean(emailAddress)}
      value={emailAddress}
    />
  );
};

Email.propTypes = {
  emailAddress: PropTypes.string
};

export default Email;

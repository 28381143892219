import AccountMenuItems from "./AccountMenuItems";
import DropdownMenu from "../../components/dropdown.menu/DropdownMenu";
import GuideStep from "../../components/guide.step/GuideStep";
import Icon from "../../components/icon/Icon";
import PropTypes from "prop-types";
import React from "react";
import TooltipEmpty from "../../components/tooltip/TooltipEmpty";
import Translation from "../../localization/Translation";
import WithModalsEvents from "../../components/HOC/WithModalEvents";
import styles from "./AccountMenu.module.scss";
import { connect } from "react-redux";

const AccountMenu = ({ onCloseModal, isLowRes, email }) => {
  let className = styles["account-menu"];

  if (isLowRes) {
    className += ` ${styles["account-menu--mobile"]}`;
  }

  return (
    <div className={styles["account-menu__wrapper"]}>
      <DropdownMenu
        className={className}
        anchorContent={() => (
          <GuideStep step={2}>
            <Translation>Account</Translation>
            <Icon symbol="arrow-down" size={6} className="arrow-down" />
          </GuideStep>
        )}
      >
        {handleCloseMenu => (
          <AccountMenuItems
            handleCloseMenu={handleCloseMenu}
            onCloseModal={onCloseModal}
          />
        )}
      </DropdownMenu>
      {!isLowRes && (
        <TooltipEmpty title={email}>
          <div className={styles["account-menu__email"]}>{email}</div>
        </TooltipEmpty>
      )}
    </div>
  );
};

AccountMenu.propTypes = {
  isLowRes: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  isLowRes: state.window.isLowRes,
  email: state.user.details.email
});

export default WithModalsEvents(connect(mapStateToProps)(AccountMenu));

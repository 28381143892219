import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import styles from "./Loader.module.scss";

const Loader = props => (
  <div className={styles["loader-wrapper"]}>
    <CircularProgress {...props} />
  </div>
);

Loader.defaultProps = {
  size: 30
};

export default Loader;

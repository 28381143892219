export const protectedPrefix = "/protected/v2";

export const endpoints = {
  bundles: `${protectedPrefix}/buy/bundles`,
  buyOrders: `${protectedPrefix}/buy/orders`,
  confirmEmail: `${protectedPrefix}/user/email/confirm`,
  email: `${protectedPrefix}/user/email`,
  exportOrders: `${protectedPrefix}/orders/export`,
  guide: `${protectedPrefix}/auth/me/guide`,
  logout: `${protectedPrefix}/auth/signout`,
  marketIndicators: "/v2/market/indicators",
  me: `${protectedPrefix}/auth/me`,
  networkFee: `${protectedPrefix}/orders/networkfee`,
  orders: `${protectedPrefix}/orders`,
  password: `${protectedPrefix}/user/password`,
  personalProfile: `${protectedPrefix}/profile/personal`,
  profile: `${protectedPrefix}/profile`,
  profileBank: `${protectedPrefix}/profile/bank`,
  profileBankVerification: `${protectedPrefix}/profile/bank/verify`,
  profileConfirm: `${protectedPrefix}/profile/personal/confirm`,
  profileId: `${protectedPrefix}/profile/id`,
  profilePhone: `${protectedPrefix}/profile/phone`,
  profilePhoneVerification: `${protectedPrefix}/profile/phone/verify`,
  questionnaires: "/protected/questionnaire",
  rate: "/v2/graph/rate",
  emailResendSignup: `${protectedPrefix}/auth/signup/resend`,
  emailResendUser: `${protectedPrefix}/user/email/resend`,
  sellOrders: `${protectedPrefix}/sell/orders`,
  setEmail: `${protectedPrefix}/auth/signup/email`,
  settingsLanguage: `${protectedPrefix}/settings/language`,
  subscriptions: "/v2/subscriptions",
  support: "/v2/support",
  swish: `${protectedPrefix}/buy/orders/swish`,
  userLanguage: `${protectedPrefix}/user/language`,
  verifyEmail: `${protectedPrefix}/auth/signup/confirm`,
  walletDetails: `${protectedPrefix}/wallet/details`,
  notifications: `${protectedPrefix}/auth/me/notifications`,
  twoFactorAuth: `${protectedPrefix}/user/password/2fa`,
  bankMain: `${protectedPrefix}/profile/bank/main`,
  bankVerification: `${protectedPrefix}/profile/bank/verification`,
  goCardlessInit: `${protectedPrefix}/profile/bank/verification/init`,
  goCardlessVerify: `${protectedPrefix}/profile/bank/verification/verify`,
  goCardlessComplete: `${protectedPrefix}/profile/bank/verification/complete`,
  stripe: `${protectedPrefix}/buy/orders/stripe`
};

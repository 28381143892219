import React from "react";
import { connect } from "react-redux";
import Image from "../../../components/image/Image";
import Translation from "../../../localization/Translation";
import { paymentMethodsConfig } from "./footer.config";
import visaLogo from "../../../../resources/images/payment.methods/visa-logo.svg";
import mastercardLogo from "../../../../resources/images/payment.methods/mastercard-logo.svg";
import styles from "./Footer.module.scss";

const FooterPaymentMethods = ({ paymentMethods }) => {
  return (
    <>
      {paymentMethods.length && (
        <div className={styles["footer__payment"]}>
          <h3 className={styles["footer__payment-heading"]}>
            <Translation>Payment methods</Translation>
          </h3>
          <div className={styles["footer__payment-logo-wrapper"]}>
            {Object.keys(paymentMethodsConfig).map(method => {
              let paymentMethod = paymentMethodsConfig[method];
              let paymentMethodEnabled = paymentMethods.includes(method);

              if (paymentMethodEnabled) {
                switch (method) {
                  case "QuickPayCardEUR":
                    return (
                      <React.Fragment key={paymentMethod.name}>
                        <Image
                          src={visaLogo}
                          alt="Visa"
                          className={styles["footer__payment-logo"]}
                        />
                        <Image
                          src={mastercardLogo}
                          alt="MasterCard"
                          className={styles["footer__payment-logo"]}
                        />
                      </React.Fragment>
                    );
                  default:
                    return (
                      <Image
                        key={method}
                        src={paymentMethod.logo}
                        alt={paymentMethod.name}
                        className={styles["footer__payment-logo"]}
                      />
                    );
                }
              }

              return null;
            })}
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  paymentMethods: state.user.paymentMethods
});

export default connect(mapStateToProps)(FooterPaymentMethods);

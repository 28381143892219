import React from "react";
import { publish, events } from "../../services/events/events.service";

const WithModalEventsHOC = Component => {
  return function WithModal(props) {
    const onOpenModal = data => {
      publish(events.EVENT_OPEN_MODAL, data);
    };

    const onCloseModal = data => {
      publish(events.EVENT_CLOSE_MODAL, data);
    };

    return (
      <Component
        {...props}
        onOpenModal={onOpenModal}
        onCloseModal={onCloseModal}
      />
    );
  };
};

export default WithModalEventsHOC;

import React from "react";
import PropTypes from "prop-types";
import Translation from "../../../../../localization/Translation";
import Icon from "../../../../../components/icon/Icon";
import { orderFeedback } from "../order.details.config";
import {
  currencies,
  orderTypes,
  paymentMethods,
  orderStatuses
} from "../../../../../constants";
import Logo from "../../../../../components/logo/Logo";
import styles from "./OrderDetailsHeader.module.scss";

const OrderDetailsHeader = ({ status, paymentMethod, currency, type }) => {
  let coinName = "N/A";

  try {
    coinName = Object.values(
      Object.assign({}, currencies.crypto, currencies.fiat)
    ).find(c => c.code === currency).name;
  } catch (error) {}

  const isBuy = orderTypes.BUY === type;

  const getOrderStatusLabelClass = status => {
    let labelClass = "order-details-header__label";
    switch (status) {
      case orderStatuses.CANCELED.name:
      case orderStatuses.ERROR.name:
      case orderStatuses.UNPAID.name:
        return styles[labelClass] + ` ${styles[(labelClass += "--error")]}`;

      case orderStatuses.COMPLETED.name:
      case orderStatuses.PAID.name:
        return styles[labelClass] + ` ${styles[(labelClass += "--success")]}`;

      case orderStatuses.PAYOUT_PENDING.name:
      case orderStatuses.PENDING.name:
        return (
          styles[labelClass] + ` ${styles[(labelClass += "--processing")]}`
        );

      default:
        return styles[labelClass];
    }
  };

  return (
    <div className={styles["order-details-header"]}>
      <Logo className={styles["order-details-header__logo"]} />
      <div className={styles["order-details-header__content-wrapper"]}>
        <Icon symbol={type.toLowerCase()} size={36} />
        <div className={styles["order-details-header__content"]}>
          <div className={styles["order-details-header__head"]}>
            <span className={styles["order-details-header__title"]}>
              <Translation>{type.capitalizeFirstLetter()}</Translation>
              <span> {coinName} </span>
              {isBuy && (
                <>
                  <> | </>
                  <Translation>
                    {paymentMethods[paymentMethod].name}
                  </Translation>
                </>
              )}
            </span>
            <span
              className={getOrderStatusLabelClass(orderStatuses[status].name)}
            >
              <Translation>{orderStatuses[status].name}</Translation>
            </span>
          </div>
          {orderFeedback[status.toLowerCase()] && (
            <div className={styles["order-details-header__feedback"]}>
              <Translation>{orderFeedback[status.toLowerCase()]}</Translation>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

OrderDetailsHeader.propTypes = {
  status: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  paymentMethod: PropTypes.string
};

export default OrderDetailsHeader;

import React from "react";
import PropTypes from "prop-types";
import { buttonConfig } from "./button.config";
import Translation from "../../localization/Translation";
import ButtonMUI from "@material-ui/core/Button";

const Button = ({ children, variant, needsTranslation, ...restProps }) => (
  <ButtonMUI {...restProps} className={buttonConfig[variant]}>
    {needsTranslation ? <Translation>{children}</Translation> : children}
  </ButtonMUI>
);

Button.defaultProps = {
  type: "submit",
  variant: "basic",
  size: "large",
  needsTranslation: true
};

Button.propTypes = {
  variant: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired
};

export default Button;

import React from "react";
import styles from "./Logo.module.scss";
// Uncomment to reverse to used old logo
// import { ReactComponent as BtcxStandardLogo } from "../../../resources/images/btcx-standard-logo.svg";
import { ReactComponent as BtcxStandardLogo } from "../../../resources/images/btcx-standard-logo-white-new.svg";

const Logo = ({ className }) => (
  <BtcxStandardLogo
    height={36}
    width={185}
    className={`${className}  ${styles["btcx-logo"]}`}
  />
);

export default Logo;

import React, { PureComponent } from "react";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Input from "../../components/input/Input";
import Button from "../../components/button/Button";
import Form from "../../components/form/Form";
import ExternalLink from "../../components/external.link/ExternalLink";
import Translation from "../../localization/Translation";
import WalletExportContent from "./WalletExportContent";
import ModalActionButton from "../../components/modal/ModalActionButton";
import { inputConfig, walletExportNotifications } from "./wallet.export.config";
import { walletDetails } from "./wallet.export.service";
import WithGlobalMessages from "../../components/HOC/WithGlobalMessages";
import styles from "./WalletExport.module.scss";

class WalletExport extends PureComponent {
  state = {
    pwd: "",
    guid: ""
  };

  onSubmitWalletPass = model => {
    walletDetails(model.walletpass)
      .then(({ pwd, guid }) => {
        this.setState({ pwd, guid });
      })
      .catch(() => {
        const { publishGlobalMessage, variants } = this.props;

        publishGlobalMessage(walletExportNotifications.error, variants.error);
      });
  };

  render() {
    const { pwd, guid } = this.state;
    const isSubmitSuccess = pwd && guid;

    return (
      <div className={styles["wallet-export"]}>
        <MuiDialogContent className={styles["wallet-export__content"]}>
          <div>
            <h2 className={styles["wallet-export__title"]}>
              <Translation>Access Your Private Key and Wallet</Translation>
            </h2>
            <div className={styles["wallet-export__subtitle"]}>
              {isSubmitSuccess ? (
                <>
                  <Translation>
                    By using your credentials below, you can access your old
                    BTCX-wallet via
                  </Translation>
                  &nbsp;
                  <ExternalLink
                    className={styles["wallet-export__link"]}
                    href="https://www.blockchain.com/"
                  >
                    Blockchain
                  </ExternalLink>
                  .
                </>
              ) : (
                <>
                  <Translation>
                    We don't support wallet export anymore. Enter your wallet
                    password to get access credentials
                  </Translation>
                  .
                </>
              )}
            </div>
          </div>
          {isSubmitSuccess ? (
            <WalletExportContent pwd={pwd} guid={guid} />
          ) : (
            <div className={styles["wallet-export__form"]}>
              <Form onSubmit={this.onSubmitWalletPass}>
                {canSubmit => (
                  <>
                    <Input {...inputConfig} />
                    <MuiDialogActions
                      className={styles["wallet-export__actions"]}
                    >
                      <ModalActionButton
                        actionType="close"
                        variant="primary-outlined"
                      >
                        Cancel
                      </ModalActionButton>
                      <Button disabled={!canSubmit} variant="primary">
                        Send
                      </Button>
                    </MuiDialogActions>
                  </>
                )}
              </Form>
            </div>
          )}
        </MuiDialogContent>
      </div>
    );
  }
}

export default WithGlobalMessages(WalletExport);

import { graphDefaults } from "./graph.config";
import {
  SET_GRAPH_PERIOD,
  TOGGLE_GRAPH_CRYPTO,
  SET_INITIAL_GRAPH_CRYPTO
} from "./graph.action.types";

const initialState = {
  period: graphDefaults.period,
  cryptos: [graphDefaults.crypto],
  fiat: graphDefaults.fiat
};

const graphReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_GRAPH_PERIOD:
      return {
        ...state,
        period: action.payload
      };

    case TOGGLE_GRAPH_CRYPTO:
      if (state.cryptos.length === 1 && state.cryptos[0] === action.payload) {
        return state;
      }

      if (state.cryptos.includes(action.payload)) {
        return {
          ...state,
          cryptos: state.cryptos.filter(e => e !== action.payload)
        };
      }

      if (!state.cryptos.includes(action.payload)) {
        return {
          ...state,
          cryptos: [...state.cryptos, action.payload]
        };
      }
      return state;
    case SET_INITIAL_GRAPH_CRYPTO:
      return {
        ...state,
        cryptos: [action.payload]
      };
    default:
      return state;
  }
};

export default graphReducer;

import { SET_THEME } from "./themes.action.types";

const themesReducer = (state = "light", action) => {
  if (action.type === SET_THEME) {
    return action.payload;
  }
  return state;
};

export default themesReducer;

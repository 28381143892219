import Link from "../../components/link/Link";
import React from "react";
import SupportLink from "../../components/support.link/SupportLink";
import Translation from "../../localization/Translation";
import store from "./../../store/store";
import { paths } from "../../routing/paths";

const { bank, changeEmail, documents, personalProfile } = paths;

const importantMessagesRegistry = {
  VERIFY_EMAIL: () => (
    <div>
      <Translation>
        You need to verify your email address in order to access Standard BTCX.
        Please check your email and retrieve your PIN-code. Enter the PIN digits
        in
      </Translation>{" "}
      <Link text="Settings" to={changeEmail} />{" "}
      <Translation>to verify your email address.</Translation>
    </div>
  ),
  CONTACT_SUPPORT: () => (
    <div>
      <Translation>
        One or more of your orders might need to be complemented. Please check
        your e-mail. If you can't find the email or if there is any issues at
        all, please don't hesitate to contact our
      </Translation>{" "}
      <SupportLink>friendly support</SupportLink>.
    </div>
  ),
  UPLOAD_DOCUMENTS: () => (
    <div>
      <Translation>Please visit your</Translation>{" "}
      <Link text="profile page" to={documents} />{" "}
      <Translation>to upload your documents.</Translation>
    </div>
  ),
  BUY_REJECTED: () => (
    <Translation>
      At least one of the documents you have provided have been rejected. We
      have sent you an email with a detailed explanation. Please refer to this
      email to correct your application.
    </Translation>
  ),
  BUY_PENDING: () => (
    <Translation>
      You may now place an order! But please note that we won't send your
      digital currency before we have verified the submitted documents.
    </Translation>
  ),
  BUY_UNVERIFIED: () => (
    <Translation>
      To buy digital currency, you must first submit your personal details.
    </Translation>
  ),
  SELL_REJECTED: () => (
    <Translation>
      At least one of the documents you have provided have been rejected. We
      have sent you an email with a detailed explanation. Please refer to this
      email to correct your application.
    </Translation>
  ),
  SELL_PENDING: () => (
    <Translation>
      Please wait while we verify your personal details. We will send you an
      email once the verification is complete.
    </Translation>
  ),
  SELL_UNVERIFIED: () => {
    let isBankidUser = false;
    let hasFilledPersonalInfo = false;

    try {
      const userDetails = store.getState().user.details;

      isBankidUser = userDetails.isBankidUser;
      hasFilledPersonalInfo = userDetails.hasFilledPersonalInfo;
    } catch (error) {}

    const showOnlyBankRelatedMessage = isBankidUser || hasFilledPersonalInfo;

    return showOnlyBankRelatedMessage ? (
      <div>
        <Translation>
          To sell digital currency you must first submit your
        </Translation>{" "}
        <Link text="bank account details" to={bank} />.
      </div>
    ) : (
      <div>
        <Translation>
          To sell digital currency you must first submit your
        </Translation>{" "}
        <Link text="personal details" to={personalProfile} />{" "}
        <Translation>and</Translation>{" "}
        <Link text="bank account details" to={bank} />.
      </div>
    );
  },
  SELL_IMPOSSIBLE: () => (
    <Translation>
      At this point in time, the Sell-option is available only to those holding
      a Swedish bank account. BTCX is expanding, and a service for international
      clients is in development.
    </Translation>
  ),
  BANKID_UNVERIFIED: () => (
    <Translation>
      It looks like we need to verify your identity manually. Our support has
      been informed and will contact you shortly.
    </Translation>
  )
};

const listOfAvailableMessages = Object.keys(importantMessagesRegistry);

export { importantMessagesRegistry, listOfAvailableMessages };

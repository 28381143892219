import React from "react";
import PropTypes from "prop-types";
import PaymentImage from "./PaymentImage";
import Translation from "../../../../localization/Translation";
import BuyOrderFinishedInfo from "./BuyOrderFinishedInfo";
import timeLapse from "../../../../../resources/images/icon-time-lapse.svg";

const BuyOrderStep4 = ({ paymentMethodConfig, isSepa }) => {
  return (
    <BuyOrderFinishedInfo
      step={4}
      title={
        <Translation>{paymentMethodConfig.instructions.step4}</Translation>
      }
      listItem={isSepa ? true : false}
    >
      <PaymentImage src={timeLapse} alt="Payment" />
    </BuyOrderFinishedInfo>
  );
};

BuyOrderStep4.propTypes = {
  paymentMethodConfig: PropTypes.object.isRequired
};

export { BuyOrderStep4 };

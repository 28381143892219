import React from "react";
import FooterColumn from "./FooterColumn";
import styles from "./Footer.module.scss";
import { BtcxPublicLink } from "../../../components/btcx.public.link/BtcxPublicLink";
import { linksConfig } from "./../../../components/btcx.public.link/btcx.public.link.config";

const FooterBaseColumns = () => {
  const sections = Object.keys(linksConfig)
    .map(link => linksConfig[link].section)
    .filter((value, index, self) => value && self.indexOf(value) === index);

  return (
    <div className={styles["footer__base-columns"]}>
      {sections.map(section => (
        <FooterColumn heading={section} key={section}>
          <>
            {Object.keys(linksConfig).map(link => {
              if (linksConfig[link].section === section) {
                return (
                  <li key={link}>
                    <BtcxPublicLink to={link}>
                      {linksConfig[link].name}
                    </BtcxPublicLink>
                  </li>
                );
              }
              return null;
            })}
          </>
        </FooterColumn>
      ))}
    </div>
  );
};

export default FooterBaseColumns;

import { updateUserLanguage } from "./localization.repository";
import store from "./../store/store";
import { defaults } from "./../constants";
import moment from "moment";
import "moment/locale/sv";
import { isString } from "util";

export const setUserLanguage = locale =>
  updateUserLanguage(locale).then(() => moment.locale(locale));

export const localize = input => {
  if (!input) {
    return "";
  }

  if (isString(input)) {
    return getDictionaryValue(input);
  }

  if (Array.isArray(input)) {
    return input.map(key => getDictionaryValue(key)).join(" ");
  }

  throw new Error("Unable to localize, provided input is not string nor array");
};

export const getLocale = () => {
  try {
    return store.getState().localization.locale;
  } catch (error) {
    console.logExternally("Error while getting locale from state", error);

    return defaults.locale;
  }
};

function getDictionaryValue(key) {
  // whole numbers are considered as valid, non-translateable input
  if (Number.isInteger(key)) {
    return key;
  }

  if (!isString(key)) {
    throw new Error("Unable to localize, provided key is not a string");
  }

  try {
    const activeDictionary = store.getState().localization.dictionary;
    const value = activeDictionary[key];

    if (value) {
      return value;
    }

    throw new Error(`No translation defined for key ${key}`);
  } catch (error) {
    console.logExternally(error);
    return key;
  }
}

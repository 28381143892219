import React from "react";
import PropTypes from "prop-types";

const ExternalLink = ({ children, ...props }) => (
  <a {...props} rel="noopener noreferrer" target="_blank">
    {children}
  </a>
);

ExternalLink.propTypes = {
  children: PropTypes.node.isRequired
};

export default ExternalLink;

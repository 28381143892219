import React from "react";
import PropTypes from "prop-types";
import Translation from "../../../../localization/Translation";
import styles from "./PaymentInstruction.module.scss";

const PaymentInstructionList = ({ config, type }) => {
  return (
    <>
      <ul className={styles["payment-instruction-list"]}>
        {Object.keys(config).map(function(key) {
          switch (type) {
            case "single":
              return (
                <li className={styles["payment-instruction__label"]} key={key}>
                  <Translation>{config[key]}</Translation>
                </li>
              );
            case "multiple":
              return (
                <li className={styles["payment-instruction__label"]} key={key}>
                  <Translation>{config[key].label}</Translation>:{" "}
                  <Translation>{config[key].value}</Translation>
                </li>
              );
            default:
              return null;
          }
        })}
      </ul>
    </>
  );
};

PaymentInstructionList.propTypes = {
  config: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
};

export default PaymentInstructionList;

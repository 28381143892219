import { createMuiTheme } from "@material-ui/core/styles";
import URLSearchParams from "@ungap/url-search-params";
import { setThemeAction } from "./themes.actions";
import isValidThemeFromUrl from "../services/validation/validations/is.valid.theme.from.url";
import { themeUrlParameter, themeCookieName, themes } from "./themes.config";
import {
  setPersistedItem,
  getPersistedItem,
  removePersistedItem
} from "../services/persistance/persistance.service";

const fontFamily = '"Ubuntu", sans-serif';

function getTheme(themePalette) {
  return createMuiTheme({
    typography: {
      useNextVariants: true
    },
    props: {
      MuiButtonBase: {
        disableRipple: true
      }
    },
    overrides: {
      MuiTypography: {
        body2: {
          fontFamily: fontFamily,
          color: themePalette.fontPrimaryColor
        }
      },
      MuiButton: {
        text: {
          textTransform: "capitalize",
          "&:hover": {
            backgroundColor: "transparent"
          }
        },
        textPrimary: {
          textTransform: "capitalize"
        },
        root: {
          fontFamily: fontFamily,
          minHeight: "50px",
          transition: "none"
        }
      },
      MuiIconButton: {
        root: {
          "&:hover": {
            backgroundColor: "transparent",
            "& i::before": {
              color: themePalette.fontPrimaryColor
            }
          }
        }
      },
      MuiInput: {
        underline: {
          "&:before": {
            borderBottom: `1px solid ${themePalette.inputBeforeBorderColor}`
          },
          "&:after": {
            borderBottom: `2px solid ${themePalette.inputAfterBorderColor}`
          },
          "&:hover:not($disabled):not($focused):not($error):before": {
            borderBottom: `2px solid ${themePalette.hoveredInputBeforeBorderColor}`
          }
        },
        root: {
          fontFamily: fontFamily,
          color: themePalette.inputRootColor,
          minHeight: `30px`
        }
      },
      MuiInputBase: {
        input: {
          "&::-webkit-input-placeholder": {
            color: themePalette.inputPlaceholderColor,
            opacity: "1"
          }
        }
      },
      MuiInputLabel: {
        root: {
          "&&$focused": {
            color: themePalette.inputLabelFocusedColor,
            "&$error": {
              color: themePalette.inputLabelErrorColor
            }
          }
        }
      },
      MuiFormLabel: {
        root: {
          fontFamily: fontFamily,
          "&$disabled": {
            color: themePalette.formLabelColor
          }
        }
      },
      MuiCircularProgress: {
        colorPrimary: {
          color: themePalette.progressColor
        }
      },
      MuiTabs: {
        indicator: {
          height: `3px`,
          borderRadius: `2px`,
          backgroundColor: themePalette.inputLabelFocusedColor
        }
      },
      MuiTab: {
        root: {
          fontFamily: fontFamily,
          "&&$selected": {
            color: themePalette.inputLabelFocusedColor
          }
        },
        labelContainer: {
          "@media (min-width:960px)": {
            padding: `6px 0`
          }
        }
      },
      MuiFormHelperText: {
        root: {
          fontFamily: fontFamily
        }
      },
      MuiExpansionPanel: {
        root: {
          "&::before": {
            height: `0`
          }
        },
        rounded: {
          "&:last-child": {
            borderBottomLeftRadius: "9px",
            borderBottomRightRadius: "9px"
          }
        }
      },
      MuiExpansionPanelSummary: {
        root: {
          minHeight: `50px`,
          padding: `0 10px`,
          "&$expanded": {
            minHeight: `50px`
          }
        },
        content: {
          justifyContent: `center`,
          "& > :last-child": {
            paddingRight: `0`
          },
          "&$expanded": {
            margin: `0`,
            alignItems: `center`
          }
        },
        expanded: {
          margin: `0`
        },
        expandIcon: {
          color: themePalette.expandIconColor
        }
      },
      MuiRadio: {
        root: {
          color: themePalette.radioColor
        }
      },
      MuiChip: {
        label: {
          paddingLeft: `0`
        },
        deleteIcon: {
          color: themePalette.stepIconColor
        }
      },
      MuiMenuItem: {
        root: {
          fontFamily: fontFamily,
          color: themePalette.fontPrimaryColor
        },
        gutters: {
          whiteSpace: "break-spaces",
          lineHeight: "1",
          fontSize: "14px"
        }
      },
      MuiModal: {
        root: {
          zIndex: "1500"
        }
      },
      MuiDialog: {
        paperWidthXs: {
          "@media (min-width: 768px)": {
            maxWidth: "530px",
            width: "530px"
          }
        },
        paperWidthSm: {
          maxWidth: "800px",
          "@media (min-width: 768px)": {
            minWidth: "530px"
          }
        },
        paperWidthMd: {
          "@media (min-width: 768px)": {
            minWidth: "530px"
          }
        },
        paperWidthLg: {
          "@media (min-width: 768px)": {
            minWidth: "530px"
          }
        }
      },
      MuiTable: {
        root: {
          fontFamily: fontFamily
        }
      },
      MuiTableCell: {
        body: {
          color: themePalette.cellColor,
          textAlign: `left`,
          verticalAlign: `baseline`,
          fontSize: `13px`,
          "&:first-child": {
            paddingLeft: `20px`
          },
          "&:last-child": {
            paddingRight: `0`
          }
        }
      },
      MuiSelect: {
        select: {
          paddingRight: `52px`,
          display: `flex`
        },
        selectMenu: {
          "& > div": {
            overflow: "hidden",
            textOverflow: "ellipsis"
          },
          "& > span": {
            overflow: "hidden",
            textOverflow: "ellipsis"
          }
        }
      },
      MuiList: {
        padding: {
          paddingTop: `0`,
          paddingBottom: `0`
        }
      },
      MuiListItem: {
        button: {
          "&:hover": {
            backgroundColor: themePalette.listItemHoverColor
          }
        }
      },
      MuiPaper: {
        root: {
          backgroundColor: themePalette.pagePrimaryBgColor
        }
      },
      MuiStepper: {
        root: {
          backgroundColor: "inherit",
          padding: "24px 0",
          "@media (max-width: 425px)": {
            flexWrap: "wrap"
          }
        }
      },
      MuiStep: {
        root: {
          "@media (max-width: 425px)": {
            "&:nth-child(4n + 4)": {
              paddingLeft: "0"
            },
            "&:nth-child(4n + 4) > div": {
              display: "none"
            }
          }
        },
        alternativeLabel: {
          "@media (max-width: 425px)": {
            flex: "1 1 33%",
            marginBottom: "15px"
          }
        }
      },
      MuiStepIcon: {
        root: {
          color: themePalette.stepIconBgColor
        },
        text: {
          fill: themePalette.stepIconColor,
          fontWeight: "bold"
        },
        active: {
          "& $text": {
            fill: themePalette.stepIconBgColor
          }
        }
      },
      MuiStepLabel: {
        iconContainer: {
          paddingRight: "0"
        },
        label: {
          color: themePalette.inputRootColor,
          "&$active": {
            color: themePalette.inputRootColor
          },
          "&$completed": {
            color: themePalette.inputRootColor
          },
          "@media (max-width: 425px)": {
            fontSize: "12px",
            marginTop: "7px!important"
          }
        }
      },
      MuiStepConnector: {
        line: {
          borderColor: themePalette.stepLineColor
        }
      },
      MuiTooltip: {
        tooltip: {
          color: themePalette.fontPrimaryColor,
          backgroundColor: themePalette.pagePrimaryBgColor,
          fontSize: "inherit",
          whiteSpace: "pre-line"
        }
      },
      MuiCheckbox: {
        root: {
          color: themePalette.radioColor
        },
        colorPrimary: {
          color: themePalette.inputLabelFocusedColor,
          "&$checked": {
            color: themePalette.inputLabelFocusedColor
          }
        }
      }
    },
    palette: {
      primary: themePalette.primary,
      secondary: themePalette.secondary,
      grey: themePalette.grey,
      error: themePalette.error
    }
  });
}

const getThemeFromUrl = search => {
  const searchParams = new URLSearchParams(search);

  return isValidThemeFromUrl(search)
    ? searchParams.get(themeUrlParameter)
    : null;
};

const themeSettings = {
  getTheme: () => {
    return getPersistedItem(themeCookieName);
  },
  removeTheme: () => {
    removePersistedItem(themeCookieName);
  },
  setTheme: theme => {
    setPersistedItem(themeCookieName, theme);
  }
};

function getThemeFromCookie() {
  const { dark, light } = themes;
  const theme = themeSettings.getTheme();
  const isDarkTheme = theme && theme === dark.cookieValue;

  return isDarkTheme ? dark.name : light.name;
}

function setThemeCookie(theme) {
  const { dark, light } = themes;

  if (theme === light.name) {
    themeSettings.removeTheme();
  }

  if (theme === dark.name) {
    themeSettings.setTheme(dark.cookieValue);
  }
}

const setTheme = theme => dispatch => {
  dispatch(setThemeAction(theme));
  setThemeCookie(theme);

  document.getElementById("body").className = "";
  document.getElementById("body").className = `${theme}-theme`;
};

export { getThemeFromCookie, getTheme, getThemeFromUrl, setTheme };

import styles from "./Button.module.scss";

const buttonConfig = {
  primary: styles["btn-primary"],
  "primary-outlined": styles["btn-primary-outlined"],
  "primary-text": styles["btn-primary-text"],
  basic: "btn",
  "secondary-text": styles["btn-secondary-text"],
  "secondary-text-active": styles["btn-text-secondary--active"],
  grouped: styles["grouped"],
  "grouped-active": `${styles["grouped"]} ${styles["grouped--active"]}`
};

const circularProgressConfig = {
  size: 30,
  color: "inherit"
};

export { buttonConfig, circularProgressConfig };

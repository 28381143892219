import debounce from "just-debounce";
import store from "../../store/store";
import { windowResizeAction } from "./window.actions";
import {
  RESIZE_DEBOUNCE_INTERVAL,
  MOBILE_BREAKPOINT
} from "./window.service.config";

const resizeDebouncedDispatch = debounce(function(v) {
  store.dispatch(windowResizeAction());
}, RESIZE_DEBOUNCE_INTERVAL);

function init() {
  window.addEventListener("resize", function() {
    resizeDebouncedDispatch();
  });
}

function isLowRes() {
  return window.innerWidth <= MOBILE_BREAKPOINT;
}

export { init, isLowRes };

import React from "react";
import PropTypes from "prop-types";
import { BtcxPublicLink, links } from "../btcx.public.link/BtcxPublicLink";

const SupportLink = ({ children, orderNumber, anchor, className }) => {
  const btcxPublicLinkProps = {
    anchor
  };

  if (className) {
    btcxPublicLinkProps.className = className;
  }

  return (
    <BtcxPublicLink
      {...btcxPublicLinkProps}
      params={{ orderid: orderNumber }}
      to={links.contactUs}
    >
      {children}
    </BtcxPublicLink>
  );
};

SupportLink.defaultProps = {
  children: "Support"
};

SupportLink.propTypes = {
  children: PropTypes.string
};

export default SupportLink;

import { init as initPrototypes } from "./prototypes";
import { jiraTrackersConfig } from "./configuration.config";

function addJiraTrackers() {
  const head = document.getElementsByTagName("head")[0];

  for (let tracker in jiraTrackersConfig) {
    const { url, targetEnvironments } = jiraTrackersConfig[tracker];

    if (targetEnvironments.includes(process.env.REACT_APP_ENVIRONMENT)) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.defer = true;
      script.src = url;
      head.appendChild(script);
    }
  }
}

function init() {
  initPrototypes();
  addJiraTrackers();
}

export { init };

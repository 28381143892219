import basePalette from "../base.palette";

const pinkThemePalette = Object.assign({}, basePalette);

pinkThemePalette.pagePrimaryBgColor = pinkThemePalette.secondary.contrastText;
pinkThemePalette.fontPrimaryColor = pinkThemePalette.secondary.main;
pinkThemePalette.listItemHoverColor = pinkThemePalette.grey.lighter;
pinkThemePalette.inputBeforeBorderColor = pinkThemePalette.grey.light;
pinkThemePalette.inputAfterBorderColor = pinkThemePalette.primary.main;
pinkThemePalette.hoveredInputBeforeBorderColor =
pinkThemePalette.secondary.main;
pinkThemePalette.inputRootColor = pinkThemePalette.secondary.main;
pinkThemePalette.inputPlaceholderColor = pinkThemePalette.grey.main;
pinkThemePalette.inputLabelFocusedColor = pinkThemePalette.primary.main;
pinkThemePalette.inputLabelErrorColor = pinkThemePalette.error.main;
pinkThemePalette.formLabelColor = pinkThemePalette.grey.dark;
pinkThemePalette.progressColor = pinkThemePalette.primary.main;
pinkThemePalette.expandIconColor = pinkThemePalette.primary.main;
pinkThemePalette.radioColor = pinkThemePalette.grey.light;
pinkThemePalette.cellColor = pinkThemePalette.secondary.main;
pinkThemePalette.stepIconBgColor = pinkThemePalette.grey.contrastText;
pinkThemePalette.stepIconColor = pinkThemePalette.grey.dark;
pinkThemePalette.stepLineColor = pinkThemePalette.grey.light;

export default pinkThemePalette;

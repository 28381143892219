import { defaults } from "../constants";
import { SET_LOCALIZATION } from "./localization.actions";
import en from "./dictionaries/en.json";
import sv from "./dictionaries/sv.json";

const dictionaries = {
  en,
  sv
};

const initialState = {
  dictionary: dictionaries[defaults.locale],
  locale: defaults.locale
};

const localizationReducer = (state = initialState, { type, locale }) => {
  switch (type) {
    case SET_LOCALIZATION:
      return {
        dictionary: dictionaries[locale],
        locale
      };

    default:
      return state;
  }
};

export default localizationReducer;

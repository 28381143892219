import { protectedPrefix } from "./backend.endpoints";

class ActiveCallModel {
  constructor(httpCallConfigurationModel) {
    this.url = getRoute(httpCallConfigurationModel.url);
    this.params = JSON.stringify(httpCallConfigurationModel.params);
    this.method = httpCallConfigurationModel.method;
    this.promise = null;
  }
}

function getRoute(url) {
  const hasPrefix = url.indexOf(protectedPrefix) >= 0;

  if (hasPrefix) {
    return url.split(protectedPrefix)[1];
  }

  return url;
}

function create(p) {
  return Object.seal(new ActiveCallModel(p));
}

export default { create };

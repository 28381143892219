import { paths } from "../../routing/paths";
import { importantMessagesRegistry } from "./important.messages.registry";
import {
  buyMessagePrefix,
  sellMessagePrefix
} from "./important.message.config";

const isBuyMessage = messageKey => messageKey.startsWith(buyMessagePrefix);
const isSellMessage = messageKey => messageKey.startsWith(sellMessagePrefix);

const isShowBuyMessage = (messageKey, pathname) =>
  isBuyMessage(messageKey) && pathname === paths.buy;

const isShowSellMessage = (messageKey, pathname) =>
  isSellMessage(messageKey) && pathname === paths.sell;

const isGlobalMessage = messageKey =>
  !isBuyMessage(messageKey) && !isSellMessage(messageKey);

const getPageImportantMessages = (importantMessages, pathname) =>
  importantMessages.filter(
    messageKey =>
      isShowBuyMessage(messageKey, pathname) ||
      isGlobalMessage(messageKey) ||
      isShowSellMessage(messageKey, pathname)
  );

const getImportantMessages = (importantMessages, pathname) =>
  getPageImportantMessages(importantMessages, pathname).map(
    messageKey => importantMessagesRegistry[messageKey]
  );

export { getImportantMessages, getPageImportantMessages };

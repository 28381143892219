import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Translation from "../../localization/Translation";
import styles from "./List.module.scss";

const List = ({ title, items, children }) => (
  <div className={styles["list"]}>
    <div className={styles["list__title"]}>
      <Translation>{title}</Translation>
    </div>
    <div className={styles["list__content"]}>
      <Grid container spacing={24}>
        {items.map(
          ({ label, value }, key) =>
            value &&
            value != "" && (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                key={key}
                className={styles["list__item"]}
              >
                <div className={styles["list__item-label"]}>
                  <Translation>{label}</Translation>
                </div>
                <div className={styles["list__item-value"]}>{value}</div>
              </Grid>
            )
        )}
      </Grid>
      {children}
    </div>
  </div>
);

List.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired
};

export default List;

import React from "react";
import PropTypes from "prop-types";
import Translation from "../../localization/Translation";
import styles from "./Notifications.module.scss";

const NotificationsEmpty = ({ className }) => {
  let wrapperClassName = styles["notifications__empty"];

  if (className) {
    wrapperClassName += ` ${className}`;
  }

  return (
    <div className={wrapperClassName}>
      <Translation>No new notifications</Translation>
    </div>
  );
};

NotificationsEmpty.propTypes = {
  className: PropTypes.string
};

export default NotificationsEmpty;

import { ADD_ACTIVE_CALL, REMOVE_ACTIVE_CALL } from "./backend.action.types";

export const addActiveCall = payload => ({
  type: ADD_ACTIVE_CALL,
  payload
});

export const removeActiveCall = payload => ({
  type: REMOVE_ACTIVE_CALL,
  payload
});

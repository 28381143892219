import React from "react";
import MuiButton from "@material-ui/core/Button";
import Icon from "../icon/Icon";
import Translation from "../../localization/Translation";
import ReactToPrint from "react-to-print";
import styles from "./Print.module.scss";

const Print = ({ reference, className }) => (
  <div className={styles["print"]}>
    <ReactToPrint
      trigger={() => (
        <MuiButton className={className}>
          <Icon symbol="print" />
          <Translation>Print</Translation>
        </MuiButton>
      )}
      content={() => reference}
    />
  </div>
);

Print.defaultProps = {
  className: styles["print__btn"]
};

export default Print;

import bankgiroLogo from "../../../../resources/images/payment.methods/bankgirot-logo.svg";
import sepaLogo from "../../../../resources/images/payment.methods/sepa-logo.svg";
import swishLogo from "../../../../resources/images/payment.methods/swish-logo.svg";
import payexLogo from "../../../../resources/images/payment.methods/payex-logo.svg";
import visamastercardLogo from "../../../../resources/images/payment.methods/visa-mastercard-logo.svg";

const scrollTo = {
  bottom: {
    behavior: "smooth",
    block: "start"
  },
  top: {
    behavior: "smooth",
    block: "end"
  }
};

const socialMediaLinks = {
  facebook: "https://sv-se.facebook.com/btcxsweden/",
  twitter: "https://twitter.com/btcxsweden",
  linkedin: "http://linkedin.com/company/btcx",
  instagram: "https://www.instagram.com/btcx_sweden/"
};

export const paymentMethodsConfig = {
  QuickPayCard: {
    name: "Credit Card"
  },
  SEPA: {
    name: "SEPA",
    logo: sepaLogo
  },
  SWISH: {
    name: "Swish",
    logo: swishLogo
  },
  StripeSwish: {
    name: "StripeSwish",
    logo: swishLogo
  },
  StripeCard: {
    name: "StripeCard",
    logo: visamastercardLogo
  },
  BANKGIRO: {
    name: "Bankgiro",
    logo: bankgiroLogo
  },
  PAYEX: {
    name: "Payex",
    logo: payexLogo
  }
};

export { scrollTo, socialMediaLinks };

import PropTypes from "prop-types";
import React from "react";
import { getGuideOptions } from "./guide.config";
import introJs from "intro.js";
import { connect } from "react-redux";
import { disableGuide } from "./../../services/user/user.service";
import { paths } from "./../../routing/paths";
import { withRouter } from "react-router-dom";
import { ACCESS_RIGHTS } from "../../access/access.configuration";
import { VERIFICATION_KYC } from "../../features/new.kyc.questions/new.kyc.questions.constants";
import "./../../../../node_modules/intro.js/minified/introjs.min.css";
import "./Guide.scss";

class Guide extends React.PureComponent {
  componentDidMount() {
    const { isSubmittedKYCQuestionnaire } = this.props;

    if (isSubmittedKYCQuestionnaire) {
      this.tryToShowGuide();
    }
  }

  componentDidUpdate(prevProps) {
    const { isSubmittedKYCQuestionnaire } = this.props;

    if (
      prevProps.isSubmittedKYCQuestionnaire !== isSubmittedKYCQuestionnaire &&
      isSubmittedKYCQuestionnaire
    ) {
      this.tryToShowGuide();
    }
  }

  tryToShowGuide() {
    const {
      displayGuide,
      enabledGuideFeature,
      isLowRes,
      history: {
        location: { pathname }
      },
      access
    } = this.props;

    if (
      !isLowRes &&
      displayGuide &&
      enabledGuideFeature &&
      pathname === paths.buy &&
      access.includes(ACCESS_RIGHTS.CAN_SEE_FULL_APP)
    ) {
      introJs()
        .setOptions(getGuideOptions())
        .onexit(disableGuide)
        .start();
    }
  }

  render() {
    return this.props.children;
  }
}

const mapStateToProps = state => {
  const KYCquestionnaireIndex = state.user.questionnaires.findIndex(
    q => q.key === VERIFICATION_KYC
  );

  const isSubmittedKYCQuestionnaire =
    KYCquestionnaireIndex > -1 &&
    state.user.questionnaires[KYCquestionnaireIndex].submitted === true;

  return {
    displayGuide: state.user.details.guideDisplayed,
    enabledGuideFeature:
      state.user.features.GUIDE && state.user.features.GUIDE.active,
    isLowRes: state.window.isLowRes,
    access: state.user.access,
    isSubmittedKYCQuestionnaire
  };
};

Guide.propTypes = {
  displayGuide: PropTypes.bool.isRequired,
  enabledGuideFeature: PropTypes.bool.isRequired
};

export default withRouter(connect(mapStateToProps, null)(Guide));

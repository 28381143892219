import React from "react";
import PropTypes from "prop-types";
import styles from "./BuyOrderFinishedInfo.module.scss";

const BuyOrderFinishedInfo = ({ step, title, children, text, listItem }) => (
  <div className={styles["buy-finised-info"]}>
    <div className={styles["buy-finised-info__oval-wrapper"]}>
      <span className={styles["buy-finised-info__oval"]}>{step}</span>
    </div>
    {listItem && (
      <ul className={styles["buy-finised-info__title--list"]}>
        <li className={styles["buy-finised-info__title--list-item"]}>
          {title}
        </li>
      </ul>
    )}
    {!listItem && (
      <div className={styles["buy-finised-info__title"]}>{title}</div>
    )}

    {children && (
      <div className={styles["buy-finised-info__main"]}>
        {children}
        {text && (
          <span className={styles["buy-finised-info__text"]}>{text}</span>
        )}
      </div>
    )}
  </div>
);

BuyOrderFinishedInfo.propTypes = {
  step: PropTypes.number.isRequired,
  title: PropTypes.node.isRequired
};

export default BuyOrderFinishedInfo;

import activeCallModelFactory from "./backend.active.call.model.factory";
import axios from "axios";
import axiosRetry from "axios-retry";
import backendThunk from "./backend.thunk";
import callConfigurationModelFactory from "./backend.call.configuration.model.factory";
import errors from "./backend.errors";
import { API_RESPONSE_STATUS } from "./backend.config";
import { endpoints } from "./backend.endpoints";
import { logout, logoutReason } from "../../services/user/user.service";
import { methods } from "./backend.methods";
import { setCsrfCookie } from "./backend.service";
import {
  findActiveCallInRegistry,
  registerActiveCall,
  unregisterActiveCall
} from "./backend.active.calls.registry";

axiosRetry(axios, {
  retries: 3,
  shouldResetTimeout: true,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition
});

export default {
  backend,
  backendThunk,
  backendUnhandled,
  endpoints,
  methods,
  isNetworkError,
  isAuthorizationError
};

function setCsrfCookieOnPostRequest(method) {
  if (method === methods.POST) {
    setCsrfCookie();
  }
}

function backend(params) {
  setCsrfCookieOnPostRequest(params.method);

  const callConfig = callConfigurationModelFactory.create(params);
  const activeCall = activeCallModelFactory.create(callConfig);
  const existingActiveCall = findActiveCallInRegistry(activeCall);
  let promise;

  if (existingActiveCall) {
    promise = existingActiveCall.promise;
  } else {
    const newPromise = axios(callConfig);

    activeCall.promise = newPromise;
    registerActiveCall(activeCall);
    promise = newPromise;
  }

  return promise
    .then(res => ({ res, callConfig }))
    .then(handleSuccessResponse)
    .catch(err => handleErrorResponse(err))
    .finally(() => unregisterActiveCall(activeCall));
}

function backendUnhandled(params) {
  setCsrfCookieOnPostRequest(params.method);

  const callConfig = callConfigurationModelFactory.create(params);
  const activeCall = activeCallModelFactory.create(callConfig);

  const existingActiveCall = findActiveCallInRegistry(activeCall);

  if (existingActiveCall) {
    unregisterActiveCall(activeCall);
  }

  return axios(callConfig);
}

function handleSuccessResponse({ res, callConfig }) {
  if (
    (callConfig.headers &&
      callConfig.headers["Content-Type"] === "multipart/form-data") ||
    callConfig.responseType === "blob"
  ) {
    return res.data;
  }

  if (
    (callConfig.url.startsWith(endpoints.questionnaires) &&
      callConfig.method === methods.POST) ||
    (callConfig.url.startsWith(endpoints.bankMain) &&
      callConfig.method === methods.PUT) ||
    (callConfig.url.startsWith(endpoints.profileBank) &&
      callConfig.method === methods.DELETE)
  ) {
    return res.status;
  }

  if (!isStandardResponse(res.data)) {
    throw new Error(errors.MALFORMED_RESPONSE);
  }

  if (res.data && res.data.status === API_RESPONSE_STATUS.ERROR) {
    throw new Error(errors.NO_SUCCESS_RESPONSE + res.config.url);
  }

  return res.data.result;
}

function handleErrorResponse(err) {
  switch (true) {
    case isNetworkError(err):
      logout(logoutReason.networkError);
      return Promise.reject(errors.NETWORK_ERROR);

    case isAuthorizationError(err):
      logout(logoutReason.authorizationError);
      return Promise.reject(err);

    case isPropagatedError(err):
      return Promise.reject(err);

    case isMalformedResponse(err):
      throw new Error(errors.MALFORMED_RESPONSE);

    case hasServerErrorMessage(err):
      return Promise.reject(getServerErrorMessage(err.response.data.result));

    default:
      return Promise.reject(
        err.response && err.response.data ? err.response.data : err.response
      );
  }
}

function hasServerErrorMessage(err) {
  if (
    err.response &&
    err.response.data &&
    err.response.data.status === API_RESPONSE_STATUS.ERROR &&
    err.response.data.result &&
    ((err.response.data.result.errors &&
      err.response.data.result.errors.length) ||
      err.response.data.result.fieldErrors)
  ) {
    return true;
  }

  return false;
}

function getServerErrorMessage(result) {
  // always returns just one (first) error
  if (result.errors && result.errors.length) {
    return result.errors[0];
  }

  return result.fieldErrors[Object.keys(result.fieldErrors)[0]];
}

function isMalformedResponse(err) {
  if (err === errors.MALFORMED_RESPONSE) {
    return true;
  }

  return false;
}

function isPropagatedError(err) {
  if (
    err.response &&
    (err.response.status === 500 || err.response.status === 404)
  ) {
    return true;
  }

  return false;
}

function isStandardResponse(res) {
  return !!(res.hasOwnProperty("status") && res.hasOwnProperty("result"));
}

function isAuthorizationError(err) {
  return !!(
    err.response &&
    (err.response.status === 403 || err.response.status === 401)
  );
}

function isNetworkError(err) {
  if (!err.response || (err.response && err.response.status === 502)) {
    return true;
  }

  return false;
}

function retryCondition(error) {
  if (
    !error.response ||
    error.code === "ECONNABORTED" ||
    error.response.status > 500
  ) {
    return true;
  }

  return false;
}

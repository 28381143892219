export const SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD";
export const SET_AMOUNTS = "SET_AMOUNTS";
export const SET_WALLET_ADDRESS = "SET_WALLET_ADDRESS";
export const SET_ACTIVE_COIN = "SET_ACTIVE_COIN";
export const SET_NETWORK_FEE = "SET_NETWORK_FEE";
export const SET_PRIORITY = "SET_PRIORITY";
export const SET_ACTIVE_FIAT = "SET_ACTIVE_FIAT";
export const SET_PROVIDER_NAME = "SET_PROVIDER_NAME";
export const RESET_BUY_ORDER = "RESET_BUY_ORDER";
export const REPEAT_BUY_ORDER = "REPEAT_BUY_ORDER";

import { isError } from "@sentry/utils";

const sentryConfig = {
  dsn: process.env.REACT_APP_SENTRY_DNS,
  debug: process.env.REACT_APP_SENTRY_DEBUG,
  environment: process.env.REACT_APP_ENVIRONMENT,
  release: process.env.REACT_APP_SENTRY_RELEASE,
  serverName: window.location.host
};

export function init() {
  loadSentry()
    .then(extendLoggingMechanism)
    .catch(() => {
      window.console.logExternally = function() {
        /* void */
      };
    });
}

function loadSentry() {
  return new Promise((resolve, reject) => {
    if (process.env.REACT_APP_SENTRY_ENABLED === "true") {
      return import("@sentry/browser")
        .then(sentry => resolve(sentry))
        .catch(reject);
    }

    reject();
  });
}

function extendLoggingMechanism({ init, captureException, setExtras }) {
  init(sentryConfig);

  window.console.logExternally = function(topic, data) {
    const _topic = isError(topic) ? topic : new Error(topic);

    try {
      if (data) {
        setExtras(data);
      }

      captureException(_topic);
    } catch {}
  };
}

import basePalette from "../base.palette";

const darkThemePalette = Object.assign({}, basePalette);

darkThemePalette.pagePrimaryBgColor = darkThemePalette.secondary.main;
darkThemePalette.fontPrimaryColor = darkThemePalette.secondary.contrastText;
darkThemePalette.listItemHoverColor = darkThemePalette.primary.light;
darkThemePalette.inputBeforeBorderColor = darkThemePalette.grey.light;
darkThemePalette.inputAfterBorderColor = darkThemePalette.primary.light;
darkThemePalette.hoveredInputBeforeBorderColor = darkThemePalette.primary.light;
darkThemePalette.inputRootColor = darkThemePalette.secondary.contrastText;
darkThemePalette.inputPlaceholderColor = darkThemePalette.grey.main;
darkThemePalette.inputLabelFocusedColor = darkThemePalette.primary.light;
darkThemePalette.inputLabelErrorColor = darkThemePalette.error.main;
darkThemePalette.formLabelColor = darkThemePalette.grey.dark;
darkThemePalette.progressColor = darkThemePalette.primary.light;
darkThemePalette.expandIconColor = darkThemePalette.primary.light;
darkThemePalette.radioColor = darkThemePalette.primary.light;
darkThemePalette.cellColor = darkThemePalette.secondary.contrastText;
darkThemePalette.stepIconBgColor = darkThemePalette.grey.contrastText;
darkThemePalette.stepIconColor = darkThemePalette.grey.dark;
darkThemePalette.stepLineColor = darkThemePalette.grey.light;

export default darkThemePalette;

import { validate } from "wallet-address-validator";
import MultiCoinAddressValidator from "multicoin-address-validator";
import { currencies } from "../../../constants";
import {
  isCashAddress,
  isLegacyAddress,
  isBitpayAddress
} from "bchaddrjs/src/bchaddr";

const taprootAddressPrefix = "bc1p";

const isTaprootBTCAddress = address => {
  const regExp = /^((bc)(0([ac-hj-np-z02-9]{39}|[ac-hj-np-z02-9]{59})|1[ac-hj-np-z02-9]{8,89}))$/;

  try {
    return address.startsWith(taprootAddressPrefix) && regExp.test(address);
  } catch (err) {
    return false;
  }
};

export const walletAddressValidation = (value, type) => {
  if (typeof value !== "string" || typeof type !== "string") {
    return false;
  }

  if (type === currencies.crypto.BTC.code) {
    return (
      isTaprootBTCAddress(value) ||
      (validate(value, type) && MultiCoinAddressValidator.validate(value, type))
    );
  }

  if ([currencies.crypto.LTC.code, currencies.crypto.ETH.code].includes(type)) {
    return validate(value, type);
  }
  if (type === currencies.crypto.BCH.code) {
    return bchAddressValidation(value);
  }
};

function bchAddressValidation(address) {
  let isValid = false;

  try {
    isValid =
      isCashAddress(address) ||
      isLegacyAddress(address) ||
      isBitpayAddress(address);
  } catch (error) {}

  return isValid;
}

import PROFILE_ACTION_TYPES, {
  UPDATE_BANK_ACCOUNT,
  SELECT_BANK_ACCOUNT,
  MARK_BANK_ACCOUNT_AS_MAIN,
  DELETE_BANK_ACCOUNT,
  UPDATE_BANK_ACCOUNT_VERIFICATION
} from "./profile.action.types";

const initialState = {
  isLoading: false,
  isError: false,
  payload: null,
  selectedBankAccountIndex: 0,
  selectedBankAccount: false
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_ACTION_TYPES.FETCH_PROFILE_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case PROFILE_ACTION_TYPES.FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        payload: action.payload
      };
    case PROFILE_ACTION_TYPES.FETCH_PROFILE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        payload: null
      };
    case UPDATE_BANK_ACCOUNT:
      const bankAccounts = state.payload.bankAccounts;
      const bankAccountIndex = action.payload.bankAccountIndex;
      bankAccounts[bankAccountIndex] = {
        ...bankAccounts[bankAccountIndex],
        ...action.payload.bankAccount
      };

      return {
        ...state,
        payload: {
          ...state.payload,
          bankAccounts
        }
      };
    case SELECT_BANK_ACCOUNT:
      return {
        ...state,
        selectedBankAccountIndex: action.payload,
        selectedBankAccount: true
      };
    case MARK_BANK_ACCOUNT_AS_MAIN:
      return {
        ...state,
        payload: {
          ...state.payload,
          bankAccounts: state.payload.bankAccounts.map((account, index) => {
            account.isMain = index === action.bankAccountIndex;
            return account;
          })
        }
      };
    case DELETE_BANK_ACCOUNT:
      const newBankAccounts = state.payload.bankAccounts.filter(
        (account, index) => {
          return index !== action.bankAccountIndex;
        }
      );

      return {
        ...state,
        payload: {
          ...state.payload,
          bankAccounts: newBankAccounts
        },
        selectedBankAccountIndex: newBankAccounts.findIndex(
          account => account.isMain
        )
      };
    case UPDATE_BANK_ACCOUNT_VERIFICATION:
      return {
        ...state,
        payload: {
          ...state.payload,
          bankAccounts: state.payload.bankAccounts.map((account, index) => {
            if (index !== action.bankAccountIndex) {
              account.verificationStatus = action.verificationStatus;
            }
            return account;
          })
        }
      };
    default:
      return state;
  }
};

export default profileReducer;

import store from "./../store/store";
import { ACCESS_RIGHTS, ROLES } from "./access.configuration";

const hasSufficientAccessRights = accessRights => {
  const userAccessRights = getUserAccessRights();

  if (accessRights.length === 1) {
    return (
      accessRights.includes(ACCESS_RIGHTS.CAN_SEE_BASIC_APP) ||
      userAccessRights.includes(accessRights[0])
    );
  }

  return accessRights.every(accessRight =>
    userAccessRights.includes(accessRight)
  );
};

function getUserAccessRights() {
  let accessRights = [];

  try {
    const storeInstance = store.getState();
    accessRights = storeInstance.user.access;
  } catch (error) {
    console.logExternally(
      "Error while accessing store in access.service::getUserAccessRights"
    );
  }

  return accessRights;
}

function parseAccessRights(roles, userDetails) {
  const accessRights = [];

  if (roles && Array.isArray(roles) && roles.length) {
    if (roles.includes(ROLES.isNotVerified)) {
      accessRights.push(ACCESS_RIGHTS.CAN_SEE_BASIC_APP);
    }

    if (roles.includes(ROLES.isVerifiedEmail)) {
      accessRights.push(ACCESS_RIGHTS.CAN_SEE_FULL_APP);
    }

    if (roles.includes(ROLES.isVerified)) {
      accessRights.push(ACCESS_RIGHTS.CAN_PLACE_BUY_ORDER);

      if (roles.includes(ROLES.isVerifiedBankAccount)) {
        accessRights.push(ACCESS_RIGHTS.CAN_PLACE_SELL_ORDER);
      }

      if (roles.includes(ROLES.isSwishEnabled)) {
        accessRights.push(ACCESS_RIGHTS.CAN_PLACE_SWISH_BUY_ORDER);
      }
    }
  }

  if (userDetails) {
    if (userDetails.isSwedishResident) {
      accessRights.push(ACCESS_RIGHTS.CAN_SEE_ONLY_SWEDISH_USER);
    }

    if (userDetails.isBankidUser) {
      accessRights.push(ACCESS_RIGHTS.CAN_SEE_ONLY_BANKID_USER);
    }

    if (userDetails.hasFilledPersonalInfo) {
      accessRights.push(ACCESS_RIGHTS.CAN_SEE_DOCUMENTS);
    }
  }

  return accessRights;
}

export { ACCESS_RIGHTS, hasSufficientAccessRights, parseAccessRights };

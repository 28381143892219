import React from "react";

import Modal from "../../components/modal/Modal";
import {
  subscribe,
  unsubscribe,
  events
} from "../../services/events/events.service";

class StandardLayoutModal extends React.Component {
  state = {
    content: null,
    isOpenModal: false,
    modalConfig: {},
    onCloseCallback: function() {}
  };

  tryExecuteOnCloseCallback = data => {
    const onCloseCallback = this.state.onCloseCallback;

    if (onCloseCallback && typeof onCloseCallback === "function") {
      onCloseCallback(data);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { isOpenModal } = this.state;

    if (!isOpenModal && isOpenModal !== prevState.isOpenModal) {
      this.tryExecuteOnCloseCallback();
    }
  }

  componentDidMount() {
    this.onOpenModalSubscriber = subscribe(
      events.EVENT_OPEN_MODAL,
      this.handleOpenEvent
    );
    this.onCloseModalSubscriber = subscribe(
      events.EVENT_CLOSE_MODAL,
      this.closeModal
    );
  }

  componentWillUnmount = () => {
    unsubscribe(this.onOpenModalSubscriber);
    unsubscribe(this.onCloseModalSubscriber);
  };

  handleOpenEvent = (e, data) => {
    this.setState({
      isOpenModal: true,
      ...data
    });
  };

  closeModal = (e, data) => {
    this.tryExecuteOnCloseCallback(data);

    this.setState({
      isOpenModal: false,
      content: null,
      onCloseCallback: null
    });
  };

  render() {
    const { content, isOpenModal, modalConfig } = this.state;

    return (
      <Modal {...modalConfig} isOpenModal={isOpenModal}>
        {content}
      </Modal>
    );
  }
}

export default StandardLayoutModal;

import newOrderSellModelFactory from "./new.order.sell.model.factory";
import {
  SET_SELL_ACTIVE_COIN,
  SET_SELL_AMOUNT,
  RESET_SELL_STATE,
  REPEAT_SELL_ORDER
} from "./sell.action.types";

const initialState = newOrderSellModelFactory.create();

const sellReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELL_ACTIVE_COIN:
      return {
        ...state,
        activeCoin: action.payload
      };

    case SET_SELL_AMOUNT:
      return {
        ...state,
        amount: action.payload.amount,
        estimatedAmount: action.payload.estimatedAmount
      };

    case REPEAT_SELL_ORDER:
      return {
        ...initialState,
        ...action.payload
      };

    case RESET_SELL_STATE:
      return initialState;

    default:
      return state;
  }
};

export default sellReducer;

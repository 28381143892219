import PropTypes from "prop-types";
import React from "react";
import Redirect from "./../components/redirect/Redirect";
import WithProfile from "../components/HOC/WithProfile";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import { verificationStatuses } from "../constants";
import { layouts } from "./../layout/layout.config";
import { paths } from "./paths";
import {
  events as analyticsEvents,
  sendAnalyticsEvent
} from "../services/analytics/analytics.service";
import { VERIFICATION_KYC } from "../../app/features/new.kyc.questions/new.kyc.questions.constants";

const { VERIFIED } = verificationStatuses;

const shouldRedirectToWizard = ({
  path,
  isBankidUser,
  verifications,
  twoFactorMandatory,
  enabledTwoFactor,
  hasPassword,
  hasBankAccounts
}) => {
  const isVerifiedPhone = verifications.phone === VERIFIED;
  const isVerifiedEmail = verifications.email === VERIFIED;
  const isVerifiedIdentity = verifications.identity === VERIFIED;
  const phoneVerificationPage = path === paths.phone;
  const requirePhoneVerificationInWizard =
    !isVerifiedPhone && !phoneVerificationPage;
  const requireTwoFatorInWizard = twoFactorMandatory && !enabledTwoFactor;

  const shouldRedirectBankidUser =
    isBankidUser &&
    ([isVerifiedEmail, isVerifiedIdentity].some(v => v !== true) ||
      requirePhoneVerificationInWizard ||
      requireTwoFatorInWizard ||
      !hasPassword ||
      hasBankAccounts);
  const shouldRedirectCredentialUser =
    !isBankidUser &&
    (!isVerifiedEmail ||
      requirePhoneVerificationInWizard ||
      requireTwoFatorInWizard);

  return shouldRedirectBankidUser || shouldRedirectCredentialUser;
};

const AppRoute = props => {
  const {
    component: Component,
    exact,
    layout,
    path,
    profile,
    isBankidUser,
    twoFactorMandatory,
    enabledTwoFactor,
    verifications,
    shouldShowKYCquestionnaire,
    hasPassword,
    hasBankAccounts,
    ...componentProps
  } = props;
  const pathname = componentProps.location.pathname;
  const Layout = layout ? layouts[layout].layout : layouts.standard.layout;
  const isVerificationPathname = path === paths.verification;
  const isSecondVerificationPathname = path === paths.secondVerification;
  sendAnalyticsEvent(analyticsEvents.pageview, pathname);

  if (shouldShowKYCquestionnaire && !isSecondVerificationPathname) {
    return <Redirect to={paths.secondVerification} />;
  }

  if (
    (isVerificationPathname &&
      !shouldRedirectToWizard({
        path,
        isBankidUser,
        verifications,
        twoFactorMandatory,
        enabledTwoFactor,
        hasPassword,
        hasBankAccounts
      })) ||
    (isSecondVerificationPathname && !shouldShowKYCquestionnaire)
  ) {
    return <Redirect to={paths.buy} />;
  }

  if (
    !isVerificationPathname &&
    !shouldShowKYCquestionnaire &&
    shouldRedirectToWizard({
      path,
      isBankidUser,
      verifications,
      twoFactorMandatory,
      enabledTwoFactor,
      hasPassword,
      hasBankAccounts
    })
  ) {
    return <Redirect to={paths.verification} />;
  }

  return (
    <Route
      path={path}
      exact={exact}
      render={() => (
        <Layout>
          <Component {...componentProps} />
        </Layout>
      )}
    />
  );
};

AppRoute.propTypes = {
  component: PropTypes.func.isRequired,
  exact: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  layout: PropTypes.string
};

const mapStateToProps = state => {
  const KYCquestionnaireIndex = state.user.questionnaires.findIndex(
    q => q.key === VERIFICATION_KYC
  );

  const isEmailVerified =
    state.user.verifications.email === verificationStatuses.VERIFIED;

  const isPhoneVerified =
    state.user.verifications.phone === verificationStatuses.VERIFIED;

  const isIndetityVerified =
    state.user.verifications.identity === verificationStatuses.VERIFIED;

  const enabledTwoFactor = state.user.details.enabled2FA;
  const twoFactorMandatory =
    !!(
      state.user.features.TWO_FACTOR_MANDATORY &&
      state.user.features.TWO_FACTOR_MANDATORY.active === true
    ) &&
    !!(
      state.user.features.TWO_FACTOR_AUTH &&
      state.user.features.TWO_FACTOR_AUTH.active === true
    );
  const isBankidUser = state.user.details.isBankidUser;
  const hasPassword = state.user.details.hasPassword;
  const hasBankAccounts = state.user.bankAccounts.list.length > 0;

  let isVerifiedBankidUser =
    isBankidUser &&
    isIndetityVerified &&
    isPhoneVerified &&
    hasPassword &&
    !hasBankAccounts;
  let isVerifiedCredentialsUser =
    !isBankidUser && isEmailVerified && isPhoneVerified && hasPassword;

  if (twoFactorMandatory) {
    isVerifiedBankidUser = isVerifiedBankidUser && enabledTwoFactor;
    isVerifiedCredentialsUser = isVerifiedCredentialsUser && enabledTwoFactor;
  }

  return {
    isBankidUser: state.user.details.isBankidUser,
    verifications: state.user.verifications,
    twoFactorMandatory: twoFactorMandatory,
    enabledTwoFactor: enabledTwoFactor,
    shouldShowKYCquestionnaire:
      state.user.questionnaires[KYCquestionnaireIndex] &&
      (state.user.questionnaires[KYCquestionnaireIndex].submitted === false ||
        state.user.questionnaires[KYCquestionnaireIndex].status ===
          verificationStatuses.REJECTED) &&
      (isVerifiedBankidUser || isVerifiedCredentialsUser),
    hasPassword: hasPassword,
    hasBankAccounts
  };
};

export default connect(mapStateToProps)(WithProfile(AppRoute));

import React from "react";
import PropTypes from "prop-types";
import { getProfile } from "../../features/profile/profile.service";
import { connect } from "react-redux";
import Loader from "../../components/loader/Loader";

const WithProfileHOC = Component => {
  class WithProfile extends React.Component {
    constructor(props) {
      super(props);

      const { profile, getProfile } = this.props;
      if (profile.payload === null) {
        getProfile();
      }
    }

    static propTypes = {
      getProfile: PropTypes.func.isRequired,
      profile: PropTypes.object.isRequired
    };

    render() {
      const { dispatch, ...props } = this.props;
      const profilePayload = this.props.profile.payload;

      if (!profilePayload) {
        return <Loader />;
      }

      props.profile = props.profile.payload;

      return <Component {...props} />;
    }
  }

  const mapStateToProps = state => ({
    profile: state.profile
  });

  const mapDispatchToProps = {
    getProfile
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(WithProfile);
};

export default WithProfileHOC;

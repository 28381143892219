import payexIcon from "../../../../../resources/images/icon-payex.png";
import swishIcon from "../../../../../resources/images/swish-icon.png";
import swishIcon2x from "../../../../../resources/images/swish-icon@2x.png";
import swishIcon3x from "../../../../../resources/images/swish-icon@3x.png";
import {
  iban,
  swift,
  currencies,
  paymentMethods as PAYMENT_METHODS
} from "../../../../constants";

export const paymentMethodsConfig = {
  BANKGIRO: {
    name: "Bankgiro",
    label: "Bankgiro number",
    orc: {
      label: "OCR Number"
    },
    amount: {
      label: "Amount",
      additionalLabel: "Send exactly this amount"
    },
    needsNextSteps: true,
    needsPersonalInformation: false,
    number: "145-7696",
    fiat: currencies.fiat.SEK,
    isPrintBreakBeforeOrderDetails: true,
    instructions: {
      step1: "Log into your bank to initiate the payment",
      step2: "Make a payment with the info below",
      step3: "Go to BTCX and check order transactions list",
      step4:
        "BTCX will process your order as soon as we have received the payment. Typically, this process takes a few hours to one to two business days (Mon-Fri)"
    }
  },
  PAYEX: {
    name: "Payex",
    icon: {
      src: payexIcon
    },
    subtitle: `It can take up to 2 business days for the bank to confirm this transactions. We will notify you once it is done.`,
    needsNextSteps: false,
    needsPersonalInformation: false,
    fiat: currencies.fiat.SEK,
    isPrintBreakBeforeOrderDetails: false
  },
  StripeSwish: {
    name: "StripeSwish",
    label: "Swish",
    icon: {
      src: swishIcon,
      srcSet: `${swishIcon2x} 2x, ${swishIcon3x} 3x`
    },
    fiat: currencies.fiat.SEK,
    androidAddress: `intent://view/#Intent;scheme=swish;package=se.bankgirot.swish;
    action=android.intent.action.VIEW;category=android.intent.category.DEFAULT;
    category=android.intent.category.BROWSABLE;end`,
    subtitle:
      "Thank you for using BTCX. Follow updates about your order via email and sms",
    needsNextSteps: false,
    needsPersonalInformation: true,
    isPrintBreakBeforeOrderDetails: false
  },
  StripeCard: {
    name: "StripeCard",
    label: "Credit / Debit Card (SEK)",
    code: "StripeCard",
    fiat: currencies.fiat.SEK,
    isPrintBreakBeforeOrderDetails: false
  },
  SWISH: {
    name: "Swish",
    icon: {
      src: swishIcon,
      srcSet: `${swishIcon2x} 2x, ${swishIcon3x} 3x`
    },
    fiat: currencies.fiat.SEK,
    androidAddress: `intent://view/#Intent;scheme=swish;package=se.bankgirot.swish;
                     action=android.intent.action.VIEW;category=android.intent.category.DEFAULT;
                     category=android.intent.category.BROWSABLE;end`,
    subtitle:
      "Thank you for using BTCX. Follow updates about your order via email and sms",
    needsNextSteps: false,
    needsPersonalInformation: true,
    isPrintBreakBeforeOrderDetails: false
  },
  SEPA: {
    name: "SEPA",
    label: "SEPA (Bank Transfer)",
    needsNextSteps: true,
    needsTransferDetails: true,
    fiat: currencies.fiat.EUR,
    bic: {
      label: "BIC (SWIFT)",
      value: swift
    },
    iban: {
      label: "IBAN",
      value: iban
    },
    accountOwner: {
      address: "KIVRA: 556911-9992",
      city: "106 31 Stockholm",
      country: "Sweden",
      name: "Goobit AB"
    },
    bank: {
      address: "6th Floor Manfeld House, 1 Southampton Street",
      city: "London",
      country: "United Kingdom",
      name: "Clear Junction Limited"
    },
    amount: {
      label: "Amount",
      additionalLabel: "Send exactly this amount"
    },
    message: {
      label: "Message"
    },
    isPrintBreakBeforeOrderDetails: true,
    instructions: {
      step1: "Log into your bank to initiate the payment",
      step2: "Make an international/SEPA payment",
      step3: "Enter payment details",
      step4:
        "BTCX will process your order as soon as we have received the payment. Typically, this process takes a few hours to one to two business days (Mon-Fri)"
    },
    paymentInfo: {
      step1: "Choose EURO as currency",
      step2: "If applicable choose split costs",
      step3: "Select earliest payment date as possible"
    },
    paymentDetails: {
      item1: {
        label: "Recipient",
        value: "Goobit AB"
      },
      item2: {
        label: "Adress",
        value: "KIVRA: 556911-9992"
      },
      item3: {
        label: "Postal code & city",
        value: "106 31 Stockholm"
      },
      item4: {
        label: "Country",
        value: "Sweden"
      }
    }
  },
  QuickPayCardEUR: {
    name: "QuickPayCardEUR",
    code: "QuickPayCardEUR",
    label: "Credit / Debit Card (EUR)",
    fiat: currencies.fiat.EUR,
    isPrintBreakBeforeOrderDetails: true
  },
  Zimpler: {
    name: "Instant Bank Transfer",
    code: "Zimpler",
    label: "Instant Bank Transfer",
    fiat: currencies.fiat.SEK,
    isPrintBreakBeforeOrderDetails: true
  },
  TinkSepaCredit: {
    name: PAYMENT_METHODS.TinkSepaCredit.name,
    code: PAYMENT_METHODS.TinkSepaCredit.code,
    fiat: currencies.fiat.SEK,
    isPrintBreakBeforeOrderDetails: false
  },
  FinsharkPayment: {
    name: PAYMENT_METHODS.FinsharkPayment.name,
    code: PAYMENT_METHODS.FinsharkPayment.code,
    fiat: currencies.fiat.SEK,
    isPrintBreakBeforeOrderDetails: false
  },
  FinsharkEUR: {
    name: PAYMENT_METHODS.FinsharkEUR.name,
    code: PAYMENT_METHODS.FinsharkEUR.code,
    fiat: currencies.fiat.EUR,
    isPrintBreakBeforeOrderDetails: false
  }
};

const {
  SEPA: { accountOwner, bank }
} = paymentMethodsConfig;

export const sepaTransferDetailsConfig = {
  account: {
    accountOwner: {
      label: "Account owner",
      value: accountOwner.name
    },
    address: {
      label: "Address",
      value: accountOwner.address
    },
    city: {
      label: "City",
      value: accountOwner.city
    },
    country: {
      label: "Country",
      value: accountOwner.country
    }
  },
  bank: {
    name: {
      label: "Bank name",
      value: bank.name
    },
    address: {
      label: "Bank address",
      value: bank.address
    },
    city: {
      label: "Bank city",
      value: bank.city
    },
    country: {
      label: "Bank country",
      value: bank.country
    }
  }
};

export const paymentMethods = Object.values(paymentMethodsConfig).map(
  el => el.name
);

import React from "react"; // eslint-disable-line
import PropTypes from "prop-types";
import { ACCESS_RIGHTS } from "./access.configuration";
import { hasSufficientAccessRights } from "./access.service";

const WithAccessRight = ({ acr, children, isReversed }) => {
  if (isReversed) {
    if (!hasSufficientAccessRights(acr)) {
      return children;
    }

    return null;
  }

  if (hasSufficientAccessRights(acr)) {
    return children;
  }

  return null;
};

WithAccessRight.propTypes = {
  acr: PropTypes.array.isRequired
};

export { ACCESS_RIGHTS, WithAccessRight };

import React from "react";
import { connect } from "react-redux";
import { localize } from "./localization.service";

const WithLocalizationHOC = Component => {
  const WithLocalization = props => {
    const { dispatch, ...rest } = props;

    return <Component {...rest} />;
  };

  const mapStateToProps = state => ({
    localize,
    locale: state.localization.locale
  });

  return connect(mapStateToProps, null)(WithLocalization);
};

export default WithLocalizationHOC;

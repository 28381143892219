let links = {};

export const linksConfig = {
  buyExpress: {
    en: "/express",
    sv: "/express",
    name: "Express Exchange",
    section: "Our Services"
  },
  signUp: {
    en: "/register",
    sv: "/registrera",
    name: "Standard Exchange",
    section: "Our Services"
  },
  otc: {
    en: "/otc",
    sv: "/otc",
    name: "OTC Exchange",
    section: "Our Services"
  },
  fraud: {
    en: "/fraud/",
    sv: "/fraud/",
    name: "About Frauds",
    section: "Read More"
  },
  risks: {
    en: "/risks/",
    sv: "/risks/",
    name: "About Risks",
    section: "Read More"
  },
  taxes: {
    en: "/taxes",
    sv: "/skatt",
    name: "About Taxes",
    section: "Read More"
  },
  terms: {
    en: "/terms",
    sv: "/villkor",
    name: "Terms of Service",
    section: "Customer Service"
  },
  privacyPolicy: {
    en: "/privacy-policy",
    sv: "/privacy-policy",
    name: "Privacy Policy",
    section: "Customer Service"
  },
  contactUs: {
    en: "/support",
    sv: "/support",
    name: "Contact Us",
    section: "Customer Service"
  },
  pep: {
    en: "/are-you-a-politically-exposed-person-pep",
    sv: "/ar-du-person-i-politiskt-utsatt-stallning-pep"
  },
  faqCreditCards: {
    en:
      "/support/faq/exchange/#0080-why-do-i-get-an-error-message-when-i-try-to-pay-an-order-using",
    sv:
      "/support/faq/vaxling/#0080-varfor-far-jag-felmeddelande-nar-jag-forsoker-gora-en"
  }
};

Object.keys(linksConfig).forEach(x => {
  links[x] = x;
});
export { links };

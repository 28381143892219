import {
  SET_USER,
  ADD_IMPORTANT_MESSAGE_TO_CLOSED_LIST,
  SET_PAGE_LIST_OF_IMPORTANT_MESSAGES,
  SET_GUIDE_DISPLAYED,
  MARK_KYC_QUESTIONNAIRE_AS_SUBMITTED,
  MARK_2FA_AS_ENABLED,
  MARK_2FA_AS_DISABLED,
  MARK_PHONE_AS_VERIFIED,
  REMOVE_BANK_ACCOUNTS
} from "./user.action.types";

const setUser = user => ({
  type: SET_USER,
  payload: user
});

const addMessageToClosedList = payload => ({
  type: ADD_IMPORTANT_MESSAGE_TO_CLOSED_LIST,
  payload
});

const setPageListOfImportantMessages = payload => ({
  type: SET_PAGE_LIST_OF_IMPORTANT_MESSAGES,
  payload
});

const setGuideDisplayed = () => ({
  type: SET_GUIDE_DISPLAYED
});

const markKYCquestionnaireAsSubmitted = () => ({
  type: MARK_KYC_QUESTIONNAIRE_AS_SUBMITTED
});

const mark2FAasEnabled = () => ({
  type: MARK_2FA_AS_ENABLED
});

const mark2FAasDisabled = () => ({
  type: MARK_2FA_AS_DISABLED
});

const markPhoneAsVerified = payload => ({
  type: MARK_PHONE_AS_VERIFIED,
  payload
});

const removeBankAccounts = () => ({
  type: REMOVE_BANK_ACCOUNTS
});

export {
  addMessageToClosedList,
  setPageListOfImportantMessages,
  setUser,
  setGuideDisplayed,
  markKYCquestionnaireAsSubmitted,
  mark2FAasEnabled,
  mark2FAasDisabled,
  markPhoneAsVerified,
  removeBankAccounts
};

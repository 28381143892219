import React from "react";
import NavigationLinks from "../navigation.links/NavigationLinks";
import Notifications from "../../../../features/notifications/Notifications";
import NavigationLogo from "../navigation.logo/NavigationLogo";
import AccountMenu from "../../../../features/account/AccountMenu";
import GuideStep from "../../../../components/guide.step/GuideStep";
import ThemeToggler from "./../../../../themes/ThemeToggler";
import styles from "./DesktopNavigation.module.scss";

const DesktopNavigation = () => (
  <div className={styles["desktop"]}>
    <div className={styles["desktop__main-menu"]}>
      <NavigationLogo />
      <NavigationLinks />
    </div>
    <ThemeToggler />
    <GuideStep step={5}>
      <Notifications />
    </GuideStep>
    <AccountMenu />
  </div>
);

export default DesktopNavigation;

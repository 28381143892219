import React from "react";
import { connect } from "react-redux";
import WithLocalization from "../../localization/WithLocalization";
import WithProfile from "../../components/HOC/WithProfile";
import theme from "./../../themes/base.palette";
import { verificationStatuses } from "../../constants";

const generateSettings = ({ token }) => ({
  webWidget: {
    authenticate: {
      chat: {
        jwtFn: function(callback) {
          callback(token);
        }
      }
    },
    color: {
      theme: theme.primary.main,
      launcher: theme.primary.main,
      launcherText: theme.primary.contrastText,
      button: theme.primary.main,
      buttonText: theme.primary.contrastText
    }
  }
});

class Support extends React.PureComponent {
  componentDidMount() {
    if (!process.env.REACT_APP_ZENDESK_WIDGET_KEY) {
      return console.logExternally("ZenDesk key is missing");
    }

    if (this.props.emailVerificationStatus !== verificationStatuses.VERIFIED) {
      return;
    }

    setTimeout(() => {
      window.zESettings = generateSettings({ token: this.props.token });
      this.insertScript(process.env.REACT_APP_ZENDESK_WIDGET_KEY);
    }, this.getLoadingDelay(process.env.REACT_APP_ZENDESK_WIDGET_LOAD_DELAY));
  }

  componentDidUpdate(prevProps) {
    if (window.zE && prevProps.locale !== this.props.locale) {
      window.zE("webWidget", "setLocale", this.props.locale);
    }

    if (
      prevProps.emailVerificationStatus !==
        this.props.emailVerificationStatus &&
      this.props.emailVerificationStatus === verificationStatuses.VERIFIED
    ) {
      window.zESettings = generateSettings({ token: this.props.token });
      this.insertScript(process.env.REACT_APP_ZENDESK_WIDGET_KEY);
    }
  }

  getLoadingDelay = value => {
    const delay = parseInt(value, 10);

    if (!Number.isNaN(delay) && delay > 0) {
      return delay;
    }

    return 0;
  };

  insertScript = zendeskKey => {
    const script = document.createElement("script");
    script.async = true;
    script.id = "ze-snippet";
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
    script.addEventListener("load", this.onScriptLoaded.bind(this));
    document.body.appendChild(script);
  };

  onScriptLoaded = () => {
    const {
      locale,
      profile: { email, firstName, lastName }
    } = this.props;

    try {
      window.zE(() => {
        window.zE("webWidget", "setLocale", locale);
        window.zE(
          "webWidget",
          "prefill",
          this.getPrefillData(email, firstName, lastName)
        );
      });
    } catch (error) {
      console.logExternally("zendesk loading error", error);
    }
  };

  getPrefillData = (email, firstName, lastName) => {
    const data = {};

    data.email = {
      value: email,
      readOnly: true
    };

    data.name = {
      value: firstName && lastName ? `${firstName} ${lastName}` : "Unknown",
      readOnly: true
    };

    return data;
  };

  render() {
    return null;
  }
}

const mapStateToProps = state => ({
  emailVerificationStatus: state.user.verifications.email,
  locale: state.localization.locale,
  token: state.user.zendeskJwt
});

export default WithLocalization(
  WithProfile(connect(mapStateToProps, null)(Support))
);

export const ROLES = {
  isVerifiedEmail: "NFxV3Pgw1XeFsxiz",
  isNotVerified: "Juf3zolKuTApue5q",
  isSwishEnabled: "i7NE0XmMzQMD4kxv",
  isVerifiedBankAccount: "K2M6vCTBhkZhUBNk",
  isVerified: "xpMjblT1v99wxZLV"
};

export const ACCESS_RIGHTS = {
  CAN_SEE_BASIC_APP: "CAN_SEE_BASIC_APP",
  CAN_SEE_FULL_APP: "CAN_SEE_FULL_APP",
  CAN_PLACE_BUY_ORDER: "CAN_PLACE_BUY_ORDER",
  CAN_PLACE_SELL_ORDER: "CAN_PLACE_SELL_ORDER",
  CAN_PLACE_SWISH_BUY_ORDER: "CAN_PLACE_SWISH_BUY_ORDER",
  CAN_SEE_ONLY_SWEDISH_USER: "CAN_SEE_ONLY_SWEDISH_USER",
  CAN_SEE_ONLY_BANKID_USER: "CAN_SEE_ONLY_BANKID_USER",
  CAN_SEE_DOCUMENTS: "CAN_SEE_DOCUMENTS"
};

import React from "react";
import Button from "../../components/button/Button";
import { logout } from "./../../services/user/user.service";

const AccountLogout = props => (
  <Button
    {...props}
    onClick={() => {
      logout();
    }}
  >
    Log out
  </Button>
);

export default AccountLogout;

import { addValidationRule } from "formsy-react";
import hasAllowedCharacters from "./formsy.extenders/has.allowed.characters";
import hasExtendedAllowedCharacters from "./formsy.extenders/has.extended.allowed.characters";
import isCountryCode from "./formsy.extenders/is.country.code";
import isDefined from "./formsy.extenders/is.defined";
import isInteger from "./formsy.extenders/is.integer";
import isLessThan from "./formsy.extenders/is.less.than";
import isMoreThan from "./formsy.extenders/is.more.than";
import isPersonalNumber from "./formsy.extenders/is.personal.number";
import isStringEmpty from "./formsy.extenders/is.string.empty";
import isValidBuyAmount from "./formsy.extenders/is.valid.buy.amount";
import isValidSellAmount from "./formsy.extenders/is.valid.sell.amount";
import isWalletAddress from "./formsy.extenders/wallet.address.validator";
import isValidPhoneNumber from "./formsy.extenders/is.valid.phone.number";
import isDecimalAmount from "./formsy.extenders/is.decimal.amount";
import isValidEmailAddress from "./formsy.extenders/is.valid.email.address";
import isValidKycInput from "./formsy.extenders/is.valid.kyc.input";
import isNumericOptional from "./formsy.extenders/is.numeric.optional";
import isValidIBAN from "./formsy.extenders/is.valid.iban";
import validations from "./validation.config";

export function init() {
  addValidationRule(validations.hasAllowedCharacters, hasAllowedCharacters);
  addValidationRule(
    validations.hasExtendedAllowedCharacters,
    hasExtendedAllowedCharacters
  );
  addValidationRule(validations.isCountryCode, isCountryCode);
  addValidationRule(validations.isDefined, isDefined);
  addValidationRule(validations.isInteger, isInteger);
  addValidationRule(validations.isLessThan, isLessThan);
  addValidationRule(validations.isMoreThan, isMoreThan);
  addValidationRule(validations.isPersonalNumber, isPersonalNumber);
  addValidationRule(validations.isStringEmpty, isStringEmpty);
  addValidationRule(validations.isValidBuyAmount, isValidBuyAmount);
  addValidationRule(validations.isValidSellAmount, isValidSellAmount);
  addValidationRule(validations.isWalletAddress, isWalletAddress);
  addValidationRule(validations.isValidPhoneNumber, isValidPhoneNumber);
  addValidationRule(validations.isDecimalAmount, isDecimalAmount);
  addValidationRule(validations.isValidEmailAddress, isValidEmailAddress);
  addValidationRule(validations.isValidKYCInput, isValidKycInput);
  addValidationRule(validations.isNumbericOptional, isNumericOptional);
  addValidationRule(validations.isValidIBAN, isValidIBAN);
}

import React from "react";
import Icon from "../../../components/icon/Icon";
import { socialMediaLinks } from "./footer.config";
import ExternalLink from "../../../components/external.link/ExternalLink";
import Translation from "../../../localization/Translation";
import styles from "./Footer.module.scss";

const FooterSocialNetworks = () => {
  return (
    <div className={styles["footer__social"]}>
      <h3 className={styles["footer__social-heading"]}>
        <Translation>Follow us on:</Translation>
      </h3>
      <div className={styles["footer__social-networks"]}>
        {Object.keys(socialMediaLinks).map(link => (
          <ExternalLink
            key={link}
            href={socialMediaLinks[link]}
            className={styles["footer__social-networks--" + link]}
          >
            <Icon symbol={link} size={14} />
          </ExternalLink>
        ))}
      </div>
    </div>
  );
};

export default FooterSocialNetworks;

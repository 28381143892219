import ExternalLink from "../../components/external.link/ExternalLink";
import PropTypes from "prop-types";
import React from "react";
import Translation from "../../localization/Translation";
import WithLocalization from "../../localization/WithLocalization";
import generatePrivateKeyEN from "../../../resources/documents/Getting_private_key_for_old_BTCX_wallets_EN.pdf";
import generatePrivateKeySV from "../../../resources/documents/Getting_private_key_for_old_BTCX_wallets_SV.pdf";
import styles from "./WalletExportContent.module.scss";

const generateKeyDocuments = {
  en: generatePrivateKeyEN,
  sv: generatePrivateKeySV
};

const WalletExportLink = ({ locale }) => (
  <ExternalLink
    href={generateKeyDocuments[locale]}
    className={styles["wallet-export-content__link"]}
  >
    <Translation>Getting private key for old BTCX wallets</Translation>
  </ExternalLink>
);

WalletExportLink.propTypes = {
  locale: PropTypes.string.isRequired
};

export default WithLocalization(WalletExportLink);

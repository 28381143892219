import React from "react";
import PropTypes from "prop-types";
import FooterHeading from "./FooterHeading";
import styles from "./Footer.module.scss";

const FooterColumn = ({ children, heading }) => (
  <div className={styles["footer__column"]}>
    <FooterHeading>{heading}</FooterHeading>
    <ul className={styles["footer__list"]}>{children}</ul>
  </div>
);

FooterColumn.propTypes = {
  heading: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired
};

export default FooterColumn;

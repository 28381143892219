export const iban = "GB59CLJU00997129900178";
export const swift = "CLJUGB21";
export const swedenCountryCode = "SE";

export const orderTypes = {
  BUY: "BUY",
  SELL: "SELL"
};

export const currencyTypes = {
  FIAT: "fiat",
  CRYPTO: "crypto"
};

export const orderStatuses = {
  CANCELED: {
    code: "CANCELED",
    name: "Canceled"
  },
  COMPLETED: {
    code: "COMPLETED",
    name: "Completed"
  },
  CONTACT_SUPPORT: {
    code: "CONTACT_SUPPORT",
    name: "Contact support"
  },
  ERROR: {
    code: "ERROR",
    name: "Error"
  },
  PAID: {
    code: "PAID",
    name: "Paid"
  },
  PAYOUT_PENDING: {
    code: "PAYOUT_PENDING",
    name: "Payout pending"
  },
  PENDING: {
    code: "PENDING",
    name: "Pending"
  },
  REFUND: {
    code: "REFUND",
    name: "Refund"
  },
  UNPAID: {
    code: "UNPAID",
    name: "Unpaid"
  }
};

export const verificationStatuses = {
  CONFIRMED: "CONFIRMED",
  PENDING: "PENDING",
  REJECTED: "REJECTED",
  UNVERIFIED: "UNVERIFIED",
  VERIFIED: "VERIFIED"
};

export const locales = {
  en: {
    name: "English",
    code: "en"
  },
  sv: {
    name: "Swedish",
    code: "sv"
  }
};

export const currencies = {
  crypto: {
    BTC: {
      code: "BTC",
      name: "Bitcoin",
      hasNetworkFee: true
    },
    ETH: {
      code: "ETH",
      name: "Ethereum",
      hasNetworkFee: true,
      paysFeeByDefault: true
    },
    LTC: {
      code: "LTC",
      name: "Litecoin",
      hasNetworkFee: false
    },
    BCH: {
      code: "BCH",
      name: "Bitcoin Cash",
      hasNetworkFee: false
    }
  },
  fiat: {
    SEK: {
      code: "SEK",
      name: "Swedish Krona"
    },
    EUR: {
      code: "EUR",
      name: "Euro"
    }
  }
};

export const priorities = {
  HIGH: "HIGH",
  LOW: "LOW"
};

export const dateFormats = {
  standard: "YYYY-MM-DD",
  extended: "YYYY-MM-DD HH:mm"
};

export const defaults = {
  crypto: currencies.crypto.BTC.code,
  dateFormat: dateFormats.standard,
  fiat: currencies.fiat.SEK.code,
  locale: locales.en.code,
  priority: priorities.LOW
};

export const features = {
  BANKID_CREDENTIALS_ACTIVE: "BANKID_CREDENTIALS_ACTIVE",
  BUY: "BUY",
  BUY_BTC: "BUY_BTC",
  BUY_ETH: "BUY_ETH",
  ORDER_TRANSACTIONS: "ORDER_TRANSACTIONS",
  QUICKPAY: "QUICKPAY",
  SELL: "SELL",
  SELL_BCH: "SELL_BCH",
  SELL_BTC: "SELL_BTC",
  SELL_ETH: "SELL_ETH",
  SELL_LTC: "SELL_LTC",
  SWISH_INTEGRATION: "SWISH_INTEGRATION",
  ZENDESK: "ZENDESK",
  TINK_PAYMENTS: "TINK_PAYMENTS",
  SELL_OTHER_BANK_ACCOUNT: "SELL_OTHER_BANK_ACCOUNT",
  GOCARDLESS_LIMIT_CHECK: "GOCARDLESS_LIMIT_CHECK",
  GOCARDLESS_REMAINED_FOR_MONTH: "GOCARDLESS_REMAINED_FOR_MONTH",
  ZIMPLER: "ZIMPLER"
};

export const paymentMethods = {
  BANKGIRO: {
    code: "BANKGIRO",
    name: "Bankgiro"
  },
  PAYEX: {
    code: "PAYEX",
    name: "Payex"
  },
  SWISH: {
    code: "SWISH",
    name: "Swish",
    feature: features.SWISH_INTEGRATION
  },
  StripeSwish: {
    code: "StripeSwish",
    name: "Swish",
    feature: features.SWISH_INTEGRATION
  },
  StripeCard: {
    code: "StripeCard",
    name: "Credit / Debit Card (SEK)"
  },
  SEPA: {
    code: "SEPA",
    name: "SEPA",
    label: "SEPA (Bank Transfer)"
  },
  QuickPayCardEUR: {
    code: "QuickPayCardEUR",
    name: "Credit / Debit Card (EUR)",
    feature: features.QUICKPAY
  },
  TinkSepaCredit: {
    code: "TinkSepaCredit",
    name: "Direct Payment (via Tink)",
    feature: features.TINK_PAYMENTS
  },
  FinsharkPayment: {
    code: "FinsharkPayment",
    name: "FINSHARK"
  },
  FinsharkEUR: {
    code: "FinsharkEUR",
    name: "FINSHARKEUR"
  },
  Zimpler: {
    code: "Zimpler",
    name: "Instant Bank Transfer",
    label: "Instant Bank Transfer",
    feature: features.ZIMPLER
  }
};

export const SWISH_ECOMMERCE = "SwishECommerce";

export const messageVariants = {
  success: "success",
  error: "error",
  info: "info"
};

export const verificationCodes = {
  EMAIL: "EMAIL",
  BANK_ACCOUNT: "BANK_ACCOUNT",
  PHONE_NUMBER: "PHONE_NUMBER",
  RESIDENCY: "RESIDENCY",
  PASSPORT: "PASSPORT",
  ID: "ID"
};

export const defaultDecimalPlaces = {
  [currencyTypes.CRYPTO]: 8,
  [currencyTypes.FIAT]: 2
};

export const defaultErrorMessage =
  "So sorry, it seems this action is not possible at the moment. Please do try again, but if the problem persists, have a word with our support. Have an otherwise great day.";

export const BTCX_PAGE_REFRESHED = "BTCX_PAGE_REFRESHED";

export const environments = {
  local: "local"
};

export const FINSHARK = {
  origin: "https://flows.finshark.io"
};
export const STRIPE = {
  origin: "https://checkout.stripe.com"
};
export const ZIMPLER = {
  origin: "https://checkout-cdn.zimpler.net"
};
export const ZIMPLER_SANDBOX = {
  origin: "https://checkout-sandbox.zimpler.net"
};

import ordersConfig from "../orders.config";
import { SWISH_ECOMMERCE, paymentMethods } from "../../../constants";
import moment from "moment";

function OrdersFilterModel(o) {
  this.currency = o && o.currency ? o.currency : null;
  this.dateRange = o && o.dateRange ? o.dateRange : null;
  this.number = o && o.number ? o.number : null;
  this.page = o && o.page ? o.page : 0;
  this.paymentMethod = o && o.paymentMethod ? o.paymentMethod : null;
  this.size = o && o.size ? o.size : ordersConfig.listOrdersDefaultSize;
  this.status = o && o.status ? o.status : null;
  this.type = o && o.type ? o.type : null;
}

OrdersFilterModel.prototype.getFrom = function() {
  if (!this.dateRange) {
    return null;
  }

  return this.dateRange.value.split(" - ")[0];
};

OrdersFilterModel.prototype.getTo = function() {
  if (!this.dateRange) {
    return null;
  }

  return this.dateRange.value.split(" - ")[1];
};

OrdersFilterModel.prototype.getActive = function() {
  return Object.keys(this)
    .filter(key => !!this[key])
    .filter(key => key !== "size" && key !== "page")
    .map(key => {
      return { [key]: this[key] };
    });
};

OrdersFilterModel.prototype.getParams = function() {
  const { dateRange } = this;
  const params = {};

  if (dateRange) {
    const fromDate = moment.utc(this.getFrom()).toISOString();
    const toDate = moment
      .utc(this.getTo())
      .endOf("day")
      .toISOString();

    params.from = fromDate;
    params.to = toDate;
  }

  Object.keys(this)
    .filter(key => key !== "dateRange")
    .forEach(key => {
      if (this[key]) {
        params[key] = this[key];
      }
    });

  if (params.paymentMethod) {
    params.method =
      params.paymentMethod === paymentMethods.SWISH.code
        ? SWISH_ECOMMERCE
        : params.paymentMethod;
    delete params.paymentMethod;
  }

  return params;
};

function getInitialParamValue(param) {
  const initial = new OrdersFilterModel();

  return initial[param];
}

function create(m) {
  return Object.freeze(new OrdersFilterModel(m));
}

export default { create, getInitialParamValue };

import basePalette from "../base.palette";

const lightThemePalette = Object.assign({}, basePalette);

lightThemePalette.pagePrimaryBgColor = lightThemePalette.secondary.contrastText;
lightThemePalette.fontPrimaryColor = lightThemePalette.secondary.main;
lightThemePalette.listItemHoverColor = lightThemePalette.grey.lighter;
lightThemePalette.inputBeforeBorderColor = lightThemePalette.grey.light;
lightThemePalette.inputAfterBorderColor = lightThemePalette.primary.main;
lightThemePalette.hoveredInputBeforeBorderColor =
  lightThemePalette.secondary.main;
lightThemePalette.inputRootColor = lightThemePalette.secondary.main;
lightThemePalette.inputPlaceholderColor = lightThemePalette.grey.main;
lightThemePalette.inputLabelFocusedColor = lightThemePalette.primary.main;
lightThemePalette.inputLabelErrorColor = lightThemePalette.error.main;
lightThemePalette.formLabelColor = lightThemePalette.grey.dark;
lightThemePalette.progressColor = lightThemePalette.primary.main;
lightThemePalette.expandIconColor = lightThemePalette.primary.main;
lightThemePalette.radioColor = lightThemePalette.grey.light;
lightThemePalette.cellColor = lightThemePalette.secondary.main;
lightThemePalette.stepIconBgColor = lightThemePalette.grey.contrastText;
lightThemePalette.stepIconColor = lightThemePalette.grey.dark;
lightThemePalette.stepLineColor = lightThemePalette.grey.light;

export default lightThemePalette;

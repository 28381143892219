import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "./../../../../../components/table.cell/TableCell";
import { currencies } from "../../../../../constants";
import TransactionDirection from "./TransactionDirection";
import TransactionDate from "./TransactionDate";
import FormattedAmount from "../../../../../components/formatted.amount/FormattedAmount";
import {
  getTransactionMethod,
  availableMessages
} from "./transactions.configuration";
import Translation from "../../../../../localization/Translation";
import styles from "./Transactions.module.scss";

const tableCellClass = styles["transactions-table__cell"];
const emptyTableCellClass = styles["transactions-table__cell--empty"];

const Transaction = ({ transaction: t }) => {
  const isValidMsg = availableMessages.includes(t.message);

  return (
    <TableRow className={styles["transactions-table__row"]}>
      <TableCell
        className={`${styles["transactions-table__cell"]} ${styles["transactions-table__cell--strong"]}`}
        data-title="In/Out"
      >
        <TransactionDirection direction={t.direction} />
      </TableCell>
      <TableCell
        className={t.paymentMethod ? tableCellClass : emptyTableCellClass}
        data-title="Payment method"
      >
        {getTransactionMethod(t.paymentMethod)}
      </TableCell>
      <TableCell
        className={isValidMsg ? tableCellClass : emptyTableCellClass}
        data-title={isValidMsg ? "Message" : null}
      >
        {isValidMsg ? <Translation>{t.message}</Translation> : null}
      </TableCell>
      <TableCell className={tableCellClass} data-title="Date">
        <TransactionDate date={t.date} />
      </TableCell>
      <TableCell className={tableCellClass} data-title="Amount">
        <FormattedAmount currency={t.currency} monospace>
          {t.amount}
        </FormattedAmount>
      </TableCell>
      <TableCell className={tableCellClass} data-title="Value">
        <FormattedAmount currency={currencies.fiat.SEK.code} monospace>
          {t.value}
        </FormattedAmount>
      </TableCell>
    </TableRow>
  );
};

export default Transaction;

import React from "react";
import Translation from "../../localization/Translation";
import Icon from "../../components/icon/Icon";
import { clipboardConfig } from "./copy.to.clipboard.config";
import { CopyToClipboard as ReactCopyToClipboard } from "react-copy-to-clipboard";
import styles from "./CopyToClipboard.module.scss";

const { copyMessages, cliboardDelayToHideNotify } = clipboardConfig;

class CopyToClipboard extends React.PureComponent {
  state = {
    notificationMsg: ""
  };

  handleResults = (text, isCopied) => {
    if (isCopied) {
      this.handleClipboardSuccess();
    } else {
      this.handleClipboardRejection();
    }
  };

  componentWillUnmount() {
    if (this.clipboardTimeoutInterval) {
      clearTimeout(this.clipboardTimeoutInterval);
    }
  }

  handleClipboardRejection = () => {
    this.setState({
      notificationMsg: copyMessages.noSupportClipboard
    });
    this.hideClipboardNotify();
  };

  handleClipboardSuccess = () => {
    this.setState({
      notificationMsg: copyMessages.successCopied
    });
    this.hideClipboardNotify();
  };

  hideClipboardNotify = () => {
    this.clipboardTimeoutInterval = setTimeout(() => {
      this.setState({ notificationMsg: "" });
    }, cliboardDelayToHideNotify);
  };

  render() {
    const { notificationMsg } = this.state;
    const { hasLabel, toCopy } = this.props;
    const isNotify = notificationMsg.length > 0;

    let contentClassName = styles["copy__content"];

    if (hasLabel) {
      contentClassName += ` ${styles["copy__content--label"]}`;
    }

    return (
      <div className={styles["copy"]}>
        {isNotify && (
          <div className={styles["copy__feedback"]}>
            <Icon symbol="check" size={12} />
            <Translation>{notificationMsg}</Translation>
          </div>
        )}
        <ReactCopyToClipboard text={toCopy} onCopy={this.handleResults}>
          <span className={contentClassName}>
            {hasLabel && <Translation>Copy</Translation>}
            <Icon symbol="copy" size={12} />
          </span>
        </ReactCopyToClipboard>
      </div>
    );
  }
}

CopyToClipboard.defaultProps = {
  hasLabel: true
};

export default CopyToClipboard;

import React from "react";
import PropTypes from "prop-types";
import Translation from "../../../localization/Translation";
import styles from "./Footer.module.scss";

const FooterHeding = ({ children }) => (
  <h3 className={styles["footer__heading"]}>
    <Translation>{children}</Translation>
  </h3>
);

FooterHeding.propTypes = {
  children: PropTypes.string.isRequired
};

export default FooterHeding;

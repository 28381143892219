export const orderFeedback = {
  pending: `BTCX hasn't received payment yet. It can take up to 2 business days depending on the payment method for the bank to confirm this transaction. We will notify you once that is done.`
};

export const exchangeRateNotice = `The exchange rate will be set at the time when we send cryptocurrency to you. For your exact amount of cryptocurrency, please check your receipt once the order is completed.`;

export const orderDetailsModalConfig = {
  maxWidth: "lg",
  hasCloseIcon: true
};

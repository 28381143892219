import { methods } from "./backend.methods";
import { getCsrfCookie } from "./backend.service";
import {
  API_ROUTE,
  DEFAULT_NUMBER_OF_REDIRECTS,
  MAX_REQUEST_INTERVAL,
  csrfToken
} from "./backend.config";

class CallConfiguration {
  constructor(obj) {
    this.method = obj.method || methods.GET;
    this.baseURL = API_ROUTE;
    this.timeout = MAX_REQUEST_INTERVAL;
    this.withCredentials = true;
    this.responseType = obj.responseType || "json";
    this.url =
      obj.url && typeof obj.url === "string"
        ? obj.url.replace(/\/\//g, "/")
        : "";

    const maxRedirects = parseInt(obj.maxRedirects, 10);

    this.maxRedirects =
      obj.maxRedirects !== undefined && !Number.isNaN(maxRedirects)
        ? maxRedirects
        : DEFAULT_NUMBER_OF_REDIRECTS;

    if (obj.isMultipart) {
      this.headers = this.headers || {};
      this.headers["Content-Type"] = "multipart/form-data";
    }

    if (obj.headers) {
      this.headers = this.headers || {};
      this.headers = Object.assign(this.headers, obj.headers);
    }

    if (obj.data) {
      this.data = obj.data;
    }

    if (methods.POST === this.method) {
      const csrfCookie = getCsrfCookie();
      this.headers = Object.assign({}, this.headers, {
        [csrfToken.headerName]: csrfCookie
      });
      if (
        this.headers["Content-Type"] !== "multipart/form-data" &&
        (this.data === undefined ||
          !this.data.hasOwnProperty(csrfToken.paramName))
      ) {
        this.data = Object.assign({}, this.data, {
          [csrfToken.paramName]: csrfCookie
        });
      }
    }

    if (obj.params) {
      this.params = obj.params;
    }
  }
}

function create(p) {
  return Object.seal(new CallConfiguration(p));
}

export default { create };

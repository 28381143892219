import moment from "moment";
import ordersFilterModelFactory from "./../filters/orders.filter.model.factory";
import { getOrdersWithUpdatedStatus } from "../orders.reducer";
import { orderTypes } from "../../../constants";
import {
  ADD_NEW_ORDER,
  LOAD_MODE_ORDERS,
  ORDERS_ACTION_TYPES,
  REMOVE_ORDERS_FILTER_BY_PROPERTY,
  RESET_ORDERS_FILTERS,
  SET_DATE_FILTER,
  SET_ORDERS_FILTER_BY_PROPERTY,
  SET_ORDER_STATUS
} from "./../orders.action.types";

const initialFilters = ordersFilterModelFactory.create();

export default (
  state = {
    items: [],
    isLoading: false,
    isError: false,
    isLoaded: false,
    totalPages: 1,
    appendOrders: false,
    areFilteredOrders: false,
    filters: initialFilters
  },
  action
) => {
  switch (action.type) {
    case ORDERS_ACTION_TYPES.FETCH_ORDERS_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case ORDERS_ACTION_TYPES.FETCH_ORDERS_SUCCESS:
      const items = state.appendOrders
        ? [...state.items, ...action.payload.items]
        : action.payload.items;

      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        isError: false,
        items,
        totalPages: action.payload.totalPages
      };

    case ORDERS_ACTION_TYPES.FETCH_ORDERS_FAILURE:
      return {
        ...state,
        isError: true,
        isLoading: false,
        isLoaded: true,
        totalPages: 0
      };

    case SET_ORDERS_FILTER_BY_PROPERTY:
      const filters = {
        ...ordersFilterModelFactory.create({
          ...state.filters,
          ...action.payload
        }),
        page: 0
      };

      return {
        ...state,
        filters,
        appendOrders: false,
        areFilteredOrders: true
      };

    case REMOVE_ORDERS_FILTER_BY_PROPERTY:
      const newFilters = ordersFilterModelFactory.create({
        ...state.filters,
        [action.payload]: ordersFilterModelFactory.getInitialParamValue(
          action.payload
        ),
        page: 0
      });

      return {
        ...state,
        filters: newFilters
      };

    case RESET_ORDERS_FILTERS:
      return {
        ...state,
        appendOrders: false,
        filters: initialFilters
      };

    case SET_ORDER_STATUS:
      return {
        ...state,
        items: getOrdersWithUpdatedStatus(state.items, action)
      };

    case SET_DATE_FILTER:
      return {
        ...state,
        areFilteredOrders: true,
        filters: ordersFilterModelFactory.create({
          ...state.filters,
          dateRange: {
            value: action.value,
            period: action.period
          }
        })
      };

    case ADD_NEW_ORDER:
      return {
        ...state,
        items: getFilteredItems([action.payload, ...state.items], state.filters)
      };

    case LOAD_MODE_ORDERS:
      return {
        ...state,
        appendOrders: true,
        filters: ordersFilterModelFactory.create({
          ...state.filters,
          page: state.filters.page + 1
        })
      };

    default:
      return state;
  }
};

function getFilteredItems(
  items,
  { number, currency, paymentMethod, status, type, dateRange }
) {
  return items.filter((order, pos, arr) => {
    const isUnique =
      arr.map(mapObj => mapObj.reference).indexOf(order.reference) === pos;
    const isBuy = orderTypes.BUY === order.type;
    const orderCurrency = isBuy ? order.to.currency : order.from.currency;
    const orderDate = order.orderDate;
    let isInDateRange = true;

    if (dateRange !== null && dateRange.value) {
      const [fromDate, toDate] = dateRange.value.split(" - ");
      isInDateRange =
        moment(orderDate).isAfter(moment(fromDate).startOf("day")) &&
        moment(orderDate).isBefore(moment(toDate).endOf("day"));
    }

    return (
      isUnique &&
      (order.number === number || number === null) &&
      (order.paymentMethod.toUpperCase() === paymentMethod ||
        paymentMethod === null) &&
      (order.status === status || status === null) &&
      (order.type === type || type === null) &&
      (orderCurrency === currency || currency === null) &&
      isInDateRange
    );
  });
}

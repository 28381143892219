class GlobalMessageModel {
  constructor(o) {
    this.message = o && o.message !== undefined ? o.message : "";
    this.variant =
      o &&
      o.variant &&
      Object.values(GlobalMessageModel.variants).includes(o.variant)
        ? o.variant
        : GlobalMessageModel.variants.info;
  }
}

GlobalMessageModel.variants = {
  success: "success",
  error: "error",
  info: "info"
};

function create(m) {
  return Object.freeze(new GlobalMessageModel(m));
}

export default { create };

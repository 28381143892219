import API from "../services/api/backend";

export const updateUserLanguage = locale =>
  API.backend({
    url: API.endpoints.userLanguage,
    method: API.methods.POST,
    data: {
      language: locale
    }
  });

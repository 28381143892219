import React, { Component } from "react";
import PropTypes from "prop-types";
import { withFormsy } from "formsy-react";
import TextField from "@material-ui/core/TextField";
import Translation from "../../localization/Translation";
import { localize } from "../../localization/localization.service";
import styles from "./Input.module.scss";

class Input extends Component {
  state = {
    isBlur: false
  };

  getValueFromEvent = event => event.currentTarget.value;

  valueChangeHandler = event => {
    const { setValue, onChange } = this.props;
    const currentValue = this.getValueFromEvent(event);

    setValue(currentValue);

    if (typeof onChange === "function") {
      onChange(currentValue, setValue);
    }
  };

  handleBlur = event => {
    const { onBlur, setValue, type } = this.props;
    let currentValue = this.getValueFromEvent(event);

    this.setState({ isBlur: true });

    if (this.props.isValidValue(currentValue)) {
      if (type !== "password" && typeof currentValue === "string") {
        currentValue = currentValue.trim();
      }

      setValue(currentValue, true);
    }

    if (onBlur && typeof onBlur === "function") {
      onBlur(currentValue, setValue);
    }
  };

  shouldShowErrorMessage = () => {
    const {
      required,
      isPristine,
      getValue,
      showErrorOnLoad,
      showErrorOnBlur,
      immediateRequiredValidation,
      displayError
    } = this.props;

    const { isBlur } = this.state;

    if (displayError !== undefined) {
      return displayError;
    }

    return (
      (!isPristine() && !showErrorOnBlur) ||
      (showErrorOnBlur && isBlur) ||
      (showErrorOnLoad && !!getValue()) ||
      (immediateRequiredValidation && required)
    );
  };

  renderErrorMessage = () => {
    const { errorClassName, getErrorMessage, needsTranslation } = this.props;
    const errorMessage = getErrorMessage();

    return (
      <span className={errorClassName}>
        {this.shouldShowErrorMessage() &&
          errorMessage &&
          (needsTranslation ? (
            <Translation>{errorMessage}</Translation>
          ) : (
            errorMessage
          ))}
      </span>
    );
  };

  render() {
    const {
      getValue,
      getErrorMessage,
      className,
      name,
      type,
      readOnly,
      autoFocus,
      required,
      placeholder,
      nativeInputProps,
      label,
      inputProps,
      margin,
      InputLabelProps,
      needsTranslation
    } = this.props;

    const elProps = {
      value: getValue() || "",
      error: !!this.shouldShowErrorMessage() && getErrorMessage() !== null,
      className,
      name,
      margin,
      type
    };

    if (required) {
      elProps.required = true;
    }

    if (readOnly) {
      elProps.disabled = true;
    }

    if (autoFocus) {
      elProps.autoFocus = true;
    }

    if (label) {
      elProps.label = needsTranslation ? (
        <Translation>{label}</Translation>
      ) : (
        label
      );
    }

    if (inputProps) {
      elProps.InputProps = inputProps;
    }

    if (placeholder) {
      elProps.placeholder = needsTranslation
        ? localize(placeholder)
        : placeholder;
    }

    if (nativeInputProps) {
      elProps.inputProps = nativeInputProps;
    }

    if (InputLabelProps) {
      elProps.InputLabelProps = InputLabelProps;
    }

    return (
      <TextField
        style={type === "hidden" ? { display: "none" } : {}}
        {...elProps}
        onBlur={this.handleBlur}
        onChange={this.valueChangeHandler}
        helperText={this.renderErrorMessage()}
      />
    );
  }
}

Input.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  autoFocus: PropTypes.bool,
  showErrorOnBlur: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  InputLabelProps: PropTypes.object,
  displayError: PropTypes.bool
};

Input.defaultProps = {
  type: "text",
  className: styles["input"],
  errorClassName: styles["input__error"],
  showErrorOnLoad: false,
  showErrorOnBlur: false,
  immediateRequiredValidation: false,
  margin: "none",
  needsTranslation: true
};

export default withFormsy(Input);
export { Input };

import ps from "js-cookie";
const ss = window.sessionStorage;

export const getSessionItem = key => ss.getItem(key);
export const removeSessionItem = key => ss.removeItem(key);
export const setSessionItem = (key, val) => ss.setItem(key, val);

export const getPersistedItem = key => ps.get(key);
export const removePersistedItem = (key, opt) => ps.remove(key, opt);
export const setPersistedItem = (key, val, opt) => ps.set(key, val, opt);

import React from "react";
import PropTypes from "prop-types";
import Translation from "../../localization/Translation";
import ExternalLink from "../external.link/ExternalLink";
import { linksConfig } from "../btcx.public.link/btcx.public.link.config";

const BtcxExternalLink = ({
  anchor,
  children,
  className,
  locale,
  params,
  to
}) => {
  const props = {
    href: `${process.env.REACT_APP_PUBLIC_APP_URL}${locale}${linksConfig[to][locale]}`
      .concat(constructParams(params))
      .concat(constructAnchor(anchor))
  };

  if (className) {
    props.className = className;
  }

  return (
    <ExternalLink {...props}>
      <Translation>{children}</Translation>
    </ExternalLink>
  );
};

BtcxExternalLink.propTypes = {
  anchor: PropTypes.string,
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  locale: PropTypes.string.isRequired,
  params: PropTypes.object,
  to: PropTypes.string.isRequired
};

function constructParams(params) {
  if (!params) {
    return "";
  }

  let paramsString = "";

  Object.keys(params).forEach(key => {
    paramsString += params[key] !== undefined ? `${key}=${params[key]}&` : "";
  });

  return paramsString.length ? `/?${paramsString}`.slice(0, -1) : "";
}

function constructAnchor(anchor) {
  return anchor ? `#${anchor}` : "";
}

export default BtcxExternalLink;

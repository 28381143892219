import store from "./../../store/store";
import { addActiveCall, removeActiveCall } from "./backend.actions";

let ACTIVE_CALLS = [];

export const registerActiveCall = activeCall => {
  ACTIVE_CALLS.push(activeCall);
  store.dispatch(addActiveCall(activeCall));
};

export const unregisterActiveCall = activeCall => {
  ACTIVE_CALLS = ACTIVE_CALLS.filter(item => item.url !== activeCall.url);
  store.dispatch(removeActiveCall(activeCall));
};

export const findActiveCallInRegistry = activeCall => {
  return ACTIVE_CALLS.find(
    call =>
      call.url === activeCall.url &&
      call.method === activeCall.method &&
      call.params === activeCall.params
  );
};

import React from "react";
import styles from "./BlankLayout.module.scss";
import { GlobalMessages } from "../../components/global.messages/GlobalMessages";

const BlankLayout = ({ children }) => (
  <>
    <GlobalMessages />
    <div className={styles["layout"]}>{children}</div>
  </>
);

export default BlankLayout;

const basePalette = {
  primary: {
    // main: "#ff9200", - To reverse to light theme uncomment and comment main used theme
    main: "#e6007e",
    dark: "#cb7502",
    light: "#e85598",
    contrastText: "#fff"
  },
  secondary: {
    main: "#444355",
    light: "#0063af",
    lighter: "#686775",
    dark: "#03205a",
    contrastText: "#fff"
  },
  error: {
    main: "#f25268",
    light: "#f25268",
    dark: "#f25268",
    contrastText: "#fff"
  },
  grey: {
    main: "#a9afbf",
    light: "rgba(213, 215, 222, .72)",
    lighter: "#f4f4f7",
    dark: "#9298a8",
    contrastText: "#ebebef"
  }
};

export default basePalette;

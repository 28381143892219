import { currencies, priorities } from "../../constants";

const { crypto } = currencies;

function OrderCheckModel(o) {
  this.fromAmount = o && o.amount ? o.amount : "";
  this.fromCurrency = o && o.tradePairFrom ? o.tradePairFrom : "";
  this.networkFee = o && o.networkFee ? o.networkFee : 0;
  this.orderDate = o && o.orderDate ? o.orderDate : "";
  this.orderNumber = o && o.orderNumber ? o.orderNumber : "";
  this.paymentMethod = o && o.paymentMethod ? o.paymentMethod : "";
  this.priority = getOrderPriority(o);
  this.qrCodeString = o && o.qrCodeString ? o.qrCodeString : "";
  this.reference = o && o.reference ? o.reference : "";
  this.toAmount =
    o && o.estimatedAmountWithLabel
      ? Number(
          o.estimatedAmountWithLabel.substring(
            0,
            o.estimatedAmountWithLabel.length - 4
          )
        )
      : "";
  this.toCurrency = o && o.currency ? o.currency : "";
  this.walletAddress = o && o.cryptoAddress ? o.cryptoAddress : "";
  this.status = o && o.status ? o.status : "";
  this.feedbackDetails = o && o.feedbackDetails ? o.feedbackDetails : "";
  this.feedbackReason = o && o.feedbackReason ? o.feedbackReason : "";
}

function getOrderPriority(o) {
  if (o && o.to && o.to.currency === crypto.ETH.code) {
    return priorities.HIGH;
  }
  if (o && o.priority) {
    return o.priority;
  }

  return "";
}

function create(m) {
  return Object.freeze(new OrderCheckModel(m));
}

export default { create };

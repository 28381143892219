import {
  ADD_IMPORTANT_MESSAGE_TO_CLOSED_LIST,
  SET_PAGE_LIST_OF_IMPORTANT_MESSAGES,
  SET_GUIDE_DISPLAYED,
  SET_USER,
  MARK_KYC_QUESTIONNAIRE_AS_SUBMITTED,
  MARK_2FA_AS_ENABLED,
  MARK_2FA_AS_DISABLED,
  MARK_PHONE_AS_VERIFIED,
  REMOVE_BANK_ACCOUNTS
} from "./user.action.types";
import { SET_ALL_NOTIFICATIONS_AS_PROCESSED } from "../../features/notifications/notifications.action.types";
import { VERIFICATION_KYC } from "../../features/new.kyc.questions/new.kyc.questions.constants";
import { verificationStatuses } from "../../constants";

const userReducer = (state = null, action) => {
  const { type } = action;

  if (type === SET_USER) {
    return action.payload;
  }

  if (type === ADD_IMPORTANT_MESSAGE_TO_CLOSED_LIST) {
    return {
      ...state,
      importantMessages: {
        ...state.importantMessages,
        closedList: [...state.importantMessages.closedList, action.payload]
      }
    };
  }

  if (type === SET_PAGE_LIST_OF_IMPORTANT_MESSAGES) {
    return {
      ...state,
      importantMessages: {
        ...state.importantMessages,
        pageList: action.payload
      }
    };
  }

  if (type === SET_GUIDE_DISPLAYED) {
    return {
      ...state,
      details: {
        ...state.details,
        guideDisplayed: false
      }
    };
  }

  if (type === MARK_2FA_AS_ENABLED) {
    return {
      ...state,
      details: {
        ...state.details,
        enabled2FA: true
      }
    };
  }

  if (type === MARK_2FA_AS_DISABLED) {
    return {
      ...state,
      details: {
        ...state.details,
        enabled2FA: false
      }
    };
  }

  if (type === SET_ALL_NOTIFICATIONS_AS_PROCESSED) {
    return {
      ...state,
      notifications: state.notifications.map(notification => {
        notification.processed = true;

        return notification;
      })
    };
  }

  if (type === MARK_KYC_QUESTIONNAIRE_AS_SUBMITTED) {
    return {
      ...state,
      questionnaires: state.questionnaires.map(questionnaire => {
        if (questionnaire.key === VERIFICATION_KYC) {
          questionnaire.submitted = true;
          questionnaire.status = verificationStatuses.UNVERIFIED;
        }

        return questionnaire;
      })
    };
  }

  if (type === MARK_PHONE_AS_VERIFIED) {
    return {
      ...state,
      verifications: {
        ...state.verifications,
        phone: verificationStatuses.VERIFIED
      }
    };
  }

  if (type === REMOVE_BANK_ACCOUNTS) {
    return {
      ...state,
      bankAccounts: {
        list: []
      }
    };
  }

  return state;
};

export default userReducer;

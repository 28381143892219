import React from "react";
import PropTypes from "prop-types";
import Translation from "../../localization/Translation";
import styles from "./PersonalInformation.module.scss";

const PersonalInformationItem = ({ label, value }) => (
  <div className={styles["personal-information__item"]}>
    <span className={styles["personal-information__label"]}>
      <Translation>{label}</Translation>
    </span>
    <span className={styles["personal-information__value"]}>{value}</span>
  </div>
);

PersonalInformationItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

export default PersonalInformationItem;

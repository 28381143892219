import {
  defaults,
  orderTypes,
  paymentMethods,
  priorities,
  SWISH_ECOMMERCE,
  currencies
} from "../../constants";
import transactionsModelFactory from "./actions/details/order.details.transactions/transactions.model.factory";

class OrderModel {
  constructor(o) {
    if (!o) {
      throw new Error("No constructor object provided to OrderModel factory");
    }
    this.from = {
      currency: o.tradePairFrom ? o.tradePairFrom : "",
      amount: o.amount ? o.amount : null
    };
    this.to = {
      currency: o.coin ? o.coin : "",
      amount: o.estimatedAmountWithLabel
        ? getEstimatedAmount(o.estimatedAmountWithLabel)
        : null
    };
    this.type = o.type ? o.type : "";
    this.tinkRedirectURL = o.tinkRedirectURL ? o.tinkRedirectURL : "";
    this.payExRedirectURL = o.payExRedirectURL ? o.payExRedirectURL : "";
    this.finsharkRedirectURL =
      o && o.finsharkRedirectURL ? o.finsharkRedirectURL : "";
    this.swishRedirectURL = o && o.swishRedirectURL ? o.swishRedirectURL : "";
    this.zimplerOpenBankingRedirectURL =
      o && o.zimplerOpenBankingRedirectURL
        ? o.zimplerOpenBankingRedirectURL
        : "";
    this.qrCodeString = o.qrCodeString ? o.qrCodeString : "";
    this.walletAddress = o.cryptoAddress ? o.cryptoAddress : "";
    this.reference = o.reference ? o.reference : null;
    this.date = this.orderDate = o.orderDate ? o.orderDate : null;
    this.number = o.orderNumber ? o.orderNumber : "";
    this.paymentMethod = getPaymentMethod(o);
    this.rate = o.rate ? o.rate : null;
    this.status = o.status ? o.status : "";
    this.transactions = o.transactions
      ? o.transactions.map(t => transactionsModelFactory.create(t))
      : [];
    this.priority =
      o.coin === defaults.crypto
        ? o.networkFee
          ? priorities.HIGH
          : priorities.LOW
        : null;
    this.networkFee = o.networkFee ? o.networkFee : "";
    this.quickpayFormData = o.quickpayFormData ? o.quickpayFormData : null;
    this.bankAccountReference =
      o && o.bankAccountReference ? o.bankAccountReference : "";
    this.providerName = o && o.providerName ? o.providerName : "";
    this.feedbackDetails = o && o.feedbackDetails ? o.feedbackDetails : "";
    this.feedbackReason = o && o.feedbackReason ? o.feedbackReason : "";
  }
}

OrderModel.prototype.getRepeatBuyOrderData = function() {
  return {
    activeCoin: this.getCryptoCurrency(),
    activeFiat: this.getFiatCurrency(),
    amount: this.getFiatAmount(),
    walletAddress: this.walletAddress,
    paymentMethod: this.paymentMethod,
    priority:
      this.getCryptoCurrency() === currencies.crypto.ETH.code
        ? priorities.HIGH
        : this.priority
  };
};

OrderModel.prototype.getRepeatSellOrderData = function() {
  return {
    activeCoin: this.getCryptoCurrency(),
    activeFiat: this.getFiatCurrency(),
    amount: String(this.from.amount),
    bankAccountReference: this.bankAccountReference
  };
};

OrderModel.prototype.isBuy = function() {
  return this.type === orderTypes.BUY;
};

OrderModel.prototype.getFiatCurrency = function() {
  if (this.isBuy()) {
    return this.from.currency;
  }
  return this.to.currency;
};

OrderModel.prototype.getFiatAmount = function() {
  if (this.isBuy()) {
    return String(this.from.amount);
  }

  return String(this.to.amount);
};

OrderModel.prototype.getCryptoCurrency = function() {
  if (this.isBuy()) {
    return this.to.currency;
  }

  return this.from.currency;
};

function getEstimatedAmount(estimatedAmountWithLabel) {
  return parseFloat(estimatedAmountWithLabel.split(" ")[0]);
}

function getPaymentMethod(o) {
  const paymentMethod = o.paymentMethod
    ? o.paymentMethod
    : o.paymentMethodBaseName
    ? o.paymentMethodBaseName.capitalizeFirstLetter()
    : "";

  switch (paymentMethod) {
    case SWISH_ECOMMERCE:
      return paymentMethods.SWISH.code;

    case paymentMethods.StripeSwish.code.capitalizeFirstLetter():
      return paymentMethods.StripeSwish.code;

    case paymentMethods.StripeCard.code.capitalizeFirstLetter():
      return paymentMethods.StripeCard.code;

    case paymentMethods.QuickPayCardEUR.code.capitalizeFirstLetter():
      return paymentMethods.QuickPayCardEUR.code;

    case paymentMethods.TinkSepaCredit.code.capitalizeFirstLetter():
      return paymentMethods.TinkSepaCredit.code;

    case paymentMethods.FinsharkPayment.code.capitalizeFirstLetter():
      return paymentMethods.FinsharkPayment.code;

    default:
      return paymentMethod;
  }
}

function create(m) {
  return Object.seal(new OrderModel(m));
}

export default { create };

import React from "react";
import Icon from "../../../../../components/icon/Icon";
import styles from "./Transactions.module.scss";
import Translation from "../../../../../localization/Translation";

const TransactionDirection = ({ direction }) => {
  return (
    <div
      className={styles[`transactions-table__cell--${direction.toLowerCase()}`]}
    >
      <Icon symbol="arrow-right" size={14} className="arrow-down" />
      <Translation>{direction}</Translation>
    </div>
  );
};

export default TransactionDirection;

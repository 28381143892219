import React from "react";
import PropTypes from "prop-types";
import styles from "./WalletAddress.module.scss";

const WalletAddress = ({ address }) => (
  <div className={styles["address"]}>
    {address.split("").map((char, key) => (
      <span className={styles["address__char"]} key={key}>
        {char}
      </span>
    ))}
  </div>
);

WalletAddress.propTypes = {
  address: PropTypes.string.isRequired
};

export default WalletAddress;

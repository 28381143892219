import { localize } from "./../../localization/localization.service";

export const getGuideOptions = () => ({
  steps: [
    {
      intro: localize("Welcome to Standard BTCX")
    },
    {
      element: document.querySelector(".guide-2"),
      intro: localize("Here you can setup your account and change the settings")
    },
    {
      element: document.querySelector(".guide-3"),
      intro: localize("Click here to buy and sell crypto currency")
    },
    {
      element: document.querySelector(".guide-4"),
      intro: localize("View and manage your orders")
    },
    {
      element: document.querySelector(".guide-5"),
      intro: localize("Important notifications can be seen here")
    }
  ],
  nextLabel: localize("Next"),
  prevLabel: localize("Back"),
  skipLabel: localize("Skip"),
  doneLabel: localize("Done"),
  hidePrev: true,
  hideNext: true,
  exitOnEsc: true,
  exitOnOverlayClick: false,
  showStepNumbers: false,
  keyboardNavigation: false,
  showButtons: true,
  showProgress: false,
  scrollToElement: true,
  disableInteraction: true,
  overlayOpacity: 0.6
});

import React from "react";
import Translation from "../../../../localization/Translation";
import styles from "./OrderDetails.module.scss";
import {
  BtcxPublicLink,
  links
} from "../../../../components/btcx.public.link/BtcxPublicLink";

const OrderDetailsModalContentError = () => (
  <div className={styles["order-details__error"]}>
    <div className={styles["order-details__error-heading"]}>
      <Translation>Order details error</Translation>
    </div>
    <div
      className={`${styles["order-details__error-content"]} ${styles["order-details__main"]}`}
    >
      <p>
        <Translation>Error while fetching order details.</Translation>
      </p>
      <p>
        <Translation>Please try again or contact</Translation>{" "}
        <BtcxPublicLink
          to={links.faq}
          className={styles["order-details__error-link"]}
        >
          support
        </BtcxPublicLink>
        .
      </p>
    </div>
  </div>
);

export default OrderDetailsModalContentError;

export const MOBILE_BREAKPOINT = 768;
export const RESIZE_DEBOUNCE_INTERVAL = 10;
export const mobileUserAgents = [
  "Android",
  "webOS",
  "iPhone",
  "iPad",
  "iPod",
  "BlackBerry",
  "Windows Phone"
];

import React from "react";
import styles from "../OrderDetails.module.scss";

const TransactionDate = ({ date }) => {
  if (!date) {
    return null;
  }

  const dateParts = date.split(" ");

  return (
    <div className={styles["order-details__transaction-date"]}>
      <span>{dateParts[0]}</span>&nbsp;
      <span>{dateParts[1]}</span>
    </div>
  );
};

export default TransactionDate;

import Footer from "./footer/Footer";
import { GlobalMessages } from "./../../components/global.messages/GlobalMessages";
import Guide from "./../../components/guide/Guide";
import Header from "./header/Header";
import ImportantMessages from "../../features/important.messages/ImportantMessages";
import PropTypes from "prop-types";
import React from "react";
import StandardLayoutModal from "./StandardLayoutModal";
import styles from "./StandardLayout.module.scss";

const StandardLayout = ({ children }) => (
  <Guide>
    <div className={styles["layout"]}>
      <ImportantMessages />
      <GlobalMessages />
      <Header />
      <main className={styles["layout__main"]}>{children}</main>
      <Footer />
      <StandardLayoutModal />
    </div>
  </Guide>
);

StandardLayout.propTypes = {
  children: PropTypes.element.isRequired
};

export default StandardLayout;

/* eslint-disable no-extend-native */
export function init() {
  if (!String.prototype.capitalizeFirstLetter) {
    String.prototype.capitalizeFirstLetter = function() {
      return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
    };
  }

  if (!String.prototype.endsWith) {
    String.prototype.endsWith = function(search, this_len) {
      if (this_len === undefined || this_len > this.length) {
        this_len = this.length;
      }
      return this.substring(this_len - search.length, this_len) === search;
    };
  }

  if (!String.prototype.toSentenceCase) {
    String.prototype.toSentenceCase = function() {
      return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
    };
  }

  if (!Number.prototype.formatFloat) {
    Number.prototype.formatFloat = function(n, x, s, c) {
      let re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\D" : "$") + ")",
        num = this.toFixed(Math.max(0, ~~n));

      return (c ? num.replace(".", c) : num).replace(
        new RegExp(re, "g"),
        "$&" + (s || ",")
      );
    };
  }
}

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import MenuList from "@material-ui/core/MenuList";
import AccountMenuItem from "./AccountMenuItem";
import { paths } from "../../routing/paths";
import RedirectButton from "../../components/redirect.button/RedirectButton";
import SupportLink from "../../components/support.link/SupportLink";
import { WithAccessRight, ACCESS_RIGHTS } from "../../access/WithAccessRight";
import AccountLogout from "./AccountLogout";
import WalletLink from "./WalletLink";
import { withRouter } from "react-router-dom";
import styles from "./AccountMenu.module.scss";

const {
  personalProfile,
  changeEmail,
  changePassword,
  bank,
  phone,
  documents
} = paths;

const AccountMenuItems = ({
  handleCloseMenu,
  onCloseModal,
  location: { pathname }
}) => {
  const isActiveProfile = useMemo(() => {
    return [bank, phone, documents, personalProfile].includes(pathname);
  }, [pathname]);

  const isActiveSettings = useMemo(() => {
    return [changeEmail, changePassword].includes(pathname);
  }, [pathname]);

  return (
    <MenuList className={styles["account-menu"]}>
      <WithAccessRight acr={[ACCESS_RIGHTS.CAN_SEE_FULL_APP]}>
        <AccountMenuItem
          handleCloseMenu={handleCloseMenu}
          isActive={isActiveProfile}
        >
          <RedirectButton to={personalProfile}>Profile</RedirectButton>
        </AccountMenuItem>
      </WithAccessRight>
      <AccountMenuItem
        handleCloseMenu={handleCloseMenu}
        isActive={isActiveSettings}
      >
        <RedirectButton to={changeEmail}>Settings</RedirectButton>
      </AccountMenuItem>
      <AccountMenuItem handleCloseMenu={handleCloseMenu}>
        <SupportLink>Contact support</SupportLink>
      </AccountMenuItem>
      <WalletLink
        onCloseModal={onCloseModal}
        handleCloseMenu={handleCloseMenu}
      />
      <AccountMenuItem handleCloseMenu={handleCloseMenu}>
        <AccountLogout />
      </AccountMenuItem>
    </MenuList>
  );
};

AccountMenuItems.propTypes = {
  handleCloseMenu: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired
};

export default withRouter(AccountMenuItems);

import React from "react";
import Image from "../../../../components/image/Image";
import styles from "./PaymentInstruction.module.scss";

const PaymentImage = ({ src, alt }) => {
  return (
    <>
      <div className={styles["payment-instruction-image-wrapper"]}>
        <Image src={src} alt={alt} />
      </div>
    </>
  );
};

export default PaymentImage;

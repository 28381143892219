const API_ROUTE = process.env.REACT_APP_API_ROUTE;
const DEFAULT_NUMBER_OF_REDIRECTS = 5;
const MAX_REQUEST_INTERVAL = 120 * 1000;
const ERROR = "ERROR";
const API_RESPONSE_STATUS = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR"
};
const csrfToken = {
  headerName: "X-Csrf-Token",
  cookieName: "X-Csrf-Token",
  paramName: "_csrf"
};

export {
  ERROR,
  API_ROUTE,
  API_RESPONSE_STATUS,
  DEFAULT_NUMBER_OF_REDIRECTS,
  MAX_REQUEST_INTERVAL,
  csrfToken
};

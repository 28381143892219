import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getImportantMessages,
  getPageImportantMessages
} from "./important.messages.service";
import { setPageListOfImportantMessages } from "../../services/user/user.actions";
import ImportantMessage from "./ImportantMessage";
import equals from "ramda/es/equals";
import styles from "./ImportantMessages.module.scss";

class ImportantMessages extends Component {
  componentDidUpdate(prevProps) {
    const {
      importantMessages: { list, closedList }
    } = this.props;

    const pathname = this.getPathname();
    const isList = list && Array.isArray(list) && list.length;

    if (
      (isList && prevProps.history.location.pathname !== pathname) ||
      !equals(list, prevProps.importantMessages.list) ||
      !equals(closedList, prevProps.importantMessages.closedList)
    ) {
      this.onSetPageList(list, pathname, closedList);
    }
  }

  componentDidMount() {
    const {
      importantMessages: { list, closedList }
    } = this.props;

    this.onSetPageList(list, this.getPathname(), closedList);
  }

  onSetPageList = (list, pathname, closedList) => {
    this.props.setPageListOfImportantMessages(
      this.getPageList(list, pathname, closedList)
    );
  };

  getPathname = () => {
    return this.props.history.location.pathname;
  };

  getPageList = (list, pathname, closedList) => {
    return getPageImportantMessages(list, pathname).filter(
      message => !closedList.includes(message)
    );
  };

  render() {
    const {
      importantMessages: { pageList }
    } = this.props;
    let listOfMessages = getImportantMessages(pageList, this.getPathname());

    if (!listOfMessages.length) {
      return null;
    }

    const ActiveMessage = listOfMessages[0];

    return (
      <div className={styles["important-messages"]}>
        <ImportantMessage message={pageList[0]}>
          <ActiveMessage />
        </ImportantMessage>
      </div>
    );
  }
}

ImportantMessages.propTypes = {
  importantMessages: PropTypes.shape({
    list: PropTypes.array.isRequired,
    pageList: PropTypes.array.isRequired,
    closedList: PropTypes.array.isRequired
  }),
  setPageListOfImportantMessages: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  importantMessages: state.user.importantMessages
});

const mapDispatchToProps = {
  setPageListOfImportantMessages
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ImportantMessages)
);

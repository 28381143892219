import validations from "../../services/validation/validation.config";
import {
  getValidationErrorMessage,
  getRequiredValueValidationMsg
} from "../../services/validation/validation.messages.service";

const emailValidationError = getValidationErrorMessage("Email");

export default {
  label: "Email",
  required: true,
  validations: `${validations.isValidEmailAddress},${[
    validations.isStringEmpty
  ]},maxLength:50`,
  validationErrors: {
    [validations.isStringEmpty]: emailValidationError,
    maxLength: emailValidationError,
    [validations.isValidEmailAddress]: emailValidationError,
    isDefaultRequiredValue: getRequiredValueValidationMsg("Email")
  }
};

import React from "react";
import PropTypes from "prop-types";

const Icon = ({ symbol, size }) => (
  <i className={`icon-${symbol}`} style={{ fontSize: size }} />
);

Icon.propTypes = {
  symbol: PropTypes.string.isRequired,
  size: PropTypes.number
};

Icon.defaultProps = {
  size: 16
};

export default Icon;

import React from "react";
import PropTypes from "prop-types";
import CopyToClipboard from "../../../../components/copy.to.clipboard/CopyToClipboard";
import Translation from "../../../../localization/Translation";
import styles from "./PaymentInstruction.module.scss";

const PaymentInstruction = ({
  label,
  toCopy,
  additionalLabel,
  customClass,
  children
}) => (
  <div
    className={
      customClass
        ? `${styles["payment-instruction"]} ${styles["payment-instruction--offset-top"]}`
        : styles["payment-instruction"]
    }
  >
    <div className={styles["payment-instruction__label"]}>
      <Translation>{label}</Translation>
    </div>
    <div className={styles["payment-instruction__value"]}>
      {children}
      <CopyToClipboard toCopy={toCopy} hasLabel={false} />
    </div>
    {additionalLabel && (
      <div className={styles["payment-instruction__additional-label"]}>
        <Translation>{additionalLabel}</Translation>
      </div>
    )}
  </div>
);

PaymentInstruction.propTypes = {
  additionalLabel: PropTypes.string,
  label: PropTypes.string.isRequired,
  toCopy: PropTypes.string.isRequired
};

export default PaymentInstruction;

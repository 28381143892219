import { isBoolean } from "util";
import bankAccountModelFactory from "./profile.bank.account.model.factory";
import countries from "./countries.json";

function ProfileModel(m) {
  this.address = m && m.address ? m.address : "";
  this.bankAccounts =
    m &&
    m.bankAccounts &&
    Array.isArray(m.bankAccounts) &&
    m.bankAccounts.length
      ? m.bankAccounts.map(a => bankAccountModelFactory.create(a))
      : [bankAccountModelFactory.create()];
  this.city = m && m.city ? m.city : "";
  this.countryCode = getCountryCode(m);
  this.email = m && m.email ? m.email : "";
  this.firstName = m && m.firstName ? m.firstName : "";
  this.lastName = m && m.lastName ? m.lastName : "";
  this.pep = m && isBoolean(m.pep) ? m.pep : false;
  this.isNotPep = !this.pep;
  this.personalNumber = m && m.personalNumber ? m.personalNumber : "";
  this.phoneNumber = m && m.phoneNumber ? m.phoneNumber : "";
  this.postalCode = m && m.postalCode ? m.postalCode : "";
  this.totalAmount = m && m.totalAmount ? m.totalAmount : 0;
  this.passportNumber = m && m.passportNumber ? m.passportNumber : "";
}

function getCountryCode(m) {
  let countryCode = "";

  if (m) {
    const countryCodes = countries.map(el => el.code);
    if (m.hasOwnProperty("country") && countryCodes.includes(m.country)) {
      countryCode = m.country;
    }
    if (
      m.hasOwnProperty("countryCode") &&
      countryCodes.includes(m.countryCode)
    ) {
      countryCode = m.countryCode;
    }
  }

  return countryCode;
}

function create(m) {
  return Object.freeze(new ProfileModel(m));
}

export default { create };

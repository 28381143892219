import PropTypes from "prop-types";
import React from "react";
import BuyOrderDetailsModalContent from "./BuyOrderDetailsModalContent";
import SellOrderDetailsModalContent from "./SellOrderDetailsModalContent";
import Loader from "./../../../../components/loader/Loader";
import { getOrder } from "./../../orders.service";
import { orderTypes } from "../../../../constants";
import OrderDetailsModalContentError from "./OrderDetailsModalContentError";
import styles from "./OrderDetails.module.scss";

class OrderDetailsModalContent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      order: null
    };
  }

  componentDidMount() {
    getOrder(this.props.order.reference)
      .then(order => {
        this.setState({ order, isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  getOrderDetailsRef = dom => {
    this.orderDetailsRef = dom;
  };

  render() {
    const { order, isLoading } = this.state;
    const isOrder = order !== null;

    return (
      <div className={styles["order-details"]} ref={this.getOrderDetailsRef}>
        {isLoading ? (
          <Loader />
        ) : isOrder ? (
          order.type === orderTypes.BUY ? (
            <BuyOrderDetailsModalContent
              order={order}
              orderDetailsRef={this.orderDetailsRef}
            />
          ) : (
            <SellOrderDetailsModalContent
              order={order}
              orderDetailsRef={this.orderDetailsRef}
            />
          )
        ) : (
          <OrderDetailsModalContentError />
        )}
      </div>
    );
  }
}

OrderDetailsModalContent.propTypes = {
  order: PropTypes.object.isRequired,
  onOpenModal: PropTypes.func,
  onCloseModal: PropTypes.func
};

export default OrderDetailsModalContent;

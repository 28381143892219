import AppRoute from "./AppRoute";
import PropTypes from "prop-types";
import React, { Component } from "react";
import equals from "ramda/es/equals";
import { Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getRoutingRegistry } from "./routing.registry";
import { tryRefreshUserData } from "../services/user/user.service";
import { paths } from "./paths";
import { ACCESS_RIGHTS } from "./../access/access.configuration";
import { VERIFICATION_KYC } from "../../app/features/new.kyc.questions/new.kyc.questions.constants";
import { orderStatuses, verificationStatuses } from "../constants";

class AppRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routes: getRoutingRegistry().getRoutes()
    };

    this.tryToChangePage();
  }

  tryToChangePage = () => {
    const {
      history,
      access,
      location,
      passwordChangeRequired,
      shouldShowKYCquestionnaire,
      hasTemporaryEmail
    } = this.props;

    const isGocardlessProfile =
      location.pathname === paths.bank && location.search.includes("ref=");

    const orderStatusPattern = new RegExp(
      "/protected/order/[^/]+/(" + Object.keys(orderStatuses).join("|") + ")$"
    );
    const isPaymentConfirmationScreen = orderStatusPattern.test(
      location.pathname
    );

    if (isGocardlessProfile) {
      history.push(`${paths.bank}${location.search}`);
      return;
    }
    if (!isPaymentConfirmationScreen) {
      if (hasTemporaryEmail) {
        history.push(paths.changeEmail);
        return;
      }

      if (shouldShowKYCquestionnaire) {
        history.replace(paths.secondVerification);
        return;
      }

      if (
        passwordChangeRequired &&
        access.includes(ACCESS_RIGHTS.CAN_SEE_FULL_APP) &&
        location.pathname !== paths.changePassword &&
        location.pathname !== paths.verification
      ) {
        history.replace(paths.changePassword);
      }
    }
  };

  componentDidMount() {
    this.changeRouteSubscriber = this.props.history.listen(async () => {
      await tryRefreshUserData();
    });
  }

  componentDidUpdate(prevProps) {
    const { access } = this.props;

    if (!equals(access, prevProps.access)) {
      this.setState({ routes: getRoutingRegistry().getRoutes() });
    }
  }

  componentWillUnmount() {
    this.changeRouteSubscriber();
  }

  render() {
    const { routes } = this.state;

    return (
      <Switch>
        {routes.map(({ component, exact, layout, name, path }) => (
          <AppRoute
            component={component}
            exact={exact}
            key={name}
            layout={layout}
            path={path}
          />
        ))}
      </Switch>
    );
  }
}

AppRoutes.propTypes = {
  access: PropTypes.array.isRequired,
  isBankidUser: PropTypes.bool.isRequired,
  hasTemporaryEmail: PropTypes.bool.isRequired,
  passwordChangeRequired: PropTypes.bool.isRequired,
  shouldShowKYCquestionnaire: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  const KYCquestionnaireIndex = state.user.questionnaires.findIndex(
    q => q.key === VERIFICATION_KYC
  );

  const isUnsubmitKYCQuestionnaire =
    state.user.questionnaires[KYCquestionnaireIndex].submitted === false;

  const isRejectedKYCQuestionnaire =
    state.user.questionnaires[KYCquestionnaireIndex].status ===
    verificationStatuses.REJECTED;

  const isEmailVerified =
    state.user.verifications.email === verificationStatuses.VERIFIED;

  const isIndetityVerified =
    state.user.verifications.identity === verificationStatuses.VERIFIED;

  const isBankidUser = state.user.details.isBankidUser;

  const isVerifiedPhone =
    state.user.verifications.phone === verificationStatuses.VERIFIED;

  const isEnabledTwoFactor = state.user.details.enabled2FA;
  const isMandatoryTwoFactor =
    state.user.features.TWO_FACTOR_MANDATORY &&
    state.user.features.TWO_FACTOR_MANDATORY.active === true;
  const hasPassword = state.user.details.hasPassword;
  const hasBankAccounts = state.user.bankAccounts.list.length > 0;

  let isVerifiedCredentialsUser =
    isEmailVerified && isVerifiedPhone && hasPassword;
  let isVerifiedBankIdUser =
    isEmailVerified &&
    isIndetityVerified &&
    isVerifiedPhone &&
    hasPassword &&
    !hasBankAccounts;

  if (isMandatoryTwoFactor) {
    isVerifiedCredentialsUser = isVerifiedCredentialsUser && isEnabledTwoFactor;
    isVerifiedBankIdUser = isVerifiedBankIdUser && isEnabledTwoFactor;
  }

  return {
    access: state.user.access,
    isBankidUser: state.user.details.isBankidUser,
    hasTemporaryEmail: state.user.hasTemporaryEmail,
    shouldShowKYCquestionnaire:
      ((isVerifiedCredentialsUser && !isBankidUser) ||
        (isBankidUser && isVerifiedBankIdUser)) &&
      (isUnsubmitKYCQuestionnaire || isRejectedKYCQuestionnaire),
    passwordChangeRequired: state.user.details.passwordChangeRequired
  };
};

export default withRouter(connect(mapStateToProps)(AppRoutes));

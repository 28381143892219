const EVENT_CLOSE_MODAL = "EVENT_CLOSE_MODAL";
const EVENT_GLOBAL_MESSAGES = " EVENT_GLOBAL_MESSAGES";
const EVENT_OPEN_MODAL = "EVENT_OPEN_MODAL";
const EVENT_OPEN_NOTIFICATIONS = "EVENT_OPEN_NOTIFICATIONS";

export default {
  EVENT_CLOSE_MODAL,
  EVENT_GLOBAL_MESSAGES,
  EVENT_OPEN_MODAL,
  EVENT_OPEN_NOTIFICATIONS
};

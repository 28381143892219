import React from "react";
import PropTypes from "prop-types";
import {
  currencyTypes,
  currencies,
  defaultDecimalPlaces
} from "./../../constants";

const allCurrencies = {};

Object.keys(currencies).forEach(currencyType => {
  Object.keys(currencies[currencyType]).forEach(currency => {
    allCurrencies[currency] = {
      type: currencyType,
      code: currency
    };
  });
});

export default function FormattedAmount({ children, currency, monospace }) {
  if (allCurrencies[currency].type === currencyTypes.CRYPTO) {
    return monospace ? (
      <span className="currency-font">
        {`${children.toFixed(defaultDecimalPlaces.crypto)} ${currency}`}
      </span>
    ) : (
      `${children.toFixed(defaultDecimalPlaces.crypto)} ${currency}`
    );
  }

  if (allCurrencies[currency].type === currencyTypes.FIAT) {
    return monospace ? (
      <span className="currency-font">
        {`${children.toFixed(defaultDecimalPlaces.fiat)} ${currency}`}
      </span>
    ) : (
      `${children.toFixed(defaultDecimalPlaces.fiat)} ${currency}`
    );
  }

  return null;
}

FormattedAmount.propTypes = {
  children: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired
};

import BlockexplorerLink from "./../../common/blockexplorer.link/BlockexplorerLink";
import BuyOrderFinishedNextSteps from "../../buy/buy.order.finished.next.steps/BuyOrderFinishedNextSteps";
import CopyToClipboard from "./../../../../components/copy.to.clipboard/CopyToClipboard";
import FormattedAmount from "../../../../components/formatted.amount/FormattedAmount";
import OrderDetailsFooter from "./order.details.footer/OrderDetailsFooter";
import OrderDetailsHeader from "./order.details.header/OrderDetailsHeader";
import OrderDetailsItem from "./OrderDetailsItem";
import PersonalInformation from "../../../../components/personal.information/PersonalInformation";
import Print from "../../../../components/print/Print";
import React from "react";
import TransactionsList from "./order.details.transactions/TransactionsList";
import Translation from "../../../../localization/Translation";
import WithLocalization from "../../../../localization/WithLocalization";
import styles from "./OrderDetails.module.scss";
import { exchangeRateNotice } from "./order.details.config";
import {
  orderTypes,
  orderStatuses,
  paymentMethods
} from "../../../../constants";
import {
  paymentMethodsConfig,
  sepaTransferDetailsConfig
} from "../../buy/payment.methods/payment.methods.config";
import {
  getErrorMsgReason,
  getErrorMsgDetails
} from "../../buy/buy.payment.status/buy.payment.stripe.error.messages.config";

const BuyOrderDetailsModalContent = ({
  localize,
  orderDetailsRef,
  locale,
  order: {
    from,
    number,
    orderDate,
    paymentMethod,
    priority,
    status,
    to,
    transactions,
    type,
    walletAddress,
    feedbackReason,
    feedbackDetails
  }
}) => {
  const { account, bank } = sepaTransferDetailsConfig;
  const isSwish =
    paymentMethod === paymentMethodsConfig.SWISH.name.toUpperCase();
  const isStripeSwish = paymentMethod === paymentMethodsConfig.StripeSwish.name;
  const isStripeCard = paymentMethod === paymentMethodsConfig.StripeCard.name;
  const isBankgiro =
    paymentMethod === paymentMethodsConfig.BANKGIRO.name.toUpperCase();
  const isSepa = paymentMethod === paymentMethodsConfig.SEPA.name.toUpperCase();
  const isBuy = orderTypes.BUY === type;
  const showNextSteps =
    (isBankgiro || isSepa) &&
    [
      orderStatuses.PAYOUT_PENDING.code,
      orderStatuses.PENDING.code,
      orderStatuses.UNPAID.code
    ].includes(status);
  const hasError = feedbackReason !== "" || feedbackDetails !== "";
  const errorMsg = getErrorMsgReason(feedbackReason, locale);
  const errorMsgDetails = getErrorMsgDetails(
    feedbackReason,
    feedbackDetails,
    locale
  );
  const isPaid = orderStatuses.PAID.code === status;

  return (
    <React.Fragment>
      <OrderDetailsHeader
        type={type}
        status={status}
        currency={to.currency}
        paymentMethod={paymentMethod}
      />

      <div className={styles["order-details__main"]}>
        {hasError && !isPaid && (
          <>
            <div className={styles["order-details__title"]}>
              {feedbackReason ? errorMsg : null}
            </div>

            <div className={styles["order-details__info"]}>
              {errorMsgDetails}
            </div>
          </>
        )}
        {showNextSteps && (
          <BuyOrderFinishedNextSteps
            title="Payment instructions"
            amount={isBuy ? from.amount : to.amount}
            activeFiat={isBuy ? from.currency : to.currency}
            orderNumber={number}
            paymentMethod={paymentMethod}
          />
        )}

        <div className={styles["order-details__title"]}>
          <Translation>Order details</Translation>
          <Print reference={orderDetailsRef} />
        </div>
        <OrderDetailsItem label="Order" value={number} />
        {priority && (
          <OrderDetailsItem label="Priority" value={localize(priority)} />
        )}
        <OrderDetailsItem label="Date" value={orderDate} />
        <OrderDetailsItem
          label="Payment method"
          content={() => (
            <Translation>{paymentMethods[paymentMethod].name}</Translation>
          )}
        />
        <OrderDetailsItem
          label="Order amount"
          content={() => (
            <FormattedAmount currency={from.currency}>
              {from.amount}
            </FormattedAmount>
          )}
        />
        <OrderDetailsItem
          label={[to.currency, "Estimated amount"]}
          content={() => (
            <FormattedAmount currency={to.currency}>
              {to.amount}
            </FormattedAmount>
          )}
        />
        <OrderDetailsItem label="Your wallet address" value={walletAddress} />
        <div className={styles["order-details__copy-blockexplorer"]}>
          <CopyToClipboard toCopy={walletAddress} />
          <BlockexplorerLink address={walletAddress} type={to.currency} />
        </div>
        <div className={styles["order-details__info"]}>
          <Translation>{exchangeRateNotice}</Translation>
        </div>
        {(isSwish || isStripeSwish) && (
          <PersonalInformation isListLayout={false} />
        )}
        {isStripeCard && <PersonalInformation isListLayout={false} />}
        {isSepa && (
          <div className={styles["order-details__additional-info"]}>
            <div className={styles["order-details__title"]}>
              <Translation>Additional transfer details</Translation>
            </div>
            <OrderDetailsItem {...account.accountOwner} />
            <OrderDetailsItem {...account.city} />
            <OrderDetailsItem {...account.address} />
            <OrderDetailsItem {...account.country} />
            <OrderDetailsItem {...bank.name} />
            <OrderDetailsItem {...bank.city} />
            <OrderDetailsItem {...bank.address} />
            <OrderDetailsItem {...bank.country} />
          </div>
        )}

        <TransactionsList transactions={transactions} />
      </div>

      <OrderDetailsFooter orderNumber={number} />
    </React.Fragment>
  );
};

export default WithLocalization(BuyOrderDetailsModalContent);

import MARKET_INDICATORS_ACTION_TYPES from "./market.indicators.action.types";

const initialState = {
  isLoading: false,
  isError: false,
  items: []
};

const marketIndicatorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case MARKET_INDICATORS_ACTION_TYPES.FETCH_MARKET_INDICATORS_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case MARKET_INDICATORS_ACTION_TYPES.FETCH_MARKET_INDICATORS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        items: action.payload
      };

    case MARKET_INDICATORS_ACTION_TYPES.FETCH_MARKET_INDICATORS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true
      };

    default:
      return state;
  }
};

export default marketIndicatorsReducer;

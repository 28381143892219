import React from "react"; // eslint-disable-line
import { connect } from "react-redux";
import BtcxExternalLink from "../btcx.external.link/BtcxExternalLink";
import { links } from "./btcx.public.link.config";

const mapStateToProps = state => ({
  locale: state.localization.locale
});
const BtcxPublicLink = connect(mapStateToProps)(BtcxExternalLink);

export { links, BtcxPublicLink };

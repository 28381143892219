import { combineReducers } from "redux";
import latestOrdersReducer from "./latest/latest.orders.reducer";
import listOrdersReducer from "./list/list.orders.reducer";

export const getOrdersWithUpdatedStatus = (orders, action) => {
  return orders.map(item => {
    if (item.reference === action.payload.reference) {
      item.status = action.payload.status;
    }
    return item;
  });
};

export default combineReducers({
  list: listOrdersReducer,
  latest: latestOrdersReducer
});

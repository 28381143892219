import React from "react";
import PropTypes from "prop-types";

const GuideStep = ({ children, step }) => (
  <div className={`guide-${step}`}>{children}</div>
);

GuideStep.propTypes = {
  step: PropTypes.number.isRequired
};

export default GuideStep;

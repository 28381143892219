import backendReducer from "../services/api/backend.reducer";
import bundlesReducer from "../features/orders/bundles/bundles.reducer";
import buyReducer from "../features/orders/buy/buy.reducer";
import graphReducer from "../features/orders/graph/graph.reducer";
import localizationReducer from "../localization/localization.reducer";
import marketIndicatorsReducer from "../features/orders/market.indicators/market.indicators.reducer";
import ordersReducer from "../features/orders/orders.reducer";
import profileReducer from "../features/profile/profile.reducer";
import ratesReducer from "../features/orders/graph/rates/rates.reducer";
import sellReducer from "../features/orders/sell/sell.reducer";
import themesReducer from "../themes/themes.reducer";
import userReducer from "../services/user/user.reducer";
import windowReducer from "./../services/window/window.reducers";
import notificationsReducer from "../features/notifications/notifications.reducer";
import { combineReducers } from "redux";

const reducer = combineReducers({
  activeCalls: backendReducer,
  bundles: bundlesReducer,
  buy: buyReducer,
  graph: graphReducer,
  localization: localizationReducer,
  marketIndicators: marketIndicatorsReducer,
  orders: ordersReducer,
  profile: profileReducer,
  rates: ratesReducer,
  sell: sellReducer,
  theme: themesReducer,
  user: userReducer,
  window: windowReducer,
  notifications: notificationsReducer
});

export default reducer;

import React, { Component, createRef } from "react";
import FooterBaseColumns from "./FooterBaseColumns";
import FooterPaymentMethods from "./FooterPaymentMethods";
import FooterSocialNetworks from "./FooterSocialNetworks";
import Newsletter from "../../../features/newsletter/Newsletter";
import { scrollTo } from "./footer.config";
import styles from "./Footer.module.scss";

class Footer extends Component {
  state = {
    isCollapsed: true
  };

  footerTopRef = createRef();
  footerBottomRef = createRef();

  onToggleCollapse = () => {
    this.setState(
      prevState => ({
        isCollapsed: !prevState.isCollapsed
      }),
      () => {
        if (this.state.isCollapsed) {
          this.footerBottomRef.current.scrollIntoView(scrollTo.bottom);
        } else {
          this.footerTopRef.current.scrollIntoView(scrollTo.top);
        }
      }
    );
  };

  getFooterClassName = isCollapsed => {
    let footerClassName = styles["footer"];

    if (isCollapsed) {
      footerClassName += ` ${styles["footer--is-hidden"]}`;
    }

    return footerClassName;
  };

  getCollapseActionClassName = isCollapsed => {
    let collapseActionClassName = styles["footer__collapse-action"];

    if (isCollapsed) {
      collapseActionClassName += ` ${styles["footer__collapse-action--is-collapsed"]}`;
    }

    return collapseActionClassName;
  };

  render() {
    const { isCollapsed } = this.state;
    const currentYear = new Date().getFullYear();

    return (
      <footer
        className={this.getFooterClassName(isCollapsed)}
        ref={this.footerTopRef}
      >
        <div className={styles["footer__content-wrapper"]}>
          <FooterBaseColumns />
          <div
            className={`${styles["footer__column"]} ${styles["footer__column--wide"]}`}
          >
            <div className={styles["footer__social-column"]}>
              <FooterSocialNetworks />
              <Newsletter />
            </div>
          </div>
          <div
            className={`${styles["footer__column"]} ${styles["footer__column--wide"]}`}
          >
            <div className={styles["footer__social-column"]}>
              <FooterPaymentMethods />
              <span>
                <hr className={styles["footer__separator"]} />
              </span>
            </div>
            <p
              className={styles["footer__copyright"]}
              ref={this.footerBottomRef}
            >
              Copyright © bt.cx {currentYear}
              <span
                className={this.getCollapseActionClassName(isCollapsed)}
                onClick={this.onToggleCollapse}
              />
            </p>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;

import routes from "./routes";
import { hasSufficientAccessRights } from "./../access/access.service";

let registry;

function RoutingRegistry() {
  this.names = [];
  this.routes = [];
}

RoutingRegistry.prototype.init = function() {
  return Promise.all(routes.map(l => import(`./routes/${l}`)))
    .then(modules => {
      modules.forEach(moduleDefinition =>
        this.processRoutesDefinition(moduleDefinition.default)
      );
    })
    .then(() => this);
};

RoutingRegistry.prototype.processRoutesDefinition = function(routesDefinition) {
  // throw if route with same name is already defined
  if (this.names.includes(routesDefinition.name)) {
    throw new Error(`Route(s) for ${routesDefinition.name} is already defined`);
  }

  this.names.push(routesDefinition.name);

  routesDefinition.routes.forEach(route => {
    // throw if route is already defined
    if (this.routes.map(r => r.name).includes(route.name)) {
      throw new Error(`Route ${route.name} is already defined`);
    }

    // all routes that don't explicitly set 'exact' value are 'exact = true'
    if (route.exact === undefined) {
      route.exact = true;
    }

    this.routes.push(route);
  });
};

RoutingRegistry.prototype.getRoutes = function() {
  return this.routes.filter(
    route =>
      route.accessRights &&
      Array.isArray(route.accessRights) &&
      hasSufficientAccessRights(route.accessRights)
  );
};

export const init = async () => {
  registry = new RoutingRegistry();
  await registry.init();
};

export const getRoutingRegistry = () => registry;

import StandardLayout from "./standard/StandardLayout";
import BlankLayout from "./blank/BlankLayout";
import WizardLayout from "./wizard/WizardLayout";

export const layouts = {
  blank: {
    name: "blank",
    layout: BlankLayout
  },
  standard: {
    name: "standard",
    layout: StandardLayout
  },
  wizard: {
    name: "wizard",
    layout: WizardLayout
  }
};

import React from "react";
import PropTypes from "prop-types";
import Translation from "../../../../localization/Translation";
import { BuyOrderStep1 } from "./BuyOrderStep1";
import { BuyOrderStep2 } from "./BuyOrderStep2";
import { BuyOrderStep3 } from "./BuyOrderStep3";
import { BuyOrderStep4 } from "./BuyOrderStep4";
import { paymentMethodsConfig } from "../payment.methods/payment.methods.config";
import styles from "./BuyOrderFinishedNextSteps.module.scss";

const BuyOrderFinishedNextSteps = ({
  amount,
  orderNumber,
  paymentMethod,
  activeFiat,
  title
}) => {
  const paymentMethodConfig = paymentMethodsConfig[paymentMethod.toUpperCase()];
  const { SEPA, BANKGIRO } = paymentMethodsConfig;
  const isSepa =
    paymentMethodConfig.name.toUpperCase() === SEPA.name.toUpperCase();
  const isBankgiro =
    paymentMethodConfig.name.toUpperCase() === BANKGIRO.name.toUpperCase();

  return (
    <div className={styles["buy-order-finished"]}>
      <h2 className={styles["buy-order-finished__title"]}>
        <Translation>{title}</Translation>
      </h2>
      <div className={styles["buy-order-finished__blocks"]}>
        <BuyOrderStep1 paymentMethodConfig={paymentMethodConfig} />
        <BuyOrderStep2
          paymentMethod={paymentMethod}
          paymentMethodConfig={paymentMethodConfig}
          orderNumber={orderNumber}
          activeFiat={activeFiat}
          amount={amount}
          isSepa={isSepa}
          isBankgiro={isBankgiro}
        />
        <BuyOrderStep3
          paymentMethod={paymentMethod}
          paymentMethodConfig={paymentMethodConfig}
          orderNumber={orderNumber}
          activeFiat={activeFiat}
          amount={amount}
          isSepa={isSepa}
          isBankgiro={isBankgiro}
        />
        <BuyOrderStep4
          paymentMethod={paymentMethod}
          paymentMethodConfig={paymentMethodConfig}
          isSepa={isSepa}
        />
      </div>
    </div>
  );
};

BuyOrderFinishedNextSteps.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  orderNumber: PropTypes.string.isRequired,
  paymentMethod: PropTypes.string.isRequired,
  activeFiat: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default BuyOrderFinishedNextSteps;

import URLSearchParams from "@ungap/url-search-params";
import {
  themeUrlParameter,
  availableThemes
} from "../../../themes/themes.config";

const isValidTheme = theme => availableThemes.includes(theme);

export default search => {
  const searchParams = new URLSearchParams(search);

  return (
    searchParams.has(themeUrlParameter) &&
    isValidTheme(searchParams.get(themeUrlParameter))
  );
};

export { isValidTheme };

import React from "react";
import PropTypes from "prop-types";
import WithProfile from "../HOC/WithProfile";
import Translation from "../../localization/Translation";
import PersonalInformationItem from "./PersonalInformationItem";
import List from "../list/List";
import { getPersonalInformationConfig } from "./personal.information.config";
import styles from "./PersonalInformation.module.scss";

const PersonalInformation = ({ profile, isListLayout }) => {
  const { title, personalInformationItems } = getPersonalInformationConfig(
    profile
  );

  return (
    <>
      {isListLayout ? (
        <List title={title} items={Object.values(personalInformationItems)} />
      ) : (
        <div className={styles["personal-information"]}>
          <div className={styles["personal-information__title"]}>
            <Translation>{title}</Translation>
          </div>
          <div className={styles["personal-information__content"]}>
            <PersonalInformationItem {...personalInformationItems.name} />
            <PersonalInformationItem {...personalInformationItems.email} />
            <PersonalInformationItem {...personalInformationItems.city} />
            <PersonalInformationItem {...personalInformationItems.address} />
            <PersonalInformationItem {...personalInformationItems.country} />
            <PersonalInformationItem
              {...personalInformationItems.phoneNumber}
            />
          </div>
        </div>
      )}
    </>
  );
};

PersonalInformation.defaultProps = {
  isListLayout: true
};

PersonalInformation.propTypes = {
  profile: PropTypes.object
};

export default WithProfile(PersonalInformation);

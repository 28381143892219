import ordersFilterModelFactory from "../filters/orders.filter.model.factory";

export const exportFormats = [
  {
    label: "CSV",
    exportFormat: "csv"
  },
  {
    label: "XLS",
    exportFormat: "xls"
  }
];

export const getFormatedExportFilters = ({ page, size, ...exportParams }) => {
  return ordersFilterModelFactory.create({
    size: 10000,
    ...exportParams
  });
};

export const noOrdersMessage =
  "No exported orders. Please try changing the filtering.";

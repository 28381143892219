import React from "react";
import { publish, events } from "../../services/events/events.service";
import PropTypes from "prop-types";
import Button from "../button/Button";

const ModalActionButton = ({
  children,
  content,
  modalConfig,
  extraData,
  actionType,
  ...buttonProps
}) => {
  const onClick = e => {
    e.stopPropagation();
    if (actionType === "open") {
      publish(events.EVENT_OPEN_MODAL, { modalConfig, content });
    }
    if (actionType === "close") {
      publish(events.EVENT_CLOSE_MODAL);
    }
  };

  return (
    <Button onClick={onClick} {...buttonProps} type="button">
      {children}
    </Button>
  );
};

ModalActionButton.defaultProps = {
  actionType: "open",
  modalConfig: {}
};

ModalActionButton.propTypes = {
  children: PropTypes.string.isRequired,
  content: PropTypes.element,
  modalConfig: PropTypes.shape({
    maxWidth: PropTypes.string,
    hasCloseIcon: PropTypes.bool,
    className: PropTypes.string
  }),
  actionType: PropTypes.oneOf(["open", "close"])
};

export default ModalActionButton;

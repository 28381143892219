export const paths = {
  all: "*",
  bank: "/profile/bank",
  buy: "/order",
  changeEmail: "/settings/email",
  changePassword: "/settings/password",
  twoFactorAuth: "/settings/2fa",
  dashboardPath: process.env.REACT_APP_DASHBOARD_PATH,
  documents: "/profile/documents",
  orderConfirmation: "/protected/order/:reference/:status",
  orderList: "/order/list",
  personalProfile: "/profile/personal",
  phone: "/profile/phone",
  questionnaire: "/questionnaire/:key",
  sell: "/order/sell",
  sellOrderConfirmation: "/order/sell/:reference/confirmation",
  verification: "/protected/verification",
  secondVerification: "/protected/second-verification",
  notifications: "/notifications"
};

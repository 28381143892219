import { ADD_ACTIVE_CALL, REMOVE_ACTIVE_CALL } from "./backend.action.types";

const activeCallsReducer = (state = [], { type, payload }) => {
  switch (type) {
    case ADD_ACTIVE_CALL:
      return JSON.parse(JSON.stringify([...state, payload]));

    case REMOVE_ACTIVE_CALL:
      return state.filter(
        item =>
          item.url !== payload.url &&
          item.params !== payload.params &&
          item.method !== payload.method
      );

    default:
      return state;
  }
};

export default activeCallsReducer;

export default (values, value) => {
  if (typeof value !== "string") {
    return false;
  }

  if (value.length > 64 || value.trim().length === 0) {
    return false;
  }

  return !new RegExp("[<>()]+", "g").test(value);
};

import NewOrderBaseModel from "../new.order.base.model";
import { defaults, orderTypes } from "../../../constants";
import { isUserPayNetworkFee } from "../../../features/orders/orders.service";

class NewOrderBuyModel extends NewOrderBaseModel {
  constructor(m) {
    super(m);

    this.networkFee = m && m.networkFee ? m.networkFee : 0;
    this.paymentMethod = m && m.paymentMethod ? m.paymentMethod : "";
    this.priority = m && m.priority ? m.priority : defaults.priority;
    this.type = orderTypes.BUY;
    this.walletAddress = m && m.walletAddress ? m.walletAddress : null;
    this.providerName = m && m.providerName ? m.providerName : "seb";
  }
}

NewOrderBuyModel.prototype.toAPI = function() {
  return {
    from: this.activeFiat,
    address: this.walletAddress,
    method: this.paymentMethod,
    to: this.activeCoin,
    amount: this.amount,
    type: this.type,
    customerPaysNetworkFee: isUserPayNetworkFee(this.activeCoin, this.priority),
    providerName: this.providerName
  };
};

function create(m) {
  return Object.freeze(new NewOrderBuyModel(m));
}

export default { create };

import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import React, { PureComponent } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Translation from "../../localization/Translation";
import globalMessageModelFactory from "./global.message.model.factory";
import styles from "./GlobalMessage.module.scss";
import { SnackbarContent } from "@material-ui/core";
import { messageVariants } from "../../constants";
import {
  baseSnackbarClassName,
  messageConfig,
  snackbarConfig
} from "./global.message.config";
import {
  events,
  publish,
  subscribe,
  unsubscribe
} from "../../services/events/events.service";

class GlobalMessages extends PureComponent {
  state = { message: null, variant: null, open: false };

  componentDidMount() {
    this.globalMessagesSubscriber = subscribe(
      events.EVENT_GLOBAL_MESSAGES,
      (event, { message, variant }) => {
        this.setState({
          message,
          variant,
          open: true
        });
      }
    );
  }

  componentWillUnmount() {
    unsubscribe(this.globalMessagesSubscriber);
  }

  handleClose = () => {
    this.setState({ open: false, message: null });
  };

  render() {
    const { message, variant, open } = this.state;
    const snackbarClassName = `${baseSnackbarClassName}--${variant}`;

    return (
      <Snackbar {...snackbarConfig} open={open} onClose={this.handleClose}>
        <SnackbarContent
          className={styles[snackbarClassName]}
          message={
            <div {...messageConfig} className={styles["global-message__text"]}>
              <Translation>{message}</Translation>
            </div>
          }
          action={[
            <IconButton
              className={styles["global-message__close-icon"]}
              key="close"
              aria-label="Close"
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </Snackbar>
    );
  }
}

const publishGlobalMessage = (message, variant = messageVariants.error) => {
  publish(
    events.EVENT_GLOBAL_MESSAGES,
    globalMessageModelFactory.create({
      message,
      variant
    })
  );
};

export { GlobalMessages, messageVariants, publishGlobalMessage };

import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import IconButton from "../../components/icon.button/IconButton";
import { addMessageToClosedList } from "../../services/user/user.actions";
import styles from "./ImportantMessages.module.scss";

const ImportantMessage = ({ children, addMessageToClosedList, message }) => {
  const handleCloseMessage = () => {
    addMessageToClosedList(message);
  };

  return (
    <div className={styles["important-messages__message"]}>
      {children}
      <IconButton
        className={styles["important-messages__close-icon-btn"]}
        symbol="close"
        onClick={handleCloseMessage}
      />
    </div>
  );
};

ImportantMessage.propTypes = {
  children: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
  addMessageToClosedList: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  addMessageToClosedList
};

export default connect(
  null,
  mapDispatchToProps
)(ImportantMessage);

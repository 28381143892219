import React from "react";
import styles from "./WizardLayout.module.scss";
import NavigationLogo from "../standard/navigation/navigation.logo/NavigationLogo";
import AccountLogout from "../../features/account/AccountLogout";
import ThemeToggler from "../../themes/ThemeToggler";
import { GlobalMessages } from "../../components/global.messages/GlobalMessages";

const WizardLayout = ({ children }) => (
  <div className={styles["layout"]}>
    <GlobalMessages />
    <div className={styles["layout__header"]}>
      <NavigationLogo hasLink={false} />
      <div className={styles["layout__header-main"]}>
        <ThemeToggler hasMinimalLayout={true} />
        <AccountLogout variant="primary-outlined" size="small" />
      </div>
    </div>
    <div className={styles["layout__main"]}>{children}</div>
  </div>
);

export default WizardLayout;

import { verificationStatuses } from "../../constants";

function BankAccountModel(m) {
  this.accountNumber =
    m && m.accountNumber ? getFormattedAccountNumber(m.accountNumber) : "";
  this.bankName = m && m.bankName ? m.bankName : "";
  this.clearingNumber = m && m.clearingNumber ? m.clearingNumber : "";
  this.allowedToEdit =
    m && typeof m.allowedToEdit === "boolean" ? m.allowedToEdit : true;
  this.iban = m && m.iban ? m.iban : "";
  this.verificationStatus =
    m && m.verificationStatus
      ? m.verificationStatus
      : verificationStatuses.UNVERIFIED;
  this.isMain = m && m.isMain ? m.isMain : false;
  this.reference = m && typeof m.reference === "string" ? m.reference : "";
  this.tinkAccountId =
    m && typeof m.tinkAccountId === "string" ? m.tinkAccountId : "";
  this.customBank = m && m.customBank ? m.customBank : null;
}

function getFormattedAccountNumber(accountNumber) {
  return accountNumber.replace(/[-\s]/g, "");
}

function create(m) {
  return Object.seal(new BankAccountModel(m));
}

export default { create };

import moment from "moment";
import { isBoolean } from "util";
import { notificationTypes } from "./notifications.config";
import { orderStatuses } from "../../constants";

function create(m, i) {
  return Object.seal(new NotificationModel(m, i));
}

function NotificationModel(m, i) {
  this.processed = m && isBoolean(m.processed) ? m.processed : false;
  this.order = m.order;
  this.type = m.notification_type;
  this.status = getStatus(m.status);
  this.title = m && m.notification_type ? getTitle(m.notification_type) : "";
  this.createdAt =
    m && m.created_at ? m.created_at : new moment().utc().toISOString();
  this.index = i;
}

function getStatus(status) {
  if (
    typeof status === "string" &&
    Object.keys(orderStatuses).includes(status)
  ) {
    return orderStatuses[status].name;
  }

  return "";
}

function getTitle(type) {
  switch (type) {
    case notificationTypes.ORDER_STATUS_CHANGED:
      return "Your order has been updated";

    case notificationTypes.SUPPORT_REQUEST_UPDATED:
      return "Your support request has been updated";

    case notificationTypes.VERIFICATION_STATUS_CHANGED:
      return "Your verification status has been changed";

    default:
      throw new Error("Unknow notification type");
  }
}

export default { create };

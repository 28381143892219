// important: errors.routes must be last since it contains catch-all route (*)
// add new route definitions on top of the list
export default [
  "profile.routes",
  "settings.routes",
  "order.routes",
  "dashboard.routes",
  "verification.routes",
  "questionnaire.routes",
  "notifications.routes",
  "error.routes"
];

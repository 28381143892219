import React from "react";
import Formsy from "formsy-react";

class Form extends React.PureComponent {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {
      canSubmit: false
    };
  }

  componentDidMount() {
    this.revalidateFormElements();
  }

  revalidateFormElements() {
    try {
      const formReference = this.formRef.current;
      if (formReference) {
        const formElements = this.formRef.current.inputs;

        if (formElements && formElements.length) {
          formElements.forEach(element => {
            const currentValue = element.getValue();

            if (currentValue) {
              element.setValue(currentValue);
            }
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  render() {
    const { canSubmit } = this.state;
    const { onSubmit, onTryToSubmit, forwardedRef, ...restProps } = this.props;

    if (typeof forwardedRef === "function") {
      restProps.ref = forwardedRef;
    } else {
      restProps.ref = this.formRef;
    }

    if (typeof onTryToSubmit === "function") {
      restProps.onSubmit = onTryToSubmit;
    }

    if (typeof onSubmit === "function") {
      restProps.onValidSubmit = onSubmit;
    }

    return (
      <Formsy
        noValidate
        {...restProps}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
      >
        {this.props.children(canSubmit)}
      </Formsy>
    );
  }
}

export default Form;

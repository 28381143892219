import newOrderBuyModelFactory from "./new.order.buy.model.factory";
import {
  REPEAT_BUY_ORDER,
  RESET_BUY_ORDER,
  SET_ACTIVE_COIN,
  SET_ACTIVE_FIAT,
  SET_AMOUNTS,
  SET_NETWORK_FEE,
  SET_PAYMENT_METHOD,
  SET_PRIORITY,
  SET_WALLET_ADDRESS,
  SET_PROVIDER_NAME
} from "./buy.action.types";

const initialState = newOrderBuyModelFactory.create();

const buyReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ACTIVE_FIAT:
      const isSameFiat = state.activeFiat === payload;
      return {
        ...state,
        activeFiat: payload,
        amount: isSameFiat ? state.amount : 0,
        estimatedAmount: isSameFiat ? state.estimatedAmount : 0
      };

    case RESET_BUY_ORDER:
      return initialState;

    case SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: payload
      };

    case SET_AMOUNTS:
      return {
        ...state,
        amount: payload.amount,
        estimatedAmount: payload.estimatedAmount
      };

    case SET_WALLET_ADDRESS:
      return {
        ...state,
        walletAddress: payload
      };

    case SET_ACTIVE_COIN:
      return {
        ...state,
        activeCoin: payload
      };

    case SET_NETWORK_FEE:
      return {
        ...state,
        networkFee: payload
      };

    case SET_PRIORITY:
      return {
        ...state,
        priority: payload
      };

    case SET_PROVIDER_NAME:
      return {
        ...state,
        providerName: payload
      };

    case REPEAT_BUY_ORDER:
      return {
        ...state,
        ...payload
      };
    default:
      return state;
  }
};

export default buyReducer;

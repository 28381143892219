import { init as initAnalyticsService } from "./services/analytics/analytics.service";
import { init as initWindowService } from "./services/window/window.service";
import { init as initAuthService } from "./services/user/user.service";
import { init as initValidationService } from "./services/validation/validation.service";
import { init as initConfigurationService } from "./services/configuration/configuration.service";
import { init as initLoggingService } from "./services/logging/logging.service";
import { init as initRoutingRegistry } from "./routing/routing.registry";

const configureDependencies = async () => {
  initLoggingService();
  initConfigurationService();
  initWindowService();
  initValidationService();
};

export const configureApplication = async () => {
  try {
    await configureDependencies();
    await initAuthService();
    await initRoutingRegistry();
    initAnalyticsService();
  } catch (error) {
    if (error) {
      console.logExternally("Error while configuring application", error);
    }

    return Promise.reject();
  }
};

import React from "react";
import { Link } from "react-router-dom";
import { paths } from "../../../../routing/paths";
import Logo from "../../../../components/logo/Logo";
import styles from "./NavigationLogo.module.scss";

const NavigationLogo = ({ hasLink }) => {
  if (hasLink) {
    return (
      <Link to={paths.dashboardPath}>
        <Logo className={styles["navigation__logo"]} />
      </Link>
    );
  }
  return <Logo className={styles["navigation__logo"]} />;
};

NavigationLogo.defaultProps = {
  hasLink: true
};

export default NavigationLogo;

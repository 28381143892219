const PROFILE_ACTION_TYPES = {
  FETCH_PROFILE_REQUEST: "FETCH_PROFILE_REQUEST",
  FETCH_PROFILE_SUCCESS: "FETCH_PROFILE_SUCCESS",
  FETCH_PROFILE_FAILURE: "FETCH_PROFILE_FAILURE"
};
const UPDATE_BANK_ACCOUNT = "UPDATE_BANK_ACCOUNT";
const SELECT_BANK_ACCOUNT = "SELECT_BANK_ACCOUNT";
const MARK_BANK_ACCOUNT_AS_MAIN = "MARK_BANK_ACCOUNT_AS_MAIN";
const DELETE_BANK_ACCOUNT = "DELETE_BANK_ACCOUNT";
const UPDATE_BANK_ACCOUNT_VERIFICATION = "UPDATE_BANK_ACCOUNT_VERIFICATION";

export default PROFILE_ACTION_TYPES;
export {
  UPDATE_BANK_ACCOUNT,
  SELECT_BANK_ACCOUNT,
  MARK_BANK_ACCOUNT_AS_MAIN,
  DELETE_BANK_ACCOUNT,
  UPDATE_BANK_ACCOUNT_VERIFICATION
};

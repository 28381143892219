const MESSAGE_ID = "message-id";

export const baseSnackbarClassName = "global-message__content";

export const messageConfig = {
  id: MESSAGE_ID
};

export const snackbarConfig = {
  autoHideDuration: 5000,
  anchorOrigin: { vertical: "top", horizontal: "center" },
  ContentProps: {
    "aria-describedby": MESSAGE_ID
  }
};

export const slideConfig = {
  direction: "down"
};

import React from "react"; // eslint-disable-line
import WithLocalization from "./WithLocalization";

const Translation = ({ localize, children }) => {
  try {
    return localize(children);
  } catch (error) {
    console.log(error);
  }

  return null;
};

export default WithLocalization(Translation);

import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Form from "../../components/form/FormWithRef";
import Email from "../../components/email/Email";
import Icon from "../../components/icon/Icon";
import { publish, events as e } from "../../services/events/events.service";
import { messageVariants, defaultErrorMessage } from "../../constants";
import { newsletterSubcribe } from "./newsletter.service";
import { newsletterConfig } from "./newsletter.config";
import Translation from "../../localization/Translation";
import styles from "./Newsletter.module.scss";

class Newsletter extends Component {
  onNewsletterSubmit = ({ email }) => {
    newsletterSubcribe(email)
      .then(() => {
        this.refs.form.reset();
        publish(e.EVENT_GLOBAL_MESSAGES, {
          message: "Successfully subscribed!",
          variant: messageVariants.success
        });
      })
      .catch(error => {
        publish(e.EVENT_GLOBAL_MESSAGES, {
          message: defaultErrorMessage,
          variant: messageVariants.error
        });
      });
  };
  render() {
    return (
      <div className={styles["newsletter"]}>
        <h3 className={styles["newsletter__heading"]}>
          <Translation>Subscribe to Our Newsletter</Translation>
        </h3>
        <Form
          onSubmit={this.onNewsletterSubmit}
          ref="form"
          className={styles["newsletter__form"]}
        >
          {canSubmit => (
            <div className={styles["newsletter__form-control"]}>
              <Email
                emailAddress={this.email}
                {...newsletterConfig.input}
                className={styles["newsletter__input"]}
              />
              <Button
                className={styles["newsletter__btn"]}
                disabled={!canSubmit}
                type="submit"
                color="primary"
                variant="contained"
              >
                <Icon symbol="send" size={14} />
              </Button>
            </div>
          )}
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  locale: state.localization.locale
});

export default connect(mapStateToProps)(Newsletter);

import { orderTypes } from "../../constants";

export default {
  listOrdersDefaultSize: 5,
  latestOrdersDefaultSize: 5
};

export const transactionTypeFilterButtons = [
  { label: "All orders", type: null },
  { label: "Buy", type: orderTypes.BUY },
  { label: "Sell", type: orderTypes.SELL }
];

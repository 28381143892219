import { SET_WINDOW_SIZE } from "./window.action.types";
import { isLowRes } from "./window.service";

const windowResizeAction = () => ({
  type: SET_WINDOW_SIZE,
  payload: {
    isLowRes: isLowRes()
  }
});

export { windowResizeAction };

import moment from "moment";
import { dateFormats } from "../../../../../constants";
import {
  fees,
  transactionDirections,
  transactionExplorers
} from "./transactions.configuration";

const { networkFee, paymentMethodFee } = fees;

class OrderTransactionModel {
  constructor(o) {
    this.amount = o && o.amount ? o.amount : 0;
    this.value = o && o.value ? parseFloat(o.value) : 0;
    this.paymentMethod = getPaymentMethod(o);
    this.currency = o && o.currency ? o.currency : null;
    this.date = getDate(o);
    this.message = o && o.paymentMessage ? o.paymentMessage : "";
    this.account = o.account;
    this.explorerLink = getExplorerLink(o);
    this.direction = getTransactionDirection(o);
  }
}

function getDate(o) {
  return (typeof o.date).localeCompare("undefined")
    ? moment.utc(o.date).format(dateFormats.extended)
    : "";
}

function getExplorerLink(data) {
  if (data.hash) {
    if (transactionExplorers[data.currency]) {
      return transactionExplorers[data.currency] + data.hash;
    }

    return null;
  }

  return null;
}

function getPaymentMethod(data) {
  if (data.feeType === paymentMethodFee.key) {
    return paymentMethodFee.name;
  }

  if (data.paymentMethodName) {
    return data.paymentMethodName;
  }

  if (data.feeType === networkFee.key) {
    return networkFee.name;
  }

  return null;
}

function getTransactionDirection(o) {
  if (o.feeType === networkFee.key || o.feeType === paymentMethodFee.key) {
    return transactionDirections.OUT;
  }

  return o.direction;
}

function create(m) {
  return Object.freeze(new OrderTransactionModel(m));
}

export default { create };

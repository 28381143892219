import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { themes, availableThemes } from "./themes.config";
import {
  getThemeFromUrl,
  getThemeFromCookie,
  setTheme
} from "../themes/themes.service";

class ThemeProvider extends React.PureComponent {
  constructor(props) {
    super(props);
    const { theme, setTheme } = this.props;
    const themeFromUrl = getThemeFromUrl(window.location.search);

    if (themeFromUrl && themeFromUrl !== theme) {
      return setTheme(themeFromUrl);
    }

    setTheme(getThemeFromCookie());
  }

  render() {
    const { children, theme } = this.props;
    return (
      <MuiThemeProvider theme={themes[theme].theme}>
        {children}
      </MuiThemeProvider>
    );
  }
}

ThemeProvider.propTypes = {
  theme: PropTypes.oneOf(availableThemes).isRequired
};

const mapStateToProps = state => ({
  theme: state.theme
});

export default connect(
  mapStateToProps,
  { setTheme }
)(ThemeProvider);

import countries from "../../features/profile/countries.json";

export const getPersonalInformationConfig = ({
  firstName,
  lastName,
  city,
  countryCode,
  postalCode,
  email,
  address,
  phoneNumber
}) => {
  const title = "Personal information";

  const personalInformationItems = {
    name: {
      label: "Name",
      value: `${firstName} ${lastName}`
    },
    city: {
      label: "City",
      value: city
    },
    country: {
      label: "Country",
      value: countries.find(el => el.code === countryCode).name
    },
    email: {
      label: "Email",
      value: email
    },
    postalCode: {
      label: "Zip Code",
      value: postalCode
    },
    address: {
      label: "Address",
      value: address
    },
    phoneNumber: {
      label: "Mobile Number",
      value: phoneNumber
    }
  };

  return {
    title,
    personalInformationItems
  };
};

import API from "../../services/api/backend";

const walletDetails = walletpass =>
  API.backend({
    url: API.endpoints.walletDetails,
    method: API.methods.POST,
    data: { walletpass }
  });

export { walletDetails };

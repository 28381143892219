import { defaults } from "./../../../constants";
import {
  FETCH_BUNDLES_FAILURE,
  FETCH_BUNDLES_REQUEST,
  FETCH_BUNDLES_SUCCESS,
  SET_BUNDLES_CRYPTO
} from "./bundles.action.types";

const initialState = {
  isError: false,
  isLoaded: false,
  isLoading: false,
  items: {},
  fiat: defaults.fiat,
  crypto: defaults.crypto
};

const bundlesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BUNDLES_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case FETCH_BUNDLES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isLoaded: true,
        items: action.payload
      };

    case FETCH_BUNDLES_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true
      };

    case SET_BUNDLES_CRYPTO:
      return {
        ...state,
        crypto: action.payload
      };

    default:
      return state;
  }
};

export default bundlesReducer;

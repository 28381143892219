import React from "react";
import PropTypes from "prop-types";
import Image from "../../../../components/image/Image";
import Translation from "../../../../localization/Translation";
import cellPhone from "../../../../../resources/images/cell-phone.svg";
import BuyOrderFinishedInfo from "./BuyOrderFinishedInfo";

const BuyOrderStep1 = ({ paymentMethodConfig }) => {
  return (
    <BuyOrderFinishedInfo
      step={1}
      title={
        <Translation>{paymentMethodConfig.instructions.step1}</Translation>
      }
      text={<Translation>Your bank</Translation>}
    >
      <Image alt={paymentMethodConfig.instructions.step1} src={cellPhone} />
    </BuyOrderFinishedInfo>
  );
};

BuyOrderStep1.propTypes = {
  paymentMethodConfig: PropTypes.object.isRequired
};

export { BuyOrderStep1 };

export const SET_USER = "SET_USER";
export const ADD_IMPORTANT_MESSAGE_TO_CLOSED_LIST =
  "ADD_IMPORTANT_MESSAGE_TO_CLOSED_LIST";
export const SET_PAGE_LIST_OF_IMPORTANT_MESSAGES =
  "SET_PAGE_LIST_OF_IMPORTANT_MESSAGES";
export const SET_GUIDE_DISPLAYED = "SET_GUIDE_DISPLAYED";

export const MARK_KYC_QUESTIONNAIRE_AS_SUBMITTED =
  "MARK_KYC_QUESTIONNAIRE_AS_SUBMITTED";
export const MARK_2FA_AS_ENABLED = "MARK_2FA_AS_ENABLED";
export const MARK_2FA_AS_DISABLED = "MARK_2FA_AS_DISABLED";
export const MARK_PHONE_AS_VERIFIED = "MARK_PHONE_AS_VERIFIED";
export const REMOVE_BANK_ACCOUNTS = "REMOVE_BANK_ACCOUNTS";

import React from "react";
import { TableCell as TC } from "@material-ui/core";
import WithLocalization from "../../localization/WithLocalization";

const TableCell = ({
  "data-title": dataTitle,
  children,
  dispatch,
  localize,
  ...rest
}) => (
  <TC data-title={localize(dataTitle)} {...rest}>
    {children}
  </TC>
);

export default WithLocalization(TableCell);

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import App from "./app/App";
import { configureApplication } from "./app/app.service";
import "./resources/styles/index.scss";

configureApplication()
  .then(() => {
    try {
      ReactDOM.render(<App />, document.getElementById("root"));
    } catch (error) {
      console.logExternally("Global error captured", { error });
    }
  })
  .catch(err => {});

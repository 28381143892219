import React from "react";
import WithLocalization from "../../localization/WithLocalization";

export class GlobalErrorBoundary extends React.PureComponent {
  state = {
    hasError: false,
    shouldRedirect: false
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  isSkippableError(error) {
    if (error && DOMException && error.constructor === DOMException) {
      return true;
    }

    return false;
  }

  componentDidCatch(error, errorInfo) {
    console.logExternally("GlobalErrorBoundary caught error", errorInfo);

    if (this.isSkippableError(error)) {
      return;
    }

    this.setState({ shouldRedirect: true });
  }

  redirectToErrorPage = locale => {
    window.location = `${process.env.REACT_APP_PUBLIC_APP_URL}${locale}/error.html`;
  };

  render() {
    const { locale } = this.props;

    switch (true) {
      case !this.state.shouldRedirect:
        return this.props.children;

      default:
        setTimeout(() => {
          this.redirectToErrorPage(locale);
        }, 0);

        return null;
    }
  }
}

export default WithLocalization(GlobalErrorBoundary);

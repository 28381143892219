import { createStore, applyMiddleware, compose } from "redux";
import ReduxThunk from "redux-thunk";
import rootReducer from "./rootReducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const reduxDevToolsEnabled =
  process.env.REACT_APP_REDUX_DEV_TOOLS_ENABLED === "true";
const middlewares = applyMiddleware(ReduxThunk);
const enhancer = reduxDevToolsEnabled
  ? composeEnhancers(middlewares)
  : middlewares;

const store = createStore(rootReducer, enhancer);

export default store;

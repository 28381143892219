import React from "react";
import IconButton from "../../../../components/icon.button/IconButton";
import NavigationLinks from "../navigation.links/NavigationLinks";
import Notifications from "../../../../features/notifications/Notifications";
import AccountMenu from "../../../../features/account/AccountMenu";
import NavigationLogo from "../navigation.logo/NavigationLogo";
import ThemeToggler from "../../../../themes/ThemeToggler";
import styles from "./MobileNavigation.module.scss";
import {
  subscribe,
  unsubscribe,
  events
} from "../../../../services/events/events.service";

class MobileNavigation extends React.Component {
  state = {
    isExpanded: false
  };

  componentDidMount() {
    this.notifications = subscribe(events.EVENT_OPEN_NOTIFICATIONS, isOpen => {
      this.setState({ isExpanded: false });
    });
  }

  componentWillUnmount() {
    unsubscribe(this.notifications);
  }

  toggleMenu = e => {
    this.setState(prevState => ({ isExpanded: !prevState.isExpanded }));
  };

  render() {
    const { isExpanded } = this.state;
    const mobileLinksClassName = isExpanded
      ? "mobile__links--active"
      : "mobile__links";
    const mobileMenuIconClassName = isExpanded
      ? "mobile__menu-icon--active"
      : "mobile__menu-icon";

    return (
      <div className={styles["mobile"]}>
        <div className={styles["mobile__header"]}>
          <NavigationLogo />
          <Notifications />
          <IconButton
            className={styles[mobileMenuIconClassName]}
            onClick={this.toggleMenu}
            symbol="hamburger"
            size={18}
          />
        </div>
        <div className={styles[mobileLinksClassName]} onClick={this.toggleMenu}>
          <ThemeToggler />
          <NavigationLinks />
          <AccountMenu />
        </div>
      </div>
    );
  }
}

export default MobileNavigation;

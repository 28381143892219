import React from "react";
import PropTypes from "prop-types";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import TooltipMUI from "@material-ui/core/Tooltip";
import WithToggle from "../HOC/WithToggle";

const TooltipEmpty = ({ onClose, title, children }) => (
  <ClickAwayListener onClickAway={onClose}>
    <TooltipMUI title={title}>{children}</TooltipMUI>
  </ClickAwayListener>
);

TooltipEmpty.propTypes = {
  title: PropTypes.string.isRequired
};

export default WithToggle(TooltipEmpty);

import validations from "./../../services/validation/validation.config";

export const newsletterConfig = {
  input: {
    name: "email",
    label: null,
    required: true,
    placeholder: "Enter your email",
    validations: `${[validations.isDefined]},${
      validations.isValidEmailAddress
    }`,
    validationErrors: {
      isDefaultRequiredValue: "This field is required",
      [validations.isValidEmailAddress]: "Please enter valid email address"
    }
  }
};

import React from "react";
import PropTypes from "prop-types";
import WithLocalization from "../../localization/WithLocalization";

const Image = ({ alt, localize, ...rest }) => (
  <img alt={localize(alt)} {...rest} />
);

Image.propTypes = {
  alt: PropTypes.string.isRequired
};

export default WithLocalization(Image);

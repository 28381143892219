import React, { memo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AccountMenuItem from "./AccountMenuItem";
import ModalActionButton from "../../components/modal/ModalActionButton";
import WalletExport from "../../features/wallet.export/WalletExport";
import { walletExportModalConfig } from "../wallet.export/wallet.export.config";
import { WithAccessRight, ACCESS_RIGHTS } from "../../access/WithAccessRight";

const WalletLink = ({ handleCloseMenu, onCloseModal, hasWallet }) => {
  if (hasWallet) {
    return (
      <WithAccessRight acr={[ACCESS_RIGHTS.CAN_SEE_FULL_APP]}>
        <AccountMenuItem handleCloseMenu={handleCloseMenu}>
          <ModalActionButton
            modalConfig={walletExportModalConfig}
            content={<WalletExport onCloseModal={onCloseModal} />}
          >
            Wallet export
          </ModalActionButton>
        </AccountMenuItem>
      </WithAccessRight>
    );
  }

  return null;
};

WalletLink.propTypes = {
  handleCloseMenu: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  hasWallet: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  hasWallet: state.user.details.hasWallet
});

const walletLinkPropsAreEqual = (prevProps, nextProps) =>
  prevProps.hasWallet === nextProps.hasWallet;

export default connect(mapStateToProps)(
  memo(WalletLink, walletLinkPropsAreEqual)
);

import React from "react";
import { publishGlobalMessage } from "./../global.messages/GlobalMessages";
import { messageVariants } from "./../../constants";

const WithGlobalMessagesHOC = Component => props => (
  <Component
    {...props}
    publishGlobalMessage={publishGlobalMessage}
    variants={messageVariants}
  />
);

export default WithGlobalMessagesHOC;

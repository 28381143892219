import * as actions from "./notifications.action.types";

const initialState = {
  items: [],
  isLoading: false,
  isError: false
};

const notificationsReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case actions.FETCH_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case actions.FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        items: [...action.payload].sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )
      };
    case actions.FETCH_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    case actions.SET_ALL_NOTIFICATIONS_AS_PROCESSED:
      return {
        ...state,
        items: state.items.map(notification => {
          notification.processed = true;

          return notification;
        })
      };
    default:
      return state;
  }
};

export default notificationsReducer;
export { initialState };

import API from "../../services/api/backend";
import store from "../../store/store";
import PROFILE_ACTION_TYPES from "./profile.action.types";
import profileModelFactory from "./profile.model.factory";
import bankAccountModelFactory from "./profile.bank.account.model.factory";
import { getUser } from "../../services/user/user.service";

export const getProfile = () =>
  API.backendThunk(
    PROFILE_ACTION_TYPES,
    {
      url: API.endpoints.profile,
      method: API.methods.GET
    },
    profile => ({
      payload: profileModelFactory.create(profile)
    })
  );

export const editProfile = ({ ...personalData }) =>
  API.backend({
    url: API.endpoints.personalProfile,
    method: API.methods.POST,
    data: personalData
  })
    .then(getUser)
    .then(() => store.dispatch(getProfile()));

export const uploadDocument = (data, id) => {
  const formData = new FormData();
  formData.append(id, data);

  return API.backend({
    url: API.endpoints.profileId,
    method: API.methods.POST,
    isMultipart: true,
    data: formData
  })
    .then(getUser)
    .then(() => store.dispatch(getProfile()));
};

export const editPhoneNumber = phoneNumber => {
  return API.backend({
    url: API.endpoints.profilePhone,
    method: API.methods.POST,
    data: { phoneNumber }
  }).catch(err => {
    if (err.message && typeof err.message === "string") {
      return Promise.reject(err.message);
    }
    return Promise.reject(err);
  });
};

export const verifyPhoneNumber = (verificationCode, verification) =>
  API.backend({
    url: API.endpoints.profilePhoneVerification,
    method: API.methods.POST,
    data: { verificationCode, verification }
  });

export const confirmProfile = isConfirmed =>
  API.backend({
    url: API.endpoints.profileConfirm,
    method: API.methods.POST,
    data: {
      confirmed: !!isConfirmed
    }
  });

export const formatPersonalNumber = val => {
  if (typeof val === "string") {
    let _val = val.replace(/[- ]/g, "");

    if (/^(20|19)?\d{10}$/.test(_val)) {
      if (_val.length === 10) {
        let year = new Date().getFullYear();

        if (_val.slice(0, 2) / 1 < year % 100) {
          _val = Math.floor(year / 100) + _val;
        } else {
          _val = Math.floor(year / 100 - 1) + _val;
        }
      }

      return _val.slice(0, 8) + "-" + _val.slice(-4);
    }
    return val;
  }
  throw new Error("Personal number is not a string.");
};

export const getCustomBankAccount = bank => {
  return bankAccountModelFactory.create(bank);
};

import {
  SET_ORDERS_FILTER_BY_PROPERTY,
  REMOVE_ORDERS_FILTER_BY_PROPERTY,
  RESET_ORDERS_FILTERS,
  SET_ORDER_STATUS,
  SET_DATE_FILTER,
  ADD_NEW_ORDER,
  LOAD_MODE_ORDERS
} from "./orders.action.types";

export const setFilterByProperty = payload => ({
  type: SET_ORDERS_FILTER_BY_PROPERTY,
  payload
});

export const setDateFilter = (period, value) => ({
  type: SET_DATE_FILTER,
  period,
  value
});

export const removeFilterByProperty = payload => ({
  type: REMOVE_ORDERS_FILTER_BY_PROPERTY,
  payload
});

export const resetOrdersFilters = () => ({
  type: RESET_ORDERS_FILTERS
});

export const setOrderStatus = payload => ({
  type: SET_ORDER_STATUS,
  payload
});

export const addNewOrder = payload => ({
  type: ADD_NEW_ORDER,
  payload
});

export const loadMoreOrders = () => ({
  type: LOAD_MODE_ORDERS
});

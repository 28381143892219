import AppRoutes from "./routing/AppRoutes";
import CssBaseline from "@material-ui/core/CssBaseline";
import GlobalErrorBoundary from "./components/global.error.boundary/GlobalErrorBoundary";
import JssProvider from "react-jss/lib/JssProvider";
import React from "react";
import ThemeProvider from "./themes/ThemeProvider";
import store from "./store/store";
import { BrowserRouter } from "react-router-dom";
import { Provider as StoreProvider } from "react-redux";
import { create } from "jss";
import { createGenerateClassName, jssPreset } from "@material-ui/core/styles";
import Support from "./features/support/Support";
import WithEnabledFeature from "./components/HOC/WithEnabledFeature";
import { features } from "./constants";

const SupportEnabled = WithEnabledFeature(Support);

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point")
});

const App = () => (
  <StoreProvider store={store}>
    <GlobalErrorBoundary>
      <CssBaseline />
      <JssProvider jss={jss} generateClassName={createGenerateClassName()}>
        <ThemeProvider>
          <BrowserRouter basename={process.env.REACT_APP_ROUTES_PREFIX}>
            <AppRoutes />
          </BrowserRouter>
        </ThemeProvider>
      </JssProvider>
      <SupportEnabled feature={features.ZENDESK} />
    </GlobalErrorBoundary>
  </StoreProvider>
);

export default App;

import React, { PureComponent } from "react";

const WithToggleHOC = Component => {
  class WithToggle extends PureComponent {
    state = {
      isOpen: false
    };

    onOpen = () => {
      this.setState({ isOpen: true });
    };

    onClose = () => {
      this.setState({ isOpen: false });
    };

    render() {
      const { isOpen } = this.state;
      return (
        <Component
          {...this.props}
          isOpen={isOpen}
          onClose={this.onClose}
          onOpen={this.onOpen}
        />
      );
    }
  }

  return WithToggle;
};

export default WithToggleHOC;

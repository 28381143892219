import { LATEST_ORDERS_ACTION_TYPES } from "./latest.orders.action.types";
import {
  SET_ORDER_STATUS,
  ADD_NEW_ORDER
} from "../../orders/orders.action.types";
import { getOrdersWithUpdatedStatus } from "../orders.reducer";

const latestOrdersReducer = (
  state = {
    items: [],
    isLoading: false,
    isError: false,
    isLoaded: false
  },
  action
) => {
  switch (action.type) {
    case LATEST_ORDERS_ACTION_TYPES.FETCH_LATEST_ORDERS_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case LATEST_ORDERS_ACTION_TYPES.FETCH_LATEST_ORDERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isLoaded: true,
        items: action.payload.items
      };
    case LATEST_ORDERS_ACTION_TYPES.FETCH_LATEST_ORDERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    case SET_ORDER_STATUS:
      return {
        ...state,
        items: getOrdersWithUpdatedStatus(state.items, action)
      };
    case ADD_NEW_ORDER:
      return {
        ...state,
        items: [...state.items, action.payload]
      };
    default:
      return state;
  }
};

export default latestOrdersReducer;

class UserBankAccountModel {
  constructor(
    {
      accountName = "",
      externalAccountId = "",
      accountNumber = "",
      iban = ""
    } = {
      accountName: "",
      externalAccountId: "",
      accountNumber: "",
      iban: ""
    }
  ) {
    this.reference =
      typeof externalAccountId === "string" ? externalAccountId : "";
    this.bankName = typeof accountName === "string" ? accountName : "";
    this.externalId =
      typeof externalAccountId === "string" ? externalAccountId : "";
    this.accountNumber = typeof accountNumber === "string" ? accountNumber : "";
    this.iban = typeof iban === "string" ? iban : "";
  }
}

function create(m) {
  return Object.seal(new UserBankAccountModel(m));
}

export default { create };

export const jiraTrackersConfig = {
  reportBug: {
    url:
      "https://btcxtest.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-qb4rxh/b/18/a44af77267a987a660377e5c46e0fb64/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=5f3450f4",
    targetEnvironments: ["dev", "test"]
  },
  addFeedback: {
    url:
      "https://btcxtest.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-qb42pu/b/18/a44af77267a987a660377e5c46e0fb64/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=cb5d563c",
    targetEnvironments: ["dev", "test"]
  }
};

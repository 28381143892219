import PropTypes from "prop-types";
import React from "react";
import SupportLink from "../../../../../components/support.link/SupportLink";
import Translation from "../../../../../localization/Translation";
import styles from "./OrderDetailsFooter.module.scss";

const OrderDetailsFooter = ({ orderNumber }) => (
  <div className={styles["order-details__footer"]}>
    <Translation>
      Experiencing any problems? Don’t hesitate to contact
    </Translation>
    &nbsp;
    <SupportLink orderNumber={orderNumber} anchor="support-form-wrapper">
      support
    </SupportLink>
    .
  </div>
);

OrderDetailsFooter.propTypes = {
  orderNumber: PropTypes.string.isRequired
};

export default OrderDetailsFooter;

export const paymentErrorMsgs = {
  authentication_required: {
    title: {
      en: "Authentication required",
      sv: "Autentisering krävs"
    },
    description: {
      en:
        "The card was declined because the transaction requires authentication such as 3D Secure.",
      sv:
        "Kortet avvisades eftersom transaktionen kräver autentisering, till exempel 3D Secure."
    }
  },
  approve_with_id: {
    title: {
      en: "Approve with ID",
      sv: "Godkänn med ID"
    },
    description: {
      en: "The payment can’t be authorised.",
      sv: "Betalningen kan inte godkännas."
    }
  },
  call_issuer: {
    title: {
      en: "Call issuer",
      sv: "Kontakta utgivaren"
    },
    description: {
      en: "The card was declined for an unknown reason.",
      sv: "Kortet avvisades av en okänd anledning."
    }
  },
  card_not_supported: {
    title: {
      en: "Card not supported",
      sv: "Kortet stöds inte"
    },
    description: {
      en: "The card does not support this type of purchase.",
      sv: "Kortet stöder inte denna typ av köp."
    }
  },
  card_velocity_exceeded: {
    title: {
      en: "Card velocity exceeded",
      sv: "Kortgräns överskriden"
    },
    description: {
      en:
        "The customer has exceeded the balance, credit limit, or transaction amount limit available on their card.",
      sv:
        "Kunden har överskridit saldot, kreditgränsen eller transaktionsgränsen som är tillgänglig på deras kort."
    }
  },
  currency_not_supported: {
    title: {
      en: "Currency not supported",
      sv: "Valuta stöds inte"
    },
    description: {
      en: "The card does not support the specified currency.",
      sv: "Kortet stöder inte den angivna valutan."
    }
  },
  do_not_honor: {
    title: {
      en: "Do not honor",
      sv: "Avvisa transaktion"
    },
    description: {
      en: "The card was declined for an unknown reason.",
      sv: "Kortet avvisades av en okänd anledning."
    }
  },
  do_not_try_again: {
    title: {
      en: "Do not try again",
      sv: "Försök inte igen"
    },
    description: {
      en: "The card was declined for an unknown reason.",
      sv: "Kortet avvisades av en okänd anledning."
    }
  },
  duplicate_transaction: {
    title: {
      en: "Duplicate transaction",
      sv: "Duplicerad transaktion"
    },
    description: {
      en:
        "A transaction with identical amount and credit card information was submitted very recently.",
      sv:
        "En transaktion med samma belopp och kortinformation skickades mycket nyligen."
    }
  },
  expired_card: {
    title: {
      en: "Expired card",
      sv: "Utgånget kort"
    },
    description: {
      en: "The card has expired.",
      sv: "Kortet har gått ut."
    }
  },
  fraudulent: {
    title: {
      en: "Generic decline",
      sv: "Generellt avslag"
    },
    description: {
      en:
        "The card was declined for an unknown reason or Stripe Radar blocked the payment.",
      sv:
        "Kortet avvisades av en okänd anledning eller så blockerade Stripe Radar betalningen."
    }
  },
  generic_decline: {
    title: {
      en: "Generic decline",
      sv: "Generellt avslag"
    },
    description: {
      en:
        "The card was declined for an unknown reason or Stripe Radar blocked the payment.",
      sv:
        "Kortet avvisades av en okänd anledning eller så blockerade Stripe Radar betalningen."
    }
  },
  incorrect_number: {
    title: {
      en: "Incorrect number",
      sv: "Felaktigt kortnummer"
    },
    description: {
      en: "The card number is incorrect.",
      sv: "Kortnumret är felaktigt."
    }
  },
  incorrect_cvc: {
    title: {
      en: "Incorrect CVC",
      sv: "Felaktig CVC"
    },
    description: {
      en: "The CVC number is incorrect.",
      sv: "CVC-numret är felaktigt."
    }
  },
  incorrect_pin: {
    title: {
      en: "Incorrect PIN",
      sv: "Felaktig PIN"
    },
    description: {
      en:
        "The PIN entered is incorrect. This decline code only applies to payments made with a card reader.",
      sv:
        "Den angivna PIN-koden är felaktig. Denna avslagskod gäller endast betalningar med kortläsare."
    }
  },
  incorrect_zip: {
    title: {
      en: "Incorrect zip",
      sv: "Felaktigt postnummer"
    },
    description: {
      en: "The postal code is incorrect.",
      sv: "Postnumret är felaktigt."
    }
  },
  insufficient_funds: {
    title: {
      en: "Insufficient funds",
      sv: "Otillräckliga medel"
    },
    description: {
      en: "The card has insufficient funds to complete the purchase.",
      sv: "Kortet har otillräckliga medel för att slutföra köpet."
    }
  },
  invalid_account: {
    title: {
      en: "Invalid account",
      sv: "Ogiltigt konto"
    },
    description: {
      en: "The card, or account the card is connected to, is invalid.",
      sv: "Kortet eller kontot som kortet är kopplat till är ogiltigt."
    }
  },
  invalid_amount: {
    title: {
      en: "Invalid amount",
      sv: "Ogiltigt belopp"
    },
    description: {
      en:
        "The payment amount is invalid, or exceeds the amount that’s allowed.",
      sv: "Betalningsbeloppet är ogiltigt eller överstiger den tillåtna summan."
    }
  },
  invalid_cvc: {
    title: {
      en: "Invalid CVC",
      sv: "Ogiltig CVC"
    },
    description: {
      en: "The CVC number is incorrect.",
      sv: "CVC-numret är felaktigt."
    }
  },
  invalid_expiry_month: {
    title: {
      en: "Invalid expiry month",
      sv: "Ogiltig utgångsmånad"
    },
    description: {
      en: "The expiry month is invalid.",
      sv: "Utgångsmånaden är ogiltig."
    }
  },
  invalid_expiry_year: {
    title: {
      en: "Invalid expiry year",
      sv: "Ogiltigt utgångsår"
    },
    description: {
      en: "The expiry year is invalid.",
      sv: "Utgångsåret är ogiltigt."
    }
  },
  invalid_number: {
    title: {
      en: "Invalid number",
      sv: "Ogiltigt kortnummer"
    },
    description: {
      en: "The card number is incorrect.",
      sv: "Kortnumret är felaktigt."
    }
  },
  invalid_pin: {
    title: {
      en: "Invalid PIN",
      sv: "Ogiltig PIN"
    },
    description: {
      en: "The PIN entered is incorrect.",
      sv: "Den angivna PIN-koden är ogiltig."
    }
  },
  issuer_not_available: {
    title: {
      en: "Issuer not available",
      sv: "Utfärdare ej tillgänglig"
    },
    description: {
      en:
        "The card issuer couldn’t be reached, so the payment couldn’t be authorised.",
      sv:
        "Kortutfärdaren kunde inte nås, så betalningen kunde inte auktoriseras."
    }
  },
  lost_card: {
    title: {
      en: "Generic decline",
      sv: "Generellt avslag"
    },
    description: {
      en:
        "The card was declined for an unknown reason or Stripe Radar blocked the payment.",
      sv:
        "Kortet avvisades av en okänd anledning eller så blockerade Stripe Radar betalningen."
    }
  },
  merchant_blacklist: {
    title: {
      en: "Generic decline",
      sv: "Generellt avslag"
    },
    description: {
      en:
        "The card was declined for an unknown reason or Stripe Radar blocked the payment.",
      sv:
        "Kortet avvisades av en okänd anledning eller så blockerade Stripe Radar betalningen."
    }
  },
  new_account_information_available: {
    title: {
      en: "New account information available",
      sv: "Ny kontoinformation tillgänglig"
    },
    description: {
      en: "The card, or account the card is connected to, is invalid.",
      sv: "Kortet eller kontot som kortet är kopplat till är ogiltigt."
    }
  },
  no_action_taken: {
    title: {
      en: "No action taken",
      sv: "Ingen åtgärd vidtagen"
    },
    description: {
      en: "The card was declined for an unknown reason.",
      sv: "Kortet avvisades av en okänd anledning."
    }
  },
  not_permitted: {
    title: {
      en: "Not permitted",
      sv: "Inte tillåtet"
    },
    description: {
      en: "The payment isn’t permitted.",
      sv: "Betalningen är inte tillåten."
    }
  },
  offline_pin_required: {
    title: {
      en: "Offline pin required",
      sv: "Offline pin krävs"
    },
    description: {
      en: "The card was declined because it requires a PIN.",
      sv: "Kortet avvisades eftersom det kräver en PIN-kod."
    }
  },
  online_or_offline_pin_required: {
    title: {
      en: "Online or offline pin required",
      sv: "Online eller offline pin krävs"
    },
    description: {
      en: "The card was declined because it requires a PIN.",
      sv: "Kortet avvisades eftersom det kräver en PIN-kod."
    }
  },
  pickup_card: {
    title: {
      en: "Pickup card",
      sv: "Hämta kort"
    },
    description: {
      en:
        "The card was declined for an unknown reason or Stripe Radar blocked the payment.",
      sv:
        "Kortet avvisades av en okänd anledning eller så blockerade Stripe Radar betalningen."
    }
  },
  pin_try_exceeded: {
    title: {
      en: "Pin try exceeded",
      sv: "Försöksgräns pin överskriden"
    },
    description: {
      en: "The allowable number of PIN tries was exceeded.",
      sv: "Det tillåtna antalet PIN-försök har överskridits."
    }
  },
  processing_error: {
    title: {
      en: "Processing error",
      sv: "Bearbetningsfel"
    },
    description: {
      en: "An error occurred while processing the card.",
      sv: "Ett fel uppstod vid bearbetning av kortet."
    }
  },
  reenter_transaction: {
    title: {
      en: "Reenter transaction",
      sv: "Ange transaktion igen"
    },
    description: {
      en:
        "The payment couldn’t be processed by the issuer for an unknown reason.",
      sv:
        "Betalningen kunde inte bearbetas av utfärdaren av en okänd anledning."
    }
  },
  restricted_card: {
    title: {
      en: "Restricted card",
      sv: "Begränsat kort"
    },
    description: {
      en:
        "The card was declined for an unknown reason or Stripe Radar blocked the payment.",
      sv:
        "Kortet avvisades av en okänd anledning eller så blockerade Stripe Radar betalningen."
    }
  },
  revocation_of_all_authorizations: {
    title: {
      en: "Revocation of all authorizations",
      sv: "Återkallande av alla auktoriseringar"
    },
    description: {
      en: "The card was declined for an unknown reason.",
      sv: "Kortet avvisades av en okänd anledning."
    }
  },

  revocation_of_authorization: {
    title: {
      en: "Revocation of authorization",
      sv: "Återkallande av auktorisering"
    },
    description: {
      en: "The card was declined for an unknown reason.",
      sv: "Kortet avvisades av en okänd anledning."
    }
  },
  security_violation: {
    title: {
      en: "Security violation",
      sv: "Säkerhetsöverträdelse"
    },
    description: {
      en: "The card was declined for an unknown reason.",
      sv: "Kortet avvisades av en okänd anledning."
    }
  },
  service_not_allowed: {
    title: {
      en: "Service not allowed",
      sv: "Tjänst ej tillåten"
    },
    description: {
      en: "The card was declined for an unknown reason.",
      sv: "Kortet avvisades av en okänd anledning."
    }
  },
  stolen_card: {
    title: {
      en: "Generic decline",
      sv: "Generellt avslag"
    },
    description: {
      en:
        "The card was declined for an unknown reason or Stripe Radar blocked the payment.",
      sv:
        "Kortet avvisades av en okänd anledning eller så blockerade Stripe Radar betalningen."
    }
  },
  stop_payment_order: {
    title: {
      en: "Stop payment order",
      sv: "Stoppad betalningsorder"
    },
    description: {
      en: "The card was declined for an unknown reason.",
      sv: "Kortet avvisades av en okänd anledning."
    }
  },
  testmode_decline: {
    title: {
      en: "Testmode decline",
      sv: "Testläge avslag"
    },
    description: {
      en: "A Stripe test card number was used.",
      sv: "Ett Stripe-testkortnummer användes."
    }
  },
  transaction_not_allowed: {
    title: {
      en: "Transaction not allowed",
      sv: "Transaktion inte tillåten"
    },
    description: {
      en: "The card was declined for an unknown reason.",
      sv: "Kortet avvisades av en okänd anledning."
    }
  },
  try_again_later: {
    title: {
      en: "Try again later",
      sv: "Försök igen senare"
    },
    description: {
      en: "The card was declined for an unknown reason.",
      sv: "Kortet avvisades av en okänd anledning."
    }
  },
  withdrawal_count_limit_exceeded: {
    title: {
      en: "Withdrawal count limit exceeded",
      sv: "Uttagsgräns överskriden"
    },
    description: {
      en:
        "The customer has exceeded the balance or credit limit available on their card.",
      sv: "Kunden har överskridit saldot eller kreditgränsen på sitt kort."
    }
  }
};

export function getErrorMsgReason(reason, locale) {
  const isStripeError = !!paymentErrorMsgs[reason];
  const errorMsg = isStripeError
    ? paymentErrorMsgs[reason].title[locale]
    : reason;

  return errorMsg;
}

export function getErrorMsgDetails(reason, details, locale) {
  const isStripeError = !!paymentErrorMsgs[reason];
  const errorMsgDetails = isStripeError
    ? details !== paymentErrorMsgs[reason].description.en
      ? details
      : paymentErrorMsgs[reason].description[locale]
    : details;

  return details
    ? errorMsgDetails
    : isStripeError
    ? paymentErrorMsgs[reason].description[locale]
    : " ";
}

import { defaults } from "../../constants";

class NewOrderBaseModel {
  constructor(m) {
    this.activeCoin = m && m.activeCoin ? m.activeCoin : defaults.crypto;
    this.amount = m && m.amount ? m.amount : 0;
    this.estimatedAmount = m && m.estimatedAmount ? m.estimatedAmount : 0;
    this.activeFiat = m && m.activeFiat ? m.activeFiat : defaults.fiat;
  }
}

export default NewOrderBaseModel;

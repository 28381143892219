import React from "react";
import PropTypes from "prop-types";
import getBlockexplorerLink from "./blockexplorer.link.config";
import Translation from "../../../../localization/Translation";
import ExternalLink from "../../../../components/external.link/ExternalLink";
import LaunchIcon from "../../../../../resources/images/launch.svg";
import Image from "../../../../components/image/Image";
import styles from "./BlockexplorerLink.module.scss";

const BlockexplorerLink = ({ type, address }) => (
  <ExternalLink
    className={styles["btn"]}
    href={getBlockexplorerLink(type, address)}
  >
    <Translation>View in Blockexplorer</Translation>
    <Image
      src={LaunchIcon}
      alt="View in Blockexplorer"
      className={styles["btn__icon"]}
    />
  </ExternalLink>
);

BlockexplorerLink.propTypes = {
  type: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired
};

export default BlockexplorerLink;

import CryptoJS from "crypto-js";
import { csrfToken } from "./backend.config";
import { defaultErrorMessage } from "../../constants";

const getCrsfToken = url => {
  const secret = process.env.REACT_APP_BTCX_SECRET;
  const timestamp = Date.now();
  const message = url + timestamp;
  const token = CryptoJS.HmacSHA1(message, secret) + timestamp;

  return token;
};

const setCsrfCookie = url => {
  window.localStorage.setItem(csrfToken.cookieName, getCrsfToken(url));
};

const getCsrfCookie = () => window.localStorage.getItem(csrfToken.cookieName);

const removeCsrfCookie = () => {
  window.localStorage.removeItem(csrfToken.cookieName);
};

const getErrorMessage = (error, availableErrors) => {
  if (error && error.result && availableErrors.includes(error.result)) {
    return error.result;
  }

  return defaultErrorMessage;
};

export { setCsrfCookie, getCsrfCookie, removeCsrfCookie, getErrorMessage };

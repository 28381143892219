export default {
  hasAllowedCharacters: "hasAllowedCharacters",
  hasExtendedAllowedCharacters: "hasExtendedAllowedCharacters",
  isCountryCode: "isCountryCode",
  isDefined: "isDefined",
  isInteger: "isInteger",
  isLessThan: "isLessThan",
  isMoreThan: "isMoreThan",
  isPersonalNumber: "isPersonalNumber",
  isStringEmpty: "isStringEmpty",
  isValidBuyAmount: "isValidBuyAmount",
  isValidPhoneNumber: "isValidPhoneNumber",
  isValidSellAmount: "isValidSellAmount",
  isWalletAddress: "isWalletAddress",
  isDecimalAmount: "isDecimalAmount",
  isValidEmailAddress: "isValidEmailAddress",
  isValidKYCInput: "isValidKYCInput",
  isNumbericOptional: "isNumericOptional",
  isValidIBAN: "isValidIBAN",
  paymentMethodMsg: "paymentMethodMsg"
};

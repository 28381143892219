import lightTeme from "./light/light.theme";
import lightThemePalette from "./light/light.theme.palette";
import darkTheme from "./dark/dark.theme";
import darkThemePalette from "./dark/dark.theme.palette";
import pinkTheme from "./pink/pink.theme";
import pinkThemePalette from "./pink/pink.theme.palette";

export const themes = {
  // light: {
  //   theme: lightTeme,
  //   palette: lightThemePalette,
  //   icon: "moon",
  //   name: "light"
  // }, // To reverse to light theme uncomment and comment light used theme
  light: {
    theme: pinkTheme,
    palette: pinkThemePalette,
    icon: "moon",
    name: "light"
  },
  dark: {
    theme: darkTheme,
    palette: darkThemePalette,
    icon: "sun",
    name: "dark",
    cookieValue: "NIGHT"
  }
};
export const availableThemes = Object.keys(themes);
export const themeUrlParameter = "theme";
export const themeCookieName = "THEME";

export default function isValidBuyAmount(value) {
  if (
    typeof value !== "string" ||
    Number.isNaN(+value) ||
    +value <= 0 ||
    !value.match(/^[0-9]*$/) ||
    (value.length > 1 && value.charAt(0) === "0")
  ) {
    return false;
  }

  return true;
}

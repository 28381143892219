import DropdownMenu from "../../components/dropdown.menu/DropdownMenu";
import Icon from "../../components/icon/Icon";
import MenuList from "@material-ui/core/MenuList";
import Notification from "./Notification";
import NotificationsEmpty from "./NotificationsEmpty";
import PropTypes from "prop-types";
import React from "react";
import styles from "./Notifications.module.scss";
import { ACCESS_RIGHTS, WithAccessRight } from "../../access/WithAccessRight";
import { connect } from "react-redux";
import { publish, events } from "../../services/events/events.service";
import { maxNotifications } from "./notifications.config";
import { paths } from "../../routing/paths";
import Button from "../../components/button/Button";
import { useHistory } from "react-router";

const Notifications = ({ notifications }) => {
  const history = useHistory();

  return (
    <WithAccessRight acr={[ACCESS_RIGHTS.CAN_SEE_FULL_APP]}>
      <DropdownMenu
        className={styles["notifications__menu"]}
        anchorContent={open => {
          if (open) {
            publish(events.EVENT_OPEN_NOTIFICATIONS, true);
          }
          return (
            <span className={styles["notifications__icon-wrapper"]}>
              <span
                className={
                  open
                    ? styles["notifications__icon--opened"]
                    : styles["notifications__icon"]
                }
              >
                {!!notifications.filter(n => !n.processed).length && (
                  <span
                    className={styles["notifications--has-non-processed"]}
                  />
                )}
                <Icon symbol="bell" size={24} />
              </span>
            </span>
          );
        }}
      >
        {handleCloseMenu => (
          <MenuList className={styles["notifications"]}>
            {notifications.length > 0 ? (
              <div>
                {notifications
                  .filter((n, i) => i < maxNotifications)
                  .map((notification, key) => (
                    <Notification
                      key={key}
                      notification={notification}
                      onCloseMenu={handleCloseMenu}
                    />
                  ))}
                <Button
                  onClick={e => {
                    handleCloseMenu(e);
                    history.push(paths.notifications);
                  }}
                  variant="primary-text"
                  data-test="view-all-btn"
                >
                  View all
                </Button>
              </div>
            ) : (
              <NotificationsEmpty />
            )}
          </MenuList>
        )}
      </DropdownMenu>
    </WithAccessRight>
  );
};

Notifications.propTypes = {
  notifications: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  notifications: state.user.notifications
});

export default connect(mapStateToProps)(Notifications);

import React from "react";
import { withRouter } from "react-router-dom";
import NavigationLink from "./NavigationLink";
import { paths } from "../../../../routing/paths";
import GuideStep from "../../../../components/guide.step/GuideStep";
import {
  WithAccessRight,
  ACCESS_RIGHTS
} from "../../../../access/WithAccessRight";

const NavigationLinks = ({ location: { pathname } }) => (
  <React.Fragment>
    <NavigationLink
      to={paths.dashboardPath}
      isActiveClass={pathname === paths.dashboardPath}
    >
      Dashboard
    </NavigationLink>
    <WithAccessRight acr={[ACCESS_RIGHTS.CAN_SEE_FULL_APP]}>
      <GuideStep step={3}>
        <NavigationLink
          to={paths.buy}
          isActiveClass={pathname === paths.buy || pathname === paths.sell}
        >
          Buy/Sell
        </NavigationLink>
      </GuideStep>
    </WithAccessRight>
    <WithAccessRight acr={[ACCESS_RIGHTS.CAN_SEE_FULL_APP]}>
      <GuideStep step={4}>
        <NavigationLink
          to={paths.orderList}
          isActiveClass={pathname === paths.orderList}
        >
          Orders
        </NavigationLink>
      </GuideStep>
    </WithAccessRight>
  </React.Fragment>
);

export default withRouter(NavigationLinks);

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Translation from "../../../../localization/Translation";
import styles from "./NavigationLink.module.scss";

const NavigationLink = ({ to, children, isActiveClass }) => {
  let linkClassName = styles["link"];

  if (isActiveClass) {
    linkClassName += ` ${styles["link--active"]}`;
  }

  return (
    <Link exact="true" to={to} className={linkClassName}>
      <Translation>{children}</Translation>
    </Link>
  );
};

NavigationLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  isActiveClass: PropTypes.bool
};

export default NavigationLink;

import { defaults } from "../../../constants";

export const colors = {
  BTC: "#e6007e",
  ETH: "#8b17dd ",
  LTC: "#9298a8",
  BCH: "#323232"
};

export const allTime = "allTime";

export const periods = {
  week: {
    apiParam: "7d",
    label: "1W",
    alias: "week"
  },
  month: {
    apiParam: "1m",
    label: "1M",
    alias: "month"
  },
  halfYear: {
    apiParam: "6m",
    label: "6M",
    alias: "halfYear"
  },
  allTime: {
    apiParam: "all",
    label: "1Y",
    alias: "allTime"
  }
};

export const graphDefaults = {
  period: allTime,
  crypto: defaults.crypto,
  fiat: defaults.fiat
};

export const yAxisConfig = {
  tickLine: false,
  padding: { bottom: 10 }
};

export const xAxisConfig = {
  tickLine: false
};

export const periodFormats = {
  [periods.allTime.alias]: "MMM Y",
  [periods.halfYear.alias]: "MMM D",
  [periods.month.alias]: "MMM D",
  [periods.week.alias]: "MMM D"
};

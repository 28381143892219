import React from "react";
import PropTypes from "prop-types";
import Translation from "../../localization/Translation";
import WalletExportLink from "./WalletExportLink";
import CopyToClipboard from "../../components/copy.to.clipboard/CopyToClipboard";
import styles from "./WalletExportContent.module.scss";

const WalletExportContent = ({ pwd, guid }) => (
  <div className={styles["wallet-export-content"]}>
    <div className={styles["wallet-export-content__guid-pwd"]}>
      <div className={styles["wallet-export-content__guid-pwd-item"]}>
        <div className={styles["wallet-export-content__guid-pwd-label"]}>
          <Translation>Guide</Translation>
        </div>
        <div className={styles["wallet-export-content__guid-pwd-value"]}>
          {guid} <CopyToClipboard toCopy={guid} hasLabel={false} />
        </div>
      </div>
      <div className={styles["wallet-export-content__guid-pwd-item"]}>
        <div className={styles["wallet-export-content__guid-pwd-label"]}>
          <Translation>Password</Translation>
        </div>
        <div className={styles["wallet-export-content__guid-pwd-value"]}>
          {pwd} <CopyToClipboard toCopy={pwd} hasLabel={false} />
        </div>
      </div>
    </div>
    <div className={styles["wallet-export-content__footer"]}>
      <div className={styles["wallet-export-content__subtitle"]}>
        <Translation>
          Follow the steps in the guide we have provided to access your private
          key and holdings.
        </Translation>
      </div>
      <WalletExportLink />
    </div>
  </div>
);

WalletExportContent.propTypes = {
  pwd: PropTypes.string.isRequired,
  guid: PropTypes.string.isRequired
};

export default WalletExportContent;
